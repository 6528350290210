import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CallRecordingService} from '../../../call-recordings/call-recording.service';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import {CustomerLite} from '../../models/customer-lite.model';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-call-recordings',
  templateUrl: './call-recordings.component.html',
  styleUrls: ['./call-recordings.component.css'],
})
export class CallRecordingsComponent implements OnInit {

  public customer: CustomerLite;
  public recordings: LengthAwarePaginator;


  constructor(
    private alertService: AlertService,
    private callRecordingService: CallRecordingService,
    public activeModal: NgbActiveModal,
  ) {
  }


  ngOnInit() {
    this.getRecordings();
  }


  /**
   * Perform the search
   */
  private getRecordings() {

    this.callRecordingService
      .getByGuid(this.customer.guid)
      .subscribe(
        lengthAwarePaginator => {
          this.recordings = lengthAwarePaginator;
          if (this.recordings.total < 1) {
            this.alertService.warning('No results found for the given search parameters.');
          }
        },
        // (err: HttpErrorResponse) => {
        //     this.handleError(err);
        // }
      );
  }

  close() {
    this.activeModal.close();
  }
}
