import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';

@Injectable({providedIn: 'root'})
export class LoginLogService {

    private resourceUrl = 'api/logs/logins';

    constructor(private http: HttpClient) {
    }

    get(member_id, page) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get<LengthAwarePaginator>(this.resourceUrl + '/' + member_id, {params: params});
    }
}
