import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {SmsAlertsService} from '../../customer/services/sms-alerts.service';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-sms-alerts',
    templateUrl: './sms-alerts.component.html',
    styleUrls: ['./sms-alerts.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    RouterLink,
    NgbPagination,
    NgbTooltip,
  ],
})
export class SmsAlertsComponent implements OnInit {

    public smsAlerts: LengthAwarePaginator;
    // public smsAlerts: Array<SmsAlert>;

    /**
     * Paginate the sms alerts
     * @type {{start: number; end: number; current_page: number}}
     */
    // public pagination: any = {
    //     start: 0,
    //     end: 10,
    //     current_page: 1
    // }

    public label = 'SMS Alerts';

    constructor(
        private route: ActivatedRoute,
        private SmsAlertsService: SmsAlertsService
    ) { }

    ngOnInit() {

        // Watch for GUID changes
        this.route.params.subscribe(
            params => {
                this.fetchSmsAlerts(1);
            }
        );
    }

    // onPageChange(page) {
    //     this.pagination.current_page = page;
    //     this.pagination.start = page*10-10;
    //     this.pagination.end = page*10;
    // }

    onPageChange(page) {
        this.fetchSmsAlerts(page);
    }


    fetchSmsAlerts(page) {
        this.SmsAlertsService
            .get<LengthAwarePaginator>(page)
            .subscribe((smsAlerts: LengthAwarePaginator) => {
                this.smsAlerts = smsAlerts;
        });
    }
}
