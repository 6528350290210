import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerLite} from '../../models/customer-lite.model';
import {CustomerService} from '../../services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionInterface} from '../../models/transaction.interface';
import {UserService} from '../../../shared/auth/services/user.service';
import {ChargebackService} from '../chargeback.service';
import {User} from '../../../shared/models/user.model';
import {ErrorService} from '../../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-chargeback-modal',
  templateUrl: './chargeback-modal.component.html',
  styleUrls: ['./chargeback-modal.component.css']
})
export class ChargebackModalComponent implements OnInit {

  /**
   * The current user
   */
  public user: User;

  /**
   * The Transaction that is the subject of the refund
   */
  public transaction: TransactionInterface;

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  /**
   * Reason select options
   */
  private reasons: Array<any> = [];

  /**
   * Form Model
   * @type {{guid: any; reason: any; note: any}}
   */
  public chargebackRequest = {
    guid: null, // set by parent modal reference
    customer_status: null,
    userId: 0,
    trans_num: null,
    note: null,
    date: null,
    amount: null,
    card_type: null,
    is_cancelled: null,
    case_id: null,
  };

  /**
   * Form validation errors
   */
  public errors: Array<any> = [];

  /**
   * API Errors
   */
  public apiErrors: Array<any> = [];

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;


  constructor(
      private customerService: CustomerService,
      private activeModal: NgbActiveModal,
      private alertService: AlertService,
      private chargebackService: ChargebackService,
      public userService: UserService,
      public errorService: ErrorService
  ) { }


  ngOnInit() {
    // Get current user
    this.userService.get().subscribe(user => {
      this.user = user;
      this.chargebackRequest.note = 'Chargeback logged by ' + this.user.name;
    });

    // For who
    this.customerService.get(this.chargebackRequest.guid).subscribe(data => {
      this.customer = data.getCustomerLite();
      // this.chargebackRequest.is_cancelled = (customer_status)
    });

    this.chargebackRequest.amount = this.transaction.amount;
    this.chargebackRequest.trans_num = this.transaction.trans_num;
    this.chargebackRequest.customer_status = this.customer.status;
  }

  /**
   * Send chargeback request to server
   */
  logChargeback() {

    this.errorService.clearErrors();

    this.chargebackService.refund(this.chargebackRequest)
        .subscribe(
            (data) => {
              this.alertService.success('Chargeback initiatated');
              this.close();
            },
            (err: HttpErrorResponse) => {
              this.errorService.handleValidationErrors(err);
            }
        );
  }

  close() {
    this.activeModal.close();
  }
}
