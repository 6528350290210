import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginLogService} from '../../services/login-log.service';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import {CustomerLogin} from "../../../shared/models/customer-login.model";

@Component({
  selector: 'app-customer-logins',
  templateUrl: 'customer-logins.component.html',
  styleUrls: ['customer-logins.component.css']
})
export class CustomerLoginsComponent implements OnInit {

  private guid = '';
  public logins: LengthAwarePaginator<CustomerLogin>;

  constructor(
    private loginLogService: LoginLogService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.guid = this.route.snapshot.params['guid'];
        this.fetchLogins(1);
      }
    );
  }

  onPageChange(page) {
    this.fetchLogins(page);
  }

  fetchLogins(page) {
    this.loginLogService.get(this.guid, page).subscribe(lengthAwarePaginator => {
      this.logins = lengthAwarePaginator;
    });
  }

}
