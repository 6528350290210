import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ProspectService} from '../services/prospect.service';
import {ProspectInterface} from '../models/prospect.interface';
import {ActivatedRoute} from '@angular/router';
import {DateUtils} from '../../shared/utils/date-utils';
import {ErrorService} from '../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";

@Component({
    selector: 'app-prospect-edit-modal',
    templateUrl: './prospect-edit-modal.component.html',
    styleUrls: ['./prospect-edit-modal.component.css']
})
export class ProspectEditModalComponent implements OnInit {

    private prospectId: number;

    public dob: NgbDateStruct;

    public prospect = {
        id: 0,
        guid: '',
        fname: '',
        lname: '',
        email: '',
        phone: '',
        dob: null,
        straddr: '',
        building_name: '',
        city: '',
        zip: '',
        is_third_party_marketing: false,
    };

    /**
     * Signify if New Prospect or Update existing
     * @type {boolean}
     */
    public isNewProspect = false;

    /**
     * Form validation errors
     */
    public errors: any;

    /**
     * Add reference to Object.keys function to template
     */
    public objectKeys = Object.keys;

    /**
     * ngBootstrap Date Configuration
     * @type {{year: number; month: number; day: number}}
     */
    public minDate = {year: 1910, month: 1, day: 1};


    constructor(
        private route: ActivatedRoute,
        public activeModal: NgbActiveModal,
        private prospectService: ProspectService,
        private alertService: AlertService,
        public errorService: ErrorService
    ) {}


    ngOnInit() {
        if (!this.isNewProspect) {
            // fetch the desired prospect
            this.prospectService.get(this.prospectId).subscribe((data: ProspectInterface) => {
                this.loadFormModel(data);
            });
        }
    }

    /**
     * Load Prospect
     * @param customer
     */
    public loadFormModel(data) {
        Object.keys(this.prospect).forEach(key => this.prospect[key] = data[key]);

        // Form Dates need to be NgBDateStruct
        const dob = new Date(data.dob);
        this.dob = {day: dob.getDate(), month: dob.getMonth() + 1, year: dob.getFullYear()};
    }


    /**
     * Save Prospect
     */
    save() {

        this.prospect.dob = (this.dob) ? DateUtils.getMysqlDate(this.dob) : '';

        this.prospectService
            .save(this.prospect)    // , this.customer.guid
            .subscribe(
                (data) => {
                    this.alertService.success('Prospect saved.');
                    this.close();
                },
                (err: HttpErrorResponse) => {
                    this.errorService.handleValidationErrors(err);
                }
            );
    }


    /**
     * Close the modal
     */
    close() {
        this.activeModal.close();
    }
}
