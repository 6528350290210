import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class DateUtils {

  /**
   * Convert NgbDateStruct to Date
   * @param ngbDateStruct
   * @returns {Date}
   */
  static convertDate(ngbDateStruct: NgbDateStruct) {
    const dt = new Date();
    dt.setDate(ngbDateStruct.day);
    dt.setMonth((ngbDateStruct.month - 1));
    dt.setFullYear(ngbDateStruct.year);
    return dt;
  }

  /**
   * Convert NgbDateStruct to MySQL Date String
   * @param ngbDateStruct
   * @returns {string}
   */
  static getMysqlDate(ngbDateStruct: NgbDateStruct) {
    return [
      ngbDateStruct.year,
      '-',
      (ngbDateStruct.month > 9 ? '' : '0') + ngbDateStruct.month,
      '-',
      (ngbDateStruct.day > 9 ? '' : '0') + ngbDateStruct.day,
    ].join('');
  }
}
