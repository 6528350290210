<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel" [ngSwitch]="isNewProspect">
    <span *ngSwitchCase="true">Add Prospect</span>
    <span *ngSwitchCase="false">Edit Prospect #{{prospect.id}}</span>
  </h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <!-- Errors
  <div *ngIf="errors">
    <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
      {{errors[key]}}
    </div>
  </div>
-->
  <app-error></app-error>

  <form>
    <!-- First Name -->
    <div class="form-group row">
      <label for="fname" class="col-sm-4 col-form-label">First Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="fname"
               name="fname"
               [(ngModel)]="prospect.fname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['fname']}">
      </div>
    </div>

    <!-- Last Name -->
    <div class="form-group row">
      <label for="lname" class="col-sm-4 col-form-label">Last Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="lname"
               name="lname"
               required
               [(ngModel)]="prospect.lname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['lname']}">
      </div>
    </div>

    <!-- Email -->
    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">Email</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="email"
               name="email"
               required
               [(ngModel)]="prospect.email"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['email']}">
      </div>
    </div>

    <!-- Phone -->
    <div class="form-group row">
      <label for="phone" class="col-sm-4 col-form-label">Phone</label>
      <div class="col-sm-8">
        <!--<ngx-intl-tel-input [(value)]="prospect.phone" [preferredCountries]="countries"></ngx-intl-tel-input>-->
        <input type="text"
               class="form-control"
               id="phone"
               name="phone"
               required
               [ngModel]="prospect.phone | ukPhone"
               (ngModelChange)="prospect.phone=$event"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['phone']}">
      </div>
    </div>

    <!-- DateofBirth -->
    <div class="form-group row">
      <label for="dob" class="col-sm-4 col-form-label">Date of Birth</label>
      <div class="col-sm-8">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" id="dob" name="dob" [minDate]="minDate"
                   [(ngModel)]="dob" ngbDatepicker #d1="ngbDatepicker">
            <button class="btn btn-outline-secondary" type="button" (click)="d1.toggle()">
              <i class="fa-regular fa-calendar" aria-hidden="true"></i>
            </button>
          </div>

      </div>
    </div>


    <!-- Address -->
    <div class="form-group row">
      <label for="dob" class="col-sm-4 col-form-label">Address</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="address"
               name="address"
               required
               [(ngModel)]="prospect.straddr">
      </div>
    </div>

    <!-- Building Name -->
    <div class="form-group row">
      <label for="building_name" class="col-sm-4 col-form-label">Building Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="building_name"
               name="building_name"
               required
               [(ngModel)]="prospect.building_name">
      </div>
    </div>

    <!-- City -->
    <div class="form-group row">
      <label for="city" class="col-sm-4 col-form-label">City</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="city"
               name="city"
               required
               [(ngModel)]="prospect.city">
      </div>
    </div>

    <!-- Postcode -->
    <div class="form-group row">
      <label for="postcode" class="col-sm-4 col-form-label">Postcode</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="postcode"
               name="postcode"
               required
               [(ngModel)]="prospect.zip">
      </div>
    </div>

    <!-- Third Party Marketing -->
    <div class="form-group row">
      <label for="is_third_party_marketing" class="col-sm-4 col-form-label">Third Party Marketing</label>
      <div class="col-sm-8 form-check">
        <label class="form-check-label">
        <input type="checkbox"
               class="form-check-input"
               id="is_third_party_marketing"
               name="is_third_party_marketing"
               required
               [(ngModel)]="prospect.is_third_party_marketing"> <span class="small">"Would you be okay with us sending you emails about updates to our service as well as 3rd party services that we feel you would be interested?"</span>
        </label>
      </div>
    </div>

  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>





