import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerNotesComponent} from './customer-notes/customer-notes.component';
import {NoteDetailModalComponent} from './note-detail-modal/note-detail-modal.component';
import {SharedModule} from '../shared/shared.module';
import {APP_CONFIG, AppConfig} from '../app.config';
import {Nl2BrPipe} from 'src/app/shared/pipes/nl2br.pipe';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';


@NgModule({
  declarations: [
    CustomerNotesComponent,
    NoteDetailModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Nl2BrPipe,
    DateTimeFormatPipe,
  ],
  exports: [
    CustomerNotesComponent,
    NoteDetailModalComponent,
  ],
  providers: [
    {provide: APP_CONFIG, useValue: AppConfig},
  ],
})
export class NotesModule {
}
