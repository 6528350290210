import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';

@Component({
  selector: 'app-cancellation-list',
  templateUrl: './cancellation-list.component.html',
  styleUrls: ['./cancellation-list.component.css'],
})
export class CancellationListComponent implements OnInit {

  public cancelEventsPage: LengthAwarePaginator;

  public pagination: any = {
    start: 0,
    end: 9,
    current_page: 1,
  };

  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit() {

    this.getPage(1);
  }

  onPageChange(page) {
    this.getPage(page);
  }

  getPage(page) {

    let params = new HttpParams();
    params = params.append('page', page);

    this.http.get(
      'api/logs/cancelevents',
      {params: params})
      .subscribe((lengthAwarePaginator: LengthAwarePaginator) => {
        this.cancelEventsPage = lengthAwarePaginator;
      });
  }


  /**
   * Event handler for refetching Notes from server
   */
  refresh(event) {
    this.getPage(1);
  }
}
