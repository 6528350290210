import {Component, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService as CustomerAlertService} from '../../customer/customer-detail/customer-alerts/services/alert.service';
import {AlertSearchOptions} from '../../customer/models/AlertSearchOptions';
import {UserService} from '../../shared/auth/services/user.service';
import {ErrorService} from '../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";
import {Observable} from 'rxjs';
import {CommonModule} from '@angular/common';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {NgbPagination, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    DateTimeFormatPipe,
    NgbTooltip,
    NgbPagination,
    RouterLink,
  ],
})
export class AlertsComponent implements OnInit {

  @Input()
  public inquiryType: string;

  @Input()
  public status: string;

  @Input()
  public label: string;

  public alerts: any;
  public isFetching: boolean;
  canHideAlert$: Observable<boolean>;

  constructor(
      private customerAlertService: CustomerAlertService,
      private alertService: AlertService,
      private userService: UserService,
      public errorService: ErrorService
  ) { }

  ngOnInit() {
    this.canHideAlert$ = this.userService.hasAbility('hide-alert');
    this.getAlerts(1);
  }

  onPageChange(page) {
    // console.log(page);
    this.getAlerts(page);
  }

  getAlerts(page) {
    const alertSearchOptions: AlertSearchOptions = {
      status: this.status,
      inquiry_type: this.inquiryType, // 'BILLING_CONCERNS'
      page: page
    };

    if (!this.isFetching) {
      this.isFetching = true;

      this.customerAlertService.search(alertSearchOptions).subscribe({
        next: (lengthAwarePaginator) => {
          this.alerts = lengthAwarePaginator;
          this.isFetching = false;
        },
        error: (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.alertService.error('A Network Error Occurred');
          } else {
            err.error.errors.map(error => {
              this.alertService.addApiError(error);
            });
          }
        }
      });
    }
  }

  hideAlert(alert_id) {
    this.customerAlertService.hide(alert_id).subscribe({
      next: (data) => {
        this.getAlerts(1);
        this.alertService.success('Alert Hidden');
      },
      error: (err: HttpErrorResponse) => {
        // handled by global error interceptor
      },
    });
  }


}
