import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from '../../services/customer.service';

@Component({
  selector: 'app-transunion-modal',
  templateUrl: 'transunion-modal.component.html',
  styleUrls: ['transunion-modal.component.css']
})
export class TransunionModalComponent {

  public customer: any;
  public accountStatus?: boolean;
  public accountClosed?: boolean;

  constructor(
    private customerService: CustomerService,
    public activeModal: NgbActiveModal,
  ) {
  }

  queryAccount() {
    this.customerService
      .queryAccountStatus(this.customer?.guid)
      .subscribe(result => {
        this.accountStatus = result;
      });

  }

  closeUser() {
    if (confirm('Are you sure you want to close the TransUnion account?')) {
      this.customerService
        .closeUser(this.customer?.guid)
        .subscribe(status => {
          this.accountClosed = status;
        });

    }
  }

  close() {
    this.activeModal.close();
  }
}
