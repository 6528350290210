import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {AlertService} from './services/alert.service';
import {NoteDetailModalComponent} from '../../../notes/note-detail-modal/note-detail-modal.component';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';
import {UserService} from '../../../shared/auth/services/user.service';
import {AddNoteModalComponent} from '../../../notes/add-note-modal/add-note-modal.component';
import {Note} from '../../../notes/models/note.model';
import {CustomerService} from '../../services/customer.service';
import {NoteService} from '../../../notes/services/note.service';

@Component({
    selector: 'app-customer-alerts',
    templateUrl: 'customer-alerts.component.html',
    styleUrls: ['customer-alerts.component.css']
})
export class CustomerAlertsComponent implements OnInit {

    private guid = '';
    public alerts: LengthAwarePaginator;
    private isFetching: boolean = false;
    private user: any = {};

    constructor(
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private alertService: AlertService,
        public userService: UserService,
        private noteService: NoteService
    ) {
        this.userService.get().subscribe(user => {
            this.user = user;
        });
    }


    ngOnInit() {
        this.guid = this.route.snapshot.params['guid'];
        // this.getAlerts(1);

        // subscribe to note save events
        this.noteService
            .onNoteSaveEvent
            .subscribe(
                event => this.refresh(event)
            );

        // Watch for GUID changes so we can update alert data
        this.route.params.subscribe(
            params => {
                this.guid = params['guid'];
                this.refresh(false);
            }
        );
    }



    getAlerts(page) {
        if (!this.isFetching) {
            this.isFetching = true;

            this.alertService.getByGuid(this.guid, page).subscribe((lengthAwarePaginator) => {
                this.alerts = lengthAwarePaginator;
                this.isFetching = false;
            });
        }
    }

    /**
     * Open Note Modal and show details for the given Note Id
     * @param noteId
     */
    viewNote(noteId) {
        const modalRef = this.modalService.open(NoteDetailModalComponent);
        modalRef.componentInstance.noteId = noteId;
    }


    resolveAlert(alert) {
        const guid = this.route.snapshot.params['guid'];

        this.customerService.get(guid).subscribe(data => {
            const customer = data;

            const modalRef = this.modalService.open(AddNoteModalComponent);
            modalRef.componentInstance.note = new Note(
                guid,
                customer.status,
                customer.fname,
                customer.lname,
            );
            modalRef.componentInstance.note.customer_type = 'customer';
            modalRef.componentInstance.user = this.user;
            modalRef.componentInstance.alert = alert;
        });
    }

    /**
     * Event handler for refetching  Alerts from server
     */
    refresh(event) {
        this.getAlerts(1);
    }
}
