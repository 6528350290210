import {Component, OnInit} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {CallRecordingService} from '../call-recording.service';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {DomSanitizer} from '@angular/platform-browser';
import {PageTitleService} from '../../shared/page/page-title.service';
import {AlertService} from "@ratespecial/core";

@Component({
    selector: 'app-recording-search',
    templateUrl: './recording-search.component.html',
    styleUrls: ['./recording-search.component.css']
})
export class RecordingSearchComponent implements OnInit {

    /**
     * Search Results
     * @type {Array}
     */
    public recordings: LengthAwarePaginator;

    /**
     * Form Model
     */
    public searchModel = {
        agent: '',
        phone: '',
        date_start: '',
        date_end: '',
        page: 1,
    };

    /**
     * Date Structures For Date Picker
     */
    public dateStart: NgbDateStruct = null;
    public dateEnd: NgbDateStruct = null;

    constructor(private activatedRoute: ActivatedRoute,
                private ngbDateParserFormatter: NgbDateParserFormatter,
                private callRecordingService: CallRecordingService,
                private router: Router,
                private alertService: AlertService,
                private sanitizer: DomSanitizer,
                private pageTitleService: PageTitleService) { }

    ngOnInit() {
      this.pageTitleService.setTitle('Call Recordings');
        this.activatedRoute
            .queryParams
            .subscribe((params: Params) => {
                // Autosearch from URL params
                if (Object.keys(params).length > 0) {
                    this.searchModel.agent = params['agent'] ? params['agent'] : '';
                    this.searchModel.phone = params['phone'] ? params['phone'] : '';
                    this.searchModel.date_start = params['date_start'] ? params['date_start'] : '';
                    this.searchModel.date_end = params['date_end'] ? params['date_end'] : '';
                    this.searchModel.page = params['page'] ? params['page'] : 1;
                    this.search();
                }
            });
    }

    /**
     * Handles form submit by placing search params on url and then navigating to the autosearch route
     */
    submit() {
        // Convert Date Structs to ISO8601 String and add to form model
        this.searchModel.date_start = this.ngbDateParserFormatter.format(this.dateStart);
        this.searchModel.date_end = this.ngbDateParserFormatter.format(this.dateEnd);


        // Add params and navigate to autosearch url
        this.router.navigate(['/call-recordings'], { queryParams: this.searchModel});
    }


    /**
     * Perform the search
     */
    private search() {

        this.callRecordingService
            .search(this.searchModel)
            .subscribe(
                lengthAwarePaginator => {
                    this.recordings = lengthAwarePaginator;
                    if (this.recordings.total < 1) {
                        this.alertService.warning('No results found for the given search parameters.');
                    }
                },
                (err: HttpErrorResponse) => {
                    this.handleError(err);
                }
            );
    }


    onPageChange(page) {
        this.searchModel.page = page;
        this.search();
    }


    reset() {
        this.dateStart = null;
        this.dateEnd = null;
        //
        // this.searchModel = {
        //     agent: '',
        //     phone: '',
        //     date_start: '',
        //     date_end: '',
        //     page: 1,
        // };

        Object
            .keys(this.searchModel)
            .map((key) => {
                this.searchModel[key] = '';
            });

        this.recordings = null;
        this.router.navigate(['/call-recordings']);

    }


    /**
     * Handle search errors
     * @param {HttpErrorResponse} err
     */
    private handleError(err: HttpErrorResponse) {
        if (err.error instanceof Error) {
            this.alertService.error('A Network Error Occurred');
        } else {
            // Check if form validation errors
            if (err.status == 422) {
                this.alertService.error('At least one search parameter must be entered.  The Product field, if used, must be included with one other field.');
            } else {
                err.error.errors.map(error => {
                    this.alertService.addApiError(error);
                });
            }
        }
    }
}
