import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class PaidTrialService {

    constructor(private http: HttpClient) {
    }

    search(searchCriteria): Observable<any> {
        return this.http
            .get('/api/transaction/paid-trials', {params: searchCriteria});
    }

}
