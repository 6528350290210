import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SmsAlert} from '../models/sms-alert.model';

@Injectable({providedIn: 'root'})
export class SmsAlertsService {

    private resourceUrl = 'api/alerts/sms';

    constructor(private http: HttpClient) {
    }

    get<LengthAwarePaginator>(page) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get<LengthAwarePaginator>(this.resourceUrl, {params: params});
    }

    getByGuid<LengthAwarePaginator>(guid, page) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get<LengthAwarePaginator>(this.resourceUrl + '/' + guid, {params: params});
    }

    resolve(id) {
        return this.http.get<Array<SmsAlert>>(this.resourceUrl + '/resolve/' + id);
    }
}
