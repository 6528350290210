<div class="card rs-card rs-card-blue">
  <div class="card-body min-paginated-card-height">

      <div class="clearfix">
          <p class="text-end">{{logins?.total}} Logins</p>
      </div>

    <table class="margin-top-15 table table-sm rs-table table-striped">
      <thead class="">
      <tr>
        <th aria-hidden="true" ngbTooltip="Login Success or Fail" container="body"><i class="fa-solid fa-server"></i></th>
        <th>Time</th>
        <th>IP</th>
        <th class="text-center">OS</th>
        <th class="text-center">Web</th>
        <th class="text-center">Grant</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let l of logins?.data">
        <td [ngSwitch]="l.is_fail" class="text-center">
          <i class="fa-solid fa-ban font-red" *ngSwitchCase="true"></i>
          <i class="fa-solid fa-check font-green" *ngSwitchCase="false"></i>
        </td>
        <td>{{l.dt | dateTimeFormat:'withtime' }}</td>
        <td>{{l.ip}}</td>

        <td class="text-center">
          <i class="fa-brands fa-windows font-windows" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Windows')"></i>
          <i class="fa-brands fa-apple font-apple" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Macintosh') || l.environment.includes('OS X') || l.environment.includes('iPhone') "></i>
          <i class="fa-brands fa-linux font-linux" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Linux')"></i>
        </td>

        <td class="text-center">
          <!--<i (click)="viewEnv(l.id)" class="fa fa-lg fa-search clickable" ngbTooltip="{{l.environment}}"></i>-->
          <i class="fa-brands fa-edge" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Edge')"></i>
          <i class="fa-brands fa-chrome font-chrome" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Chrom')"></i>
          <i class="fa-brands fa-firefox font-firefox" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Firefox') && !l.environment.includes('Seamonkey')"></i>
          <i class="fa-brands fa-safari font-safari" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('Safari') && !l.environment.includes('Chrom')"></i>
          <i class="fa-brands fa-internet-explorer font-internet-explorer" aria-hidden="true" ngbTooltip="{{l.environment}}" *ngIf="l.environment.includes('MSIE')"></i>
        </td>

        <td [ngSwitch]="l.grant" class="text-center">
          <i class="fa-brands fa-google" aria-hidden="true" ngbTooltip="Google" *ngSwitchCase="'google'"></i>
          <i class="fa-solid fa-unlock" aria-hidden="true" ngbTooltip="One Time Password" *ngSwitchCase="'otp'"></i>
          <i class="fa-solid fa-key" aria-hidden="true" ngbTooltip="Username + Password" *ngSwitchDefault></i>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="text-center mt-4" *ngIf="logins?.total == 0">There have been no logins.</div>
  </div>

    <div class="card-footer d-flex justify-content-between">
        <ngb-pagination *ngIf="logins"
                        (pageChange)="onPageChange($event)"
                        [collectionSize]="logins?.total"
                        [(page)]="logins.current_page"
                        [pageSize]="logins?.per_page"
                        [maxSize]="5" [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        size="sm"></ngb-pagination>
      <p>Page: {{logins?.current_page}}</p>
    </div>
</div>
