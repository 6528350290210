import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router, RouterLink} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {PaidTrialService} from '../paid-trial.service';
import {PageTitleService} from '../../shared/page/page-title.service';
import {AlertService} from "@ratespecial/core";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-paid-trial',
  templateUrl: './paid-trial.component.html',
  styleUrls: ['./paid-trial.component.css'],
  standalone: true,
  providers: [
    PaidTrialService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
  ],
})
export class PaidTrialComponent implements OnInit {

  /**
   * Search Results
   * @type {Array}
   */
  public trials: [];

  /**
   * Form Model
   */
  public searchModel = {
    fname: '',
    lname: '',
    email: '',
    last_four: '',
    card_bin: '',
  };


  constructor(private activatedRoute: ActivatedRoute,
              private paidTrialService: PaidTrialService,
              private router: Router,
              private alertService: AlertService,
              private pageTitleService: PageTitleService) {
  }

  ngOnInit() {
    this.pageTitleService.setTitle('Paid Trials');
    this.activatedRoute
      .queryParams
      .subscribe((params: Params) => {
        // Autosearch from URL params
        this.searchModel.fname = params['fname'] ? params['fname'] : '';
        this.searchModel.lname = params['lname'] ? params['lname'] : '';
        this.searchModel.email = params['email'] ? params['email'] : '';
        this.searchModel.card_bin = params['card_bin'] ? params['card_bin'] : '';
        this.searchModel.last_four = params['last_four'] ? params['last_four'] : '';
        this.search();
      });
  }

  /**
   * Handles form submit by placing search params on url and then navigating to the autosearch route
   */
  submit() {
    // Add params and navigate to autosearch url
    this.router.navigate(['/paid-trial'], {queryParams: this.searchModel});
  }


  /**
   * Perform the search
   */
  private search() {

    this.paidTrialService
      .search(this.searchModel)
      .subscribe({
        next: resp => {
          if (resp.hasOwnProperty('paid_trials')) {
            this.trials = resp.paid_trials;
          } else {
            this.alertService.warning('No results found for the given search parameters.');
          }
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);
        }
      });
  }


  onPageChange(page) {
    // this.searchModel.page = page;
    this.search();
  }


  reset() {


    Object
      .keys(this.searchModel)
      .map((key) => {
        this.searchModel[key] = '';
      });

    this.trials = null;
    this.router.navigate(['/paid-trial']);

  }


  /**
   * Handle search errors
   * @param {HttpErrorResponse} err
   */
  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      this.alertService.error('A Network Error Occurred');
    } else {
      // Check if form validation errors
      if (err.status == 422) {
        this.alertService.error('At least one search parameter must be entered.  The Product field, if used, must be included with one other field.');
      } else {
        err.error.errors.map(error => {
          this.alertService.addApiError(error);
        });
      }
    }
  }
}
