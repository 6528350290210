<div class="modal-header">
  <h4 class="modal-title">PDF Viewer</h4>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <div id="pdfViewer"></div>
</div>

<div class="modal-footer">
  <button (click)="close()" class="btn btn-secondary" type="button">Close</button>
</div>
