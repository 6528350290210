<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Smart Offers Information</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">

  <div class="container-fluid">
    <div class="row">
      <div class="col-6 label">Has seen smart offers pop up?</div>
      <div class="col-6">
        {{ accountSettings.hasSeenSmartOffers ? 'Yes' : 'No' }}
      </div>
    </div>
    <div class="row">
      <div class="col-6 label">Has consented to smart offers?</div>
      <div class="col-6">
        {{ accountSettings.hasConsentedSmartOffers ? 'Yes' : 'No' }}
      </div>
    </div>
    <div class="row">
      <div class="col-6 label">Consent timestamp</div>
      <div class="col-6">
        {{ accountSettings.consentedSmartOffersTimestamp | dateTimeFormat:'withtime' }}
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">Close</button>
</div>
