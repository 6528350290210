<div class="container">
	<h3>Call Recording Search</h3>

	<div class="card card-body rs-card rs-card-blue">

		<form #searchForm="ngForm" class="customer-search-form">
			<div class="row">
				<div class="col-md-5">
					<!--fname -->
					<div class="mb-2 row">
						<label class="control-label control-label-left col-md-4" for="phone">Phone</label>
						<div class="col-md-8">
							<input type="text" name="phone" id="phone" class="form-control form-control-sm" [(ngModel)]="searchModel.phone" >
						</div>
					</div>

					<!--lname -->
					<div class="mb-2 row">
						<label class="control-label control-label-left col-md-4" for="agent">Agent</label>
						<div class="controls col-md-8">
							<input type="text" id="agent" name="agent" class="form-control form-control-sm" [(ngModel)]="searchModel.agent">
							<span id="errId2" class="error"></span>
						</div>
					</div>
				</div>


				<div class="col-md-7">

					<div class="mb-2 row">
						<label class="control-label control-label-left col-md-4">Date Range</label>
						<div class="controls col-md-4">
							<div class="input-group">
								<input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp1"
									   [(ngModel)]="dateStart" ngbDatepicker #d1="ngbDatepicker" required>
                <button class="btn btn-sm  btn-sm-date-picker btn-outline-secondary" type="button" (click)="d1.toggle()">
                  <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
						</div>
						<div class="controls col-md-4">
							<div class="input-group">
								<input class="form-control form-control-sm" placeholder="yyyy-mm-dd" name="dp2"
									   [(ngModel)]="dateEnd" ngbDatepicker #d2="ngbDatepicker" required>
                <button class="btn btn-sm  btn-sm-date-picker btn-outline-secondary" type="button" (click)="d2.toggle()">
                  <i class="fa-regular fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
						</div>
					</div>

				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="search-buttons float-end">
						<button type="submit" (click)="submit()" class="btn btn-sm btn-outline-primary me-1">Search</button>
						<button type="button" class="btn btn-sm btn-outline-secondary" (click)="reset()">Reset</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>


<div class="container"  *ngIf="recordings?.total > 0">

	<h5 class="margin-top-35">Search Results:</h5>

	<div class="card rs-card rs-card-blue">
		<div class="card-body">

			<div class="clearfix">
				<p class="text-end">{{recordings?.total}} Recordings</p>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<table class="table table-sm  table-search-results">
						<thead class="">
						<tr>
							<th>First Name</th>
							<th>Surname</th>
							<th>GUID</th>
							<th>Agent</th>
							<th>Phone</th>
							<th>Date</th>
							<th class="text-center">Action</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let rec of recordings?.data">
							<td>{{ rec.fname}}</td>
							<td>{{ rec.lname}}</td>
							<td>{{ rec.guid }}</td>
							<td>{{ rec.agent}}</td>
							<td>{{ rec.phone}}</td>
							<td>{{ rec.dt}}</td>
							<td class="text-center">
								<!--<i class="fa fa-assistive-listening-systems clickable" (click)="listen(rec)" title="Listen to recording"></i>-->
								<a href="/api/five9/stream/{{rec.id}}" [download]="rec.filename"><i class="fa-solid fa-download clickable" aria-hidden="true" ngbTooltip="Download Audio File" container="body" ></i></a>
								<i class="fa-regular fa-eye clickable" [routerLink]="['/customer/detail', rec.guid]" *ngIf="rec.guid"  ngbTooltip="View Customer Detail" container="body"></i>
							</td>
						</tr>
						</tbody>
					</table>

				</div>
			</div>
		</div>

		<div class="card-footer d-flex justify-content-between">
			<ngb-pagination *ngIf="recordings"
							(pageChange)="onPageChange($event)"
							[collectionSize]="recordings.total"
							[pageSize]="recordings?.per_page"
							[(page)]="recordings.current_page"
							[maxSize]="5"
							[rotate]="true"
							[ellipses]="false"
							[boundaryLinks]="true" size="sm"></ngb-pagination>
      <p>Page: {{recordings?.current_page}}</p>
		</div>

	</div>

</div>
