import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/auth/services/auth.service';
import {Router} from '@angular/router';
import {HistoryService} from '../../shared/auth/services/history.service';
import {LoadIndicatorService} from "@ratespecial/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(
      private loadIndicatorService: LoadIndicatorService,
      private authService: AuthService,
      private router: Router,
      private historyService: HistoryService
  ) {}

  public googleAuthUrl: string;

  public loginRequest = {
    username: '',
    password: ''
  };


  private static generateGoogleUrl(): string {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

    url.searchParams.append('client_id', '513523973524-muommgeiduusirsm1jh6cn0bfp3hvp6a.apps.googleusercontent.com');
    // url.searchParams.append('client_id', '78415809345-uo2hohetldkku02hps06e9p4dpe94k3e.apps.googleusercontent.com');
    url.searchParams.append('redirect_uri', window.location.origin + '/auth/google');
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');

    return url.toString();
  }


  ngOnInit() {
    this.googleAuthUrl = LoginComponent.generateGoogleUrl();
  }


  /**
   * Local Login
   */
  login() {
    this.loadIndicatorService.push('local-auth');

    this
        .authService.localLogin(this.loginRequest)
        .subscribe({
          next: () => {

            this.gotoLandingPage();
            this.loadIndicatorService.pop('local-auth');
          },
          error: err => {
            console.error(err);
            this.router.navigateByUrl('/auth/login');
            this.loadIndicatorService.pop('local-auth');
          }
        });
  }


  gotoLandingPage() {
      const lastVisitedRoute = this.historyService.consumeLastVisitedRoute();

      if (lastVisitedRoute) {
          this.router.navigateByUrl(lastVisitedRoute);
      } else {
          this.router.navigateByUrl('/dashboard');
      }
      // this.historyService.clearLastVisitedRoute();
  }
}
