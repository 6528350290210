<div  class="padding-top-35">
  <h4>Notes</h4>
  <div class="card rs-card rs-card-blue">
    <div class="card-body">
      <div class="clearfix">
        <p class="text-end">{{notesPage?.total}} Notes</p>
      </div>

      <!-- Desktop View -->
      <table class="margin-top-15 table table-sm rs-table table-striped table-alerts d-md-none d-sm-none d-none d-lg-table">
          <thead class="">
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Channel Type</th>
            <th>Inquiry Group</th>
            <th>Inquiry Type</th>
            <th>Note</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let n of notesPage?.data">
            <td>{{n.dt | dateTimeFormat:'withtime' }}</td>
            <td>{{n.user_name}}</td>
            <td>{{n.type_name}}</td>
            <td>{{n.parent_inquiry_type_name}}</td>
            <td>{{n.inquiry_type_name}}</td>
            <td [innerHTML]="n.body | nl2br"></td>
            <td class="text-center">
              <i (click)="viewNote(n.id)" class="fa-solid fa-magnifying-glass fa-lg clickable"></i>
            </td>
          </tr>
          </tbody>
      </table>

      <!-- Mobile View-->
      <div class="rs-flex-table d-lg-none d-md-block">
        <div class="row" *ngFor="let n of notesPage?.data">
          <div class="col-lg-2 col-sm-4 order-sm-1"><label>Date:</label> {{n.dt | dateTimeFormat:'withtime' }}</div>
          <div class="col-lg-1 col-sm-4 order-sm-2"><label>User:</label> {{n.user_name}}</div>
          <div class="col-lg-2 col-sm-4 order-sm-4"><label>Channel Type:</label> {{n.type_name}}</div>
          <div class="col-lg-2 col-sm-4 order-sm-5"><label>Inquiry Group:</label> {{n.parent_inquiry_type_name}}</div>
          <div class="col-lg-2 col-sm-4 order-sm-6"><label>Inquiry Type:</label> {{n.inquiry_type_name}}</div>
          <div class="col-lg-2 col-sm-10 order-sm-7"><label>Note:</label><span [innerHTML]="n.body | nl2br"></span></div>
          <div class="col-lg-1 col-sm-4 order-sm-3 text-end">
            <i (click)="viewNote(n.id)" class="fa-solid fa-magnifying-glass fa-lg clickable"></i>
          </div>
        </div>
      </div>

      <div class="text-center mt-4" *ngIf="notesPage?.total == 0">There are no notes</div>

    </div> <!-- END Body -->

    <div class="card-footer d-flex justify-content-between">
      <ngb-pagination *ngIf="notesPage"
                      (pageChange)="onPageChange($event)"
                      [collectionSize]="notesPage.total"
                      [pageSize]="notesPage?.per_page"
                      [(page)]="notesPage.current_page"
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true" size="sm"></ngb-pagination>
      <p>Page: {{notesPage?.current_page}}</p>
    </div>
  </div>
</div>
