<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Checkout Screenshot for Transaction #{{trans_num}}</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <p *ngIf="!screenshot">A screenshot file was not found for this transaction.</p>
  <img class="img-fluid" [src]="screenshot?.url" />


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>




