<div class="container">

  <h3>What's New</h3>

  <h4 class="margin-top-25">Shortcuts Functionality</h4>


    <p class="margin-top-35">Press <strong>Shift + ?</strong> to open the shortcuts help to view the available shortcut options</p>


  <div class="row">
    <div class="col-4 offset-4">
      <img class="img-fluid" src="/assets/whatsnew/shortcuts_help.png" />
    </div>
  </div>



    <p class="margin-top-50">Press <strong>Shift + F</strong> to open the Find by GUID Modal which allows you to jump directly to any valid GUID.</p>


  <div class="row">
    <div class="col-8 offset-2">
      <img class="img-fluid" src="/assets/whatsnew/shortcuts_find_by_guid.png"/>
    </div>
  </div>


    <p class="margin-top-50">Press <strong>Shift + S</strong> to open the Customer Search Modal which allows you to jump directly to any valid GUID.</p>


  <div class="row">
    <div class="col-8 offset-2">
      <img class="img-fluid" src="/assets/whatsnew/shortcuts_customer_search.png"/>
    </div>
  </div>
</div>