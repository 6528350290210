import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/services/auth.service';

/**
 * If any ajax call results in a 401, destroy our token and go to login.
 */
@Injectable()
export class LogoutOnUnauthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(req).pipe(
        tap(
          () => {
          },
          err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status == 401 || (err.status == 400 && err.error.hasOwnProperty('error') && err.error.error === 'token_not_provided')) {
                this.authService.logout(true);
              }
            }
          },
        ));
  }
}
