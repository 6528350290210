<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Dine Card Offer</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <!-- Errors -->
  <div *ngIf="errors">
    <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
      {{errors[key]}}
    </div>
  </div>

  <p>This will subscribe the customer to the Dine Card offer for a certain amount of time.</p>

  <form>
    <div class="form-row">
      <label class="col-sm-4 col-form-label">Subscription Duration</label>
      <div class="col-sm-8">
        <div class="custom-control custom-radio">
          <input type="radio" id="3months" name="duration" class="custom-control-input" value="3" [(ngModel)]="selectedDuration">
          <label for="3months" class="custom-control-label">3 months</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="6months" name="duration" class="custom-control-input" value="6" [(ngModel)]="selectedDuration">
          <label for="6months" class="custom-control-label">6 months</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="9months" name="duration" class="custom-control-input" value="9" [(ngModel)]="selectedDuration">
          <label for="9months" class="custom-control-label">9 months</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="12months" name="duration" class="custom-control-input" value="12" [(ngModel)]="selectedDuration">
          <label for="12months" class="custom-control-label">12 months</label>
        </div>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="submit()">Subscribe</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>
