import { Component, OnInit } from '@angular/core';
import {NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ProspectService} from '../services/prospect.service';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {ProspectEditModalComponent} from '../prospect-edit-modal/prospect-edit-modal.component';

@Component({
  selector: 'app-prospect-list',
  templateUrl: './prospect-list.component.html',
  styleUrls: ['./prospect-list.component.css']
})
export class ProspectListComponent implements OnInit {

  public prospectsPage: LengthAwarePaginator;

  public searchText: string;

  /**
   * Form Model
   */
  public searchModel = {
    searchText: '',
    // fname: '',
    // lname: '',
    // email: '',
    // post_code: '',
    // last_four: '',
    // date_start: '',
    // date_end: '',
    // member_status: '',
    // phone: ''
    is_contacted: ''
  };

  /**
   * Date Structures For Date Picker
   */
  // private dateStart: NgbDateStruct = null;
  // private dateEnd: NgbDateStruct = null;


  constructor(
      // private activatedRoute: ActivatedRoute,
      // private router: Router,
      private prospectService: ProspectService,
      private ngbDateParserFormatter: NgbDateParserFormatter,
      private modalService: NgbModal,
  ) { }


  ngOnInit() {

    // this.accountService
    //     .onCancelAccount
    //     .subscribe(
    //         event => this.getNotes(1)
    //     );

    this.getProspects(1);
  }

  onPageChange(page) {
    this.getProspects(page);
  }

  getProspects(page) {
    // let guid = this.route.snapshot.params['guid'];
    this.prospectService.search(this.searchModel, page).subscribe((lengthAwarePaginator: LengthAwarePaginator) => {
      this.prospectsPage = lengthAwarePaginator;
    });
  }

  filter(filterBy: String) {
    // if(filterBy == 'uncontacted'){
    //     this.searchModel['is_contacted'] = 0;
    // }else{
    //     delete this.searchModel['is_contacted'];
    // }
      this.getProspects(1);
  }

  search() {
    this.getProspects(1);
  }

  reset() {
    this.searchModel.is_contacted = '';
    this.searchModel.searchText = null;
    this.getProspects(1);
  }

  /**
   * Event handler for refetching Notes from server
   */
  refresh(event) {
    this.getProspects(1);
  }

  /**
   * Show Edit Customer Modal
   */
  addProspect() {
    const modalRef = this.modalService.open(ProspectEditModalComponent);
    modalRef.componentInstance.isNewProspect = true;
  }
}
