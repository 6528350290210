import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SmsAlertsService} from '../../services/sms-alerts.service';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';


@Component({
  selector: 'app-customer-sms-alerts',
  templateUrl: './customer-sms-alerts.component.html',
  styleUrls: ['./customer-sms-alerts.component.css'],
})
export class CustomerSmsAlertsComponent implements OnInit {

  private guid = '';
  public smsAlerts: LengthAwarePaginator;  // Array<SmsAlert>;
  public label = 'SMS Alerts';

  constructor(
    private route: ActivatedRoute,
    private SmsAlertsService: SmsAlertsService,
  ) {
  }

  ngOnInit() {
    this.guid = this.route.snapshot.params['guid'];
    // this.fetchSmsEvents(guid);

    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.guid = params['guid'];
        this.fetchSmsAlerts(1);
      },
    );
  }

  resolveAlert(id) {
    this.SmsAlertsService.resolve(id).subscribe(alert => {
      this.updateAlert(alert);
    });
  }

  updateAlert(alert) {
    this.smsAlerts.data.forEach((value, index) => {
      if (value.id === alert.id) {
        this.smsAlerts.data[index] = alert;
      }
    });
  }

  onPageChange(page) {
    this.fetchSmsAlerts(page);
  }

  fetchSmsAlerts(page) {
    this.SmsAlertsService.getByGuid(this.guid, page).subscribe((smsAlerts: LengthAwarePaginator) => {
      this.smsAlerts = smsAlerts;
    });
  }
}
