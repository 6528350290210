<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Password Reset</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <!-- Errors -->
  <div *ngIf="errors">
    <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
      {{errors[key]}}
    </div>
  </div>

  @if (customerExpired()) {
  <div class="alert alert-warning" role="alert">
    Customer is cancelled and past their final date of access.  Password reset will fail.
  </div>
  }

  <p>Are you sure you want to send password reset notification to <strong>{{customer?.fname}} {{customer?.lname}}</strong>?</p>

  <p>A notification will be sent to the email address currently associated with the account: <strong>{{customer?.email}}</strong></p>

  <p appUserAbility="reset-password-url">
    <strong style="color:#0088CB">RESET URL</strong>
    <br>
    <textarea style="width:100%; word-wrap:break-word;min-height: 7rem;">{{reset_url}}</textarea>
  </p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="send()">Send</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>




