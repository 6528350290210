import {Pipe, PipeTransform} from '@angular/core';
import * as glp from 'google-libphonenumber';

@Pipe({
  name: 'ukPhone',
  standalone: true,
})
export class UkPhonePipe implements PipeTransform {

  /**
   * Formatting Style
   * e.g. INTERNATIONAL, NATIONAL, OR RFC3966
   */
  private phoneNumberFormat = glp.PhoneNumberFormat;

  /**
   * Instance of Google Phone Number Parser/Formatter
   */
  private phoneUtil = glp.PhoneNumberUtil.getInstance();


  transform(value: any, args?: any): any {

    try {
      const phoneNumber = this.phoneUtil.parse(value, 'GB');
      return this.phoneUtil.format(
        phoneNumber,
        glp.PhoneNumberFormat.INTERNATIONAL,
      );
    } catch (error) {
      return '';
    }
  }

}
