<div class="card rs-card rs-card-blue">
  <div class="card-body" [ngClass]="{'min-paginated-card-height': reportPostLog?.total >= 10}">
    <div class="clearfix">
      <p class="text-end">{{reportPostLog?.total}} Post Attempts</p>
    </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="reportPostLog?.total > 0">
      <thead class="">
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Destination</th>
        <th>Type</th>
        <th class="text-center">Status</th>
        <th>Error</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let m of reportPostLog.data | slice:pagination.start:pagination.end">
        <td>{{m.id}}</td>
        <td>{{m.dt | dateTimeFormat:'withtime' }}</td>
        <td>{{m.company}}</td>
        <td [ngSwitch]="m.type">
          <span *ngSwitchCase="'PDF_Report'">PDF</span>
          <span *ngSwitchCase="'JSON_Report'">JSON</span>
        </td>
        <td class="text-center">
          <i
            class="fa-solid fa-circle"
            [ngClass]="isSuccess(m.status) ? 'fetch-success' : 'fetch-fail'">
          </i>
        </td>
        <td>{{m.error}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="reportPostLog"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="reportPostLog?.total"
                    [(page)]="pagination.current_page"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm">
    </ngb-pagination>
    <p>Page: {{pagination?.current_page}}</p>
  </div>

</div>
