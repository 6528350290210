import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {ProspectService} from '../services/prospect.service';
import {ProspectInterface} from '../models/prospect.interface';
import {AddNoteModalComponent} from '../../notes/add-note-modal/add-note-modal.component';
import {Note} from '../../notes/models/note.model';
import {ProspectEditModalComponent} from '../prospect-edit-modal/prospect-edit-modal.component';
import {MarkContactedModalComponent} from '../mark-contacted-modal/mark-contacted-modal.component';
import {OfferEmailModalComponent} from '../offer-email-modal/offer-email-modal.component';
import {UserService} from '../../shared/auth/services/user.service';
import {ConfirmationModalComponent} from '../../shared/modal/confirmation-modal/confirmation-modal.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../shared/error/error.service';
import {AlertService} from "@ratespecial/core";


@Component({
    selector: 'app-prospect-detail',
    templateUrl: './prospect-detail.component.html',
    styleUrls: ['./prospect-detail.component.css']
})
export class ProspectDetailComponent implements OnInit {

    public prospect: ProspectInterface;
    private user: any = {};


    constructor(
        private route: ActivatedRoute,
        private prospectService: ProspectService,
        private modalService: NgbModal,
        private alertService: AlertService,
        private userService: UserService,
        private errorService: ErrorService
    ) {
    }


    ngOnInit() {
        const id = this.route.snapshot.params['id'];

        this.userService.get().subscribe(user => {
            this.user = user;
        });

        // Subscribe to prospect save events so we can update display
        this.prospectService
            .onProspectUpdateEvent
            .subscribe(
                event => {
                    this.prospect = event;
                }
            );

        // fetch the desire prospect
        this.prospectService.get(id).subscribe((data: ProspectInterface) => {
            this.prospect = data;
        });
    }


    /**
     * Show Edit prospect Modal
     */
    public editProspect() {
        // const dob = new Date(this.prospect.dob);
        const modalRef = this.modalService.open(ProspectEditModalComponent);
        modalRef.componentInstance.prospectId = this.prospect.id;
    }


    /**
     * Show Add Note Modal
     */
    public addNote() {
        const modalRef = this.modalService.open(AddNoteModalComponent);
        modalRef.componentInstance.note = new Note(
            this.prospect.guid,
            this.prospect.status,
            this.prospect.fname,
            this.prospect.lname,
        );
        modalRef.componentInstance.note.customer_type = 'prospect';
        modalRef.componentInstance.user = this.user;
    }


    /**
     * Show Edit prospect Modal
     */
    public markAsContacted() {
        const modalRef = this.modalService.open(MarkContactedModalComponent);
        modalRef.componentInstance.prospect = this.prospect;
    }


    public emailOffer() {
        const modalRef = this.modalService.open(OfferEmailModalComponent);
        modalRef.componentInstance.emailOfferRequest.id = this.prospect.id;
        modalRef.componentInstance.emailOfferRequest.guid = this.prospect.guid;
    }


    public dnc() {
        this.alertService.info('This feature is not yet implemented');
    }


    /**
     * Show delete confirmation
     */
    public delete() {
        // this.alertService.info('This feature is not yet implemented');

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.title = 'Delete Prospect';
        modalRef.componentInstance.message = 'Are you sure you want to delete this prospect?';

        modalRef.result.then((shouldDelete) => {
            if (shouldDelete) {
                this.deleteProspect();
            }
        });
    }

    private deleteProspect() {

        this.prospectService
            .delete(this.prospect.id)    // , this.customer.guid
            .subscribe(
                (data) => {
                    this.alertService.success('Prospect deleted.');
                    // TODO: CLear display, jump back to prospect list
                },
                (err: HttpErrorResponse) => {
                    this.errorService.handleValidationErrors(err);
                }
            );
    }
}
