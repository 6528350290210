<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <p>Choose which Email Type to send for <strong>{{customer?.fname}} {{customer?.lname}}</strong>.</p>

  <form>

    <input type="hidden" name="guid" [(ngModel)]="sendEmailRequest.guid">

      <div class="form-group">
        <div class="pl-3">
          <div class="form-check">
            <input type="radio" id="welcome" name="type" class="form-check-input" value="welcome" [(ngModel)]="sendEmailRequest.type">
            <label for="welcome" class="form-check-label">Welcome</label>
          </div>

          <div class="form-check">
            <input type="radio" id="cancel" name="type" class="form-check-input" value="cancel" [(ngModel)]="sendEmailRequest.type">
            <label for="cancel" class="form-check-label">Cancel</label>
          </div>

          <div class="form-check">
            <input type="radio" id="mv_start" name="type" class="form-check-input" value="mv_start" [(ngModel)]="sendEmailRequest.type">
            <label for="mv_start" class="form-check-label">Manual Verify - Start</label>
          </div>

          <div class="form-check">
            <input type="radio" id="mv_remind_1" name="type" class="form-check-input" value="mv_remind_1" [(ngModel)]="sendEmailRequest.type">
            <label for="mv_remind_1" class="form-check-label">Manual Verify - Remind 1</label>
          </div>

          <div class="form-check">
            <input type="radio" id="mv_remind_2" name="type" class="form-check-input" value="mv_remind_2" [(ngModel)]="sendEmailRequest.type">
            <label for="mv_remind_2" class="form-check-label">Manual Verify - Remind 2</label>
          </div>

          <div class="form-check">
            <input type="radio" id="thin_file_start" name="type" class="form-check-input" value="thin_file_start" [(ngModel)]="sendEmailRequest.type">
            <label for="thin_file_start" class="form-check-label">Thin File - Start</label>
          </div>

          <div class="form-check">
            <input type="radio" id="cc_alert" name="type" class="form-check-input" value="cc_alert" [(ngModel)]="sendEmailRequest.type">
            <label for="cc_alert" class="form-check-label">Credit Alert</label>
          </div>
        </div>
      </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="sendEmail()">Send</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>




