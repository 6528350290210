import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, shareReplay} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-afid',
  templateUrl: './afid.component.html',
  styleUrls: ['./afid.component.scss']
})
export class AfidComponent implements OnInit {

  publisherName$;
  private _afid: any;

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {

  }

  @Input() set afid(value) {
    if (value && value != this._afid) {
      this.publisherName$ = this.http
        .get('/api/afid/' + value)
        .pipe(
          map((resp: Array<object>) => {
            if (resp.length > 0) {
              return resp[0]['company'];
            }
            return '';
          }),
          shareReplay(),
        );
    } else {
      this.publisherName$ = of('');
    }

    this._afid = value;
  }

  get afid() {
    return this._afid ? this._afid : '-';
  }

}
