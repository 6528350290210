import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AccountSettings} from "../../shared/models/account-settings.interface";

@Component({
  selector: 'app-smart-offers-info-modal',
  templateUrl: './smart-offers-info-modal.component.html',
  styleUrls: ['./smart-offers-info-modal.component.scss']
})
export class SmartOffersInfoModalComponent {

  accountSettings: AccountSettings = null;

  constructor(public activeModal: NgbActiveModal) { }

}
