import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LengthAwarePaginator} from '../shared/models/LengthAwarePaginator';

@Injectable()
export class CallRecordingService {

    constructor(private http: HttpClient) {
    }

    search(searchCriteria): Observable<LengthAwarePaginator> {
        return this.http
            .get<LengthAwarePaginator>('/api/logs/call-recordings', {params: searchCriteria});
    }

    download(fileId) {
        return this.http
            .get('/api/five9/stream/' + fileId);
    }


    getByGuid(guid) {
        return this.http
            .get<LengthAwarePaginator>('/api/logs/call-recordings/guid/' + guid);
    }
}
