<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Customer Search</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<form class="guid-modal-form" (ngSubmit)="submit()">
  <div class="modal-body">

    <app-error></app-error>

    <!-- First Name -->
    <div class="mb-2 row">
      <label for="fname" class="col-sm-4 col-form-label">First Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="fname"
               name="fname"
               required
               #fnameInput
               [(ngModel)]="searchModel.fname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['fname']}">
      </div>
    </div>


    <!-- Last Name -->
    <div class="mb-2 row">
      <label for="lname" class="col-sm-4 col-form-label">Last Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="lname"
               name="lname"
               required
               [(ngModel)]="searchModel.lname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['lname']}">
      </div>
    </div>

    <!--email -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4" for="email">Email</label>
      <div class="controls col-md-8">
        <input type="text"
               id="email"
               name="email"
               class="form-control form-control"
               [(ngModel)]="searchModel.email"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['email']}">
      </div>
    </div>

    <!-- post code -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4" for="postcode">Post Code</label>
      <div class="controls col-md-8">
        <input type="text"
               id="postcode"
               name="postcode"
               class="form-control form-control"
               [(ngModel)]="searchModel.post_code"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['postcode']}">
        <span id="errId4" class="error"></span>
      </div>
    </div>

    <!-- credit card -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4" for="creditcard">Last 4 of Credit Card</label>
      <div class="controls col-md-8">
        <input type="text" name="creditcard" id="creditcard" class="form-control form-control" [(ngModel)]="searchModel.last_four">
      </div>
    </div>

    <!-- Date Range -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4">Date Range</label>
      <div class="controls col-md-4">
        <div class="input-group">
          <input class="form-control form-control" placeholder="yyyy-mm-dd" name="dp1"
                 [(ngModel)]="dateStart" ngbDatepicker #d1="ngbDatepicker" required>
          <button class="btn btn-outline-secondary" type="button" (click)="d1.toggle()">
            <i class="fa-regular fa-calendar" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="controls col-md-4">
        <div class="input-group">
          <input class="form-control form-control" placeholder="yyyy-mm-dd" name="dp2"
                 [(ngModel)]="dateEnd" ngbDatepicker #d2="ngbDatepicker" required>
          <button class="btn btn-outline-secondary" type="button" (click)="d2.toggle()">
            <i class="fa-regular fa-calendar" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Membership -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4" for="member_status">Membership
        Status</label>
      <div class="controls col-md-8">

        <select id="member_status" name="member_status" class="form-control form-control" [(ngModel)]="searchModel.member_status">
          <option value=""></option>
          <option *ngFor="let status of memberstatusOptions" [value]="status">{{status}}</option>
        </select><span id="errId8" class="error"></span></div>

    </div>


    <!-- Phone -->
    <div class="mb-2 row">
      <label class="control-label control-label-left col-md-4" for="phone">Phone</label>
      <div class="controls col-md-8">

        <input id="phone" name="phone" class="form-control form-control"
               type="text" [(ngModel)]="searchModel.phone">
      </div>

    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-outline-primary">Search</button>
    <button type="button" class="btn btn-outline-secondary" (click)="reset()">Reset</button>
  </div>
</form>
