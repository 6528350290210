import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReportPostLogInterface} from "../models/report-post-log.interface";
import {LengthAwarePaginator} from "../../shared/models/LengthAwarePaginator";

@Injectable({
  providedIn: 'root',
})
export class ReportPostLogService {

  private resourceUrl = 'api/logs/report-posts';

  constructor(private http: HttpClient) {
  }

  get(uid: string, page: number) {
    let params = new HttpParams();
    params = params.append('page', page);
    return this.http.get<LengthAwarePaginator<ReportPostLogInterface>>(this.resourceUrl + '/' + uid, {params: params});
  }
}
