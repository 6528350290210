import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {AppErrorHandler} from 'src/app/shared/error/app-error-handler.service';

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  Sentry.init({
    dsn: 'https://b1a2615fe1654fa6b6f361cd758297a6@o401842.ingest.us.sentry.io/5282134',
    release: environment.release,
    environment: environment.sentryEnvironment,

    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint): Sentry.Breadcrumb | null {
      // If this is a navigation breadcrumb, apply our own filters to make sure sensitive data doesn't escape
      if ('category' in breadcrumb && breadcrumb.category === 'navigation') {
        Object.keys(AppErrorHandler.navigationDataFilters).forEach((key => {
          if (breadcrumb.data['from'].indexOf(key) > -1) {
            breadcrumb.data['from'] = AppErrorHandler.navigationDataFilters[key];
          }

          if (breadcrumb.data['to'].indexOf(key) > -1) {
            breadcrumb.data['to'] = AppErrorHandler.navigationDataFilters[key];
          }
        }));
      }

      return breadcrumb;
    },

    beforeSend(event) {
      // Discard 401 and 403 events
      if (event && event.message && (
        event.message.includes('401 Unauthorized')
        || event.message.includes('403 Forbidden')
        || event.message.includes('401 OK')
      )) {

        return null;
      }

      return event;
    },

    ignoreErrors: [
      '0 Unknown Error',  // Seems to happen to a lot of calls at once on mobile users.  network issue?
      "Cannot read properties of undefined (reading 'domInteractive')", // https://github.com/getsentry/sentry-javascript/issues/10011
    ],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
