import {inject, Injectable} from '@angular/core';
import {ErrorHandlerOptions, SentryErrorHandler} from '@sentry/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorSnackBarComponent, ErrorSnackBarData} from './error-snack-bar.component';
import {DOCUMENT} from '@angular/common';

/**
 * Custom error handler, extending SentryErrorHandler, which takes care of "Failed to fetch dynamically imported module" errors.
 * This is inspired by angular.dev's own error handler:
 * https://github.com/angular/angular/blob/8f08d0491630d46ed6d0ba2cfe70ed0e7a0bf58e/adev/src/app/core/services/errors-handling/error-handler.ts
 */

@Injectable({providedIn: 'root'})
export class AppErrorHandler extends SentryErrorHandler {

  static navigationDataFilters = {
    'auth/google': '[auth/google]',       // /auth/google?code={google-auth-code}
  };

  // private accountService = inject(AccountService);
  private snackBar = inject(MatSnackBar);
  private document = inject(DOCUMENT);

  constructor(options?: ErrorHandlerOptions) {
    super(options);
  }

  handleError(error: any) {

    if (typeof error.message === 'string') {
      // Just looking at the first line of the error message (ignoring the call stack part),
      // which should contain a pattern that we are looking for.
      const firstLine = error.message.split('\n')[0];
      if (firstLine?.match(/chunk-(.*?)\.js/)) {
        // Trying to load a chunk that doesn't exist anymore
        // Users should reload the app.
        this.openErrorSnackBar();

        console.error('Chunk error');

        // Consider chunk errors handled.
        return;
      }
    }

    super.handleError(error);
  }

  /**
   * If available, make sure sentry has the account details.  Apparently this can't be called from the contructor as this class is created
   * way early in the app initialize process, and the NGXS state isn't ready yet.
   */
  /*configureScope(): void {
    this.accountService
      .getAccount()
      .subscribe((account: IAccount) => {

          if (account) {
            Sentry.getCurrentScope().setUser({
              id: account.user.guid,
              email: account.user.email,
            });
          } else {
            // scope.clear() ?
            Sentry.getCurrentScope().setUser(null);
          }

      });
  }*/

  /**
   * Show a message telling the user to refresh the page, since the old chunks aren't available anymore.
   */
  private openErrorSnackBar(): void {
    this.snackBar
      .openFromComponent(ErrorSnackBarComponent, {
        // panelClass: '',
        data: {
          message: `Our portal have been updated, reload the page to see the latest.`,
          actionText: `Reload`,
        } satisfies ErrorSnackBarData,
      })
      .onAction()
      .subscribe(() => {
        this.document.location.reload();
      });
  }
}

/**
 * Provider factory to use with Angular DI
 */
export const appErrorFactory = () => {
  return new AppErrorHandler({
    showDialog: false,
  });
};
