<div class="card rs-card rs-card-blue">
  <div class="card-body min-paginated-card-height">

    <div class="clearfix">
      <p class="text-end">{{offerclicks?.total}} Clicks</p>
    </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped">
      <thead class="">
      <tr>
        <th>Time</th>
        <th>Name</th>
        <!--<th>Environment</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let o of offerclicks?.data">
        <td>{{o.dt | dateTimeFormat:'withtime' }}</td>
        <td>{{o.name}}</td>
        <!--<td class="text-center">-->
          <!--<i (click)="viewEnv(l.id)" class="fa fa-lg fa-search clickable" ngbTooltip="{{l.environment}}"></i>-->
        <!--</td>-->
      </tr>
      </tbody>
    </table>

    <div class="text-center" *ngIf="offerclicks?.total == 0">There are no offer clicks.</div>

  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="offerclicks"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="offerclicks?.total"
                    [(page)]="offerclicks.current_page"
                    [pageSize]="offerclicks?.per_page"
                    [maxSize]="5" [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm"></ngb-pagination>
    <p>Page: {{offerclicks?.current_page}}</p>
  </div>

</div>
