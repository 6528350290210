import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SessionActions} from './session.actions';
import {Injectable} from '@angular/core';
import {User} from 'src/app/shared/models/user.model';
import UpdateUser = SessionActions.UpdateUser;
import ClearState = SessionActions.ClearState;
import UpdateIsAuthenticated = SessionActions.UpdateIsAuthenticated;


export interface SessionStateModel {
  user: User;
  isAuthenticated: boolean;
}


const sessionStateDefaults: SessionStateModel = {
  user: null,
  isAuthenticated: false,
};


@State<SessionStateModel>({
  name: 'session',
  defaults: sessionStateDefaults
})


@Injectable()
export class SessionState {

  @Selector()
  static getUser(state: SessionStateModel) {
    return state.user;
  }

  @Selector()
  static isAuthenticated(state: SessionStateModel) {
    return state.isAuthenticated;
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<SessionStateModel>, action: UpdateUser) {
    ctx.patchState({user: action.user});
  }

  @Action(UpdateIsAuthenticated)
  updateIsAuthenticated(ctx: StateContext<SessionStateModel>, action: UpdateIsAuthenticated) {
    ctx.patchState({isAuthenticated: action.isAuthenticated});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<SessionStateModel>) {
    ctx.setState(sessionStateDefaults);
  }
}
