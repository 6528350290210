<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Note #{{ noteId }}</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <dl class="row">
    <dt class="col-sm-4">Note Id:</dt>
    <dd class="col-sm-8">{{ note?.id }}</dd>

    <dt class="col-sm-4">Date</dt>
    <dd class="col-sm-8">{{ note?.dt | dateTimeFormat:'withtime' }}</dd>

    <dt class="col-sm-4">User</dt>
    <dd class="col-sm-8">{{ note?.user_name }}</dd>

    <dt class="col-sm-4 text-truncate">Channel Type</dt>
    <dd class="col-sm-8">{{ note?.type_name }}</dd>

    <dt class="col-sm-4">Inquiry Type</dt>
    <dd class="col-sm-8">{{ note?.inquiry_type_name }}</dd>

    <dt class="col-sm-4">Note Content</dt>
    <dd class="col-sm-8" [innerHTML]="note?.body | nl2br"></dd>
  </dl>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
</div>
