<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Refund Transaction</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <!-- Errors -->
  <app-error></app-error>

  <p>Are you sure you want to refund transaction # {{transaction?.trans_num}} in the amount of
    <strong>{{refundRequest?.refund_amount | currency : 'GBP'}}</strong> to {{customer.fname}} {{customer.lname}}?</p>

  <form>

    <input type="hidden" name="guid" [(ngModel)]="refundRequest.guid">

    <!-- Reason -->
    <div class="form-group form-row">
      <label for="reason" class="col-sm-4 col-form-label">Reason</label>
      <div class="col-sm-8">
        <select
            name="reason"
            id="reason"
            required
            class="form-control"
            [(ngModel)]="refundRequest.reason"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['reason']}"
        >
          <optgroup *ngFor="let groupname of objectKeys(reasons)" [label]="groupname">
            <option *ngFor="let r of reasons[groupname]" [ngValue]="r">{{r.body}}</option>
          </optgroup>
        </select>
      </div>
    </div>

    <!-- Amount -->
    <div class="form-group form-row">
      <label for="reason" class="col-sm-4 col-form-label">Amount</label>

      <div class="col-sm-8">
        <div class="form-check form-check-inline" *ngFor="let refundAmount of refundAmounts; let i = index">
          <input class="form-check-input"
                 type="radio"
                 name="amount"
                 [id]="'amount' + i"
                 [value]="refundAmount"
                 [(ngModel)]="refundRequest.refund_amount"
                 [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['reason']}">

          <label class="form-check-label" [for]="'amount' + i">{{refundAmount | currency : 'GBP'}}</label>
        </div>

        <div class="form-check form-check-inline">
          <i class="fa-regular fa-circle-question" aria-hidden="true"
             *ngIf="transaction?.hasPartialRefund"
             ngbTooltip="Either Partial Refund has already been issued or customer billed at a discount price."
             placement="right"
             container="body"></i>
        </div>
      </div>
    </div>

    <!-- Notes -->
    <div class="form-group form-row">
      <label for="body" class="col-sm-4 col-form-label">Note</label>
      <div class="col-sm-8">
        <textarea
            class="form-control"
            id="body"
            name="body"
            rows="3"
            required
            [(ngModel)]="refundRequest.note"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['note']}"></textarea>
      </div>
    </div>

    <!--<pre>{{refundRequest | json}}</pre>-->

  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="refund()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>
