import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class ChargebackService {

  private resourceUrl = 'api/transaction/log/chargeback';

  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onLogChargeback = new EventEmitter<any>();


  constructor(private http: HttpClient) {
  }


  refund(chargebackRequest) {
    const headers = new HttpHeaders()
        .set('Content-Type', 'application/json');

    return this.http.post(this.resourceUrl, chargebackRequest, {headers}).pipe(tap(
        (data) => {
          this.notify(data);
        }));
  }


  /**
   * Notify any subscribers
   * @param event
   */
  notify(event) {
    this.onLogChargeback.emit(event);
  }
}
