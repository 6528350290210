import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthSanctumInterceptor} from './interceptor/http/auth-sanctum.interceptor';
import {LogoutOnUnauthInterceptor} from './interceptor/http/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from './interceptor/http/server-error.interceptor';
import {ErrorComponent} from './error/error.component';
import {ErrorService} from './error/error.service';
import {ConfirmationModalComponent} from './modal/confirmation-modal/confirmation-modal.component';
import {APP_CONFIG, AppConfig} from '../app.config';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {LoadIndicatorInterceptor} from '@ratespecial/core';


@NgModule({
  declarations: [
    ConfirmationModalComponent,
  ],
  exports: [
    BrowserModule,
    FormsModule,
    NgbModule,
    ConfirmationModalComponent,
    ErrorComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ErrorComponent,
  ],
  providers: [
    {provide: APP_CONFIG, useValue: AppConfig},
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthSanctumInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withLocalStorage(),
      withSessionStorage(),
    ),
  ],
})
export class SharedModule {
}
