<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Mark Prospect Contacted</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  Do you wish to set the prospect status as "contacted"? This will close the prospect and remove from the prospect contact list.
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>
