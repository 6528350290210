import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as PDFObject from 'pdfobject';

@Component({
  selector: 'app-mail-events-pdf-modal',
  templateUrl: './mail-events-modal.component.html',
  styleUrls: ['./mail-events-modal.component.scss']
})
export class MailEventsModalComponent {
  @Input() pdfUrl: string; // Input property to receive the PDF URL
  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    if (this.pdfUrl) {
      const options = {
        pdfOpenParams: {
          toolbar: '0',
        },
        width: '100%', // Set the width to your desired value
        height: '600px',
      };

      // Display the PDF in the modal
      PDFObject.embed(this.pdfUrl, '#pdfViewer', options);
    }
  }

  close() {
    this.modalService.dismissAll();
  }

}
