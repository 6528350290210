import {Component} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {DeferredAuthCardResponse} from '../../models/deferred-auth-card-response';
import {ProcessorResponse} from '../../models/processor-response.enum';
import {PaayThreedsService} from '../../services/paay/PaayThreedsService';
import {Customer} from '../../../customer/models/customer.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadIndicatorService} from "@ratespecial/core";
import {ThreedsResults} from "../../services/paay/threeds-results";
import {environment} from "../../../../environments/environment";
import {NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';


@Component({
  selector: 'app-deferred-auth-modal',
  templateUrl: './deferred-auth-modal.component.html',
  styleUrls: ['./deferred-auth-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    ReactiveFormsModule,
    NgClass,
  ],
})
export class DeferredAuthModalComponent {

  public normalPrice = 34.95;
  public cardForm: UntypedFormGroup;
  public years: number[];
  public months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public errors = [];
  public isSubmitted = false;
  public isSuccess = false;
  public guid = null;
  public customer: Customer;
  public isShowModal = false;
  public deferredAuthCardResponse: DeferredAuthCardResponse = null;
  public processorResponse = ProcessorResponse;
  public hasUnknownError = false;
  public isPriceDiscounted = false;
  public discountPercent = 0.0;

  /**
   * Reference the the callback function supplied to listenForChallengeComplete.  This is kept so
   * we can safely remove it later.
   * @private
   */
  private lastChallengeCallbackFunction: () => void;

  public tdsFields = {
    id: ''
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private threedsService: PaayThreedsService,
    private loadIndicatorService: LoadIndicatorService,
    public activeModal: NgbActiveModal,
  ) {}


  modalInit(): void {
    this.initializeForm();
    this.init3Ds();
    this.calculateDiscount();
  }


  isDeferredAuthComplete() {
    return (this.customer && this.customer.deferred_auth === 1 && this.customer.deferred_auth_complete === 1);
  }

  /**
   * calcs discount percent if there is one and toggles displayed messaging
   */
  private calculateDiscount(): void {
    const price = parseFloat(this.customer.recurring_price);
    if (price < this.normalPrice) {
      this.isPriceDiscounted = true;
      this.discountPercent = Math.round((this.normalPrice - price) / this.normalPrice * 100);
    }
  }

  /**
   * Create initial form object with validators
   */
  private initializeForm(): void {

    this.years = this.getYears();

    // test card: 4916909992637469 / 321
    this.cardForm = this.formBuilder.group({
      ccn: ['', [
        Validators.required,
      ]],
      cvv: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{3,4}$')
      ]],
      expy: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{2}$')
      ]],
      expm: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{2}$')
      ]],
    });
  }

  private init3Ds(): void {
    this.threedsService.activate();

    this.tdsFields.id = this.threedsService.generateUniqueId();

    // TODO: Acquired related init

    // When the threeds library needs more information, it tries to show the Iframe.  This is a callback
    // letting us know that it is happening.
    this.threedsService.setIframeCallbackHandler(() => {
      this.showModal();
    });

    this.listenForChallengeComplete(() => {
      // The server will have already stored the cres values in cache, so let's try again.
      // this.executeProcess(); //TODO: Acquired
      this.closeModal();
    });
  }

  private showModal(): void {
    this.isShowModal = true;
  }

  public closeModal(): void {
    this.isShowModal = false;
  }

  save(): void {
    this.resetErrors();
    this.init3Ds();
    this.isSubmitted = true;

    if (this.cardForm.dirty && this.cardForm.valid) {
      this.loadIndicatorService.push('3ds');
      this.execute3ds();
    } else {
      this.isSubmitted = false;
    }
  }

  resetErrors() {
    this.hasUnknownError = false;
    this.errors = [];
  }

  // Test Card: 4012000033330026
  private execute3ds(): void {
    this.threedsService
      .verify(this.customer.recurring_price)
      .then((resp) => {
        this.loadIndicatorService.push('authcard');
        this.postAuth(resp);
      })
      .catch((e) => {
        this.handleThreeDsErrors(e);
        this.isSubmitted = false;
      })
      .finally(() => {
        this.closeModal();
        this.loadIndicatorService.pop('3ds');
      });
  }

  /**
   * We are expecting either array of string or a string.  Anything else, we show a generic error to the user cause
   * it's probably internal 3ds errors we want to go to Sentry.
   * @param e
   */
  private handleThreeDsErrors(e): void {
    try {
      const json = JSON.parse(e);

      this.errors.push('3DS: ' + json.error);
    } catch (exception) {
      if (Array.isArray(e)) {
        this.errors = e;
        return;
      } else {
        this.errors.push('An error ocurred during 3DS processing.  Check the card and try again.');
      }
    }
  }

  // TODO: Acquired goes here
  private executeProcess(): void {
  }

  private postAuth(resp: ThreedsResults) {
    // Combine both user form with 3DS response
    const form = {...this.cardForm.value, ...resp, ...{guid: this.guid}};

    // 3DS as complete/accepted, post to checkout
    this.http.post('/api/card-services/auth-card', form)
      .subscribe((response: DeferredAuthCardResponse) => {
          this.handleResponse(response);
        },
        error => {
          console.log(error); // Get it in Sentry
          this.loadIndicatorService.pop('authcard');
        }
      );
  }

  handleResponse(response: DeferredAuthCardResponse): void {
    this.loadIndicatorService.pop('authcard');
    this.deferredAuthCardResponse = response;

    if (this.deferredAuthCardResponse.result === ProcessorResponse.SUCCESS) {
      this.isSuccess = true;
    } else {
      // deferredAuthCardResponse error will display in template except if card wasn't attempted, may not have error message at all.
      if (!this.deferredAuthCardResponse.cardWasAttempted) {
        this.hasUnknownError = true;
      }
    }
  }

  isPostSuccess(): boolean {
    return (this.deferredAuthCardResponse && this.deferredAuthCardResponse.result === ProcessorResponse.SUCCESS);
  }

  /**
   * Populate select for expiration year
   */
  private getYears(): number[] {
    const year = (new Date()).getFullYear();
    const years = [];
    let i = 0;

    while (i < 10) {
      years.push(year + i);
      i++;
    }

    return years;
  }

  /**
   * getter for GUI access of validators
   */
  get formControls() {
    return this.cardForm.controls;
  }

  hasChanges(): boolean {
    return this.cardForm.dirty;
  }

  /**
   * When the bank's challenge process is complete, they redirect the form over to our page.  This page
   * uses window.postMessage() to announce that it has been reached.  This function sets up a callback
   * for that postMessage.
   *
   * @param callback
   */
  private listenForChallengeComplete(callback: () => void): void {
    // Remove previous callback, if applicable
    if (this.lastChallengeCallbackFunction) {
      window.removeEventListener('message', this.lastChallengeCallbackFunction);
    }

    // Listen for events from our ChallengeResponseController
    window.addEventListener('message', (event) => {
      // This is the domain that is specified in the challenge_url variable on the Acquired post
      if (event.origin !== window.origin) {
        return;
      }

      // The only acceptable data.  This is specified in 3ds-iframe-result.blade
      if (event.data !== 'challenge finished') {
        return;
      }

      callback();
    });

    // Store copy of the callback so we can properly remove it
    this.lastChallengeCallbackFunction = callback;
  }

  isProduction(): boolean {
    return environment.production;
  }
}
