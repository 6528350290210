<div class="card rs-card rs-card-blue">
  <div class="card-body min-paginated-card-height">
      <div class="clearfix">
          <p class="text-end">{{cancelevents?.total}} Events</p>
      </div>
    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped">
      <thead class="">
      <tr>
        <th>Time</th>
        <th>Type</th>
        <th>Reason</th>
        <th class="text-center">info</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let c of cancelevents?.data">
        <td>{{c.updated_at | dateTimeFormat:'withtime' }}</td>
        <td>{{c.type}}</td>
        <td>{{c.other}}</td>
        <td class="text-center" [ngSwitch]="c.type">
            <span *ngSwitchCase="'start'" ngbTooltip="The cancel account modal was opened" placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'cancel-reason'" ngbTooltip="First modal was completed, answering &quot;why are you cancelling&quot;" placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'cancel'" ngbTooltip="They finished the cancellation modal asking to cancel, and they were cancelled automatically and logged out." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'cancel-reason-other'" ngbTooltip="If cancel reason was &quot;other&quot;, this will be what they manually entered as the reason" placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'downsell'" ngbTooltip="The customer's price point was lowered.  Contains price point that it was lowered to." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'hard-to-use-reason'" ngbTooltip="Customer selected &quot;hard to use&quot; for cancel reason.  Another modal was displayed, asking what they found difficult.  This contains what they entered." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'cancel-manual'" ngbTooltip="They finished the cancellation modal asking to cancel, however it was determined to let customer service contact them first.  Not actually cancelled." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'concern'" ngbTooltip="They finished the cancellation modal, and optioned to leave a comment/concern." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
            <span *ngSwitchCase="'concern-other'" ngbTooltip="They finished the cancellation modal, and optioned to leave a custom comment/concern.  This contains the text the left." placement="left" container="body">
                <i class="fa-solid fa-circle-info font-blue"></i>
            </span>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="text-center" *ngIf="cancelevents?.total == 0">There has been no cancellation activity.</div>
  </div><!-- END Body -->

    <div class="card-footer d-flex justify-content-between">
        <ngb-pagination *ngIf="cancelevents"
                        (pageChange)="onPageChange($event)"
                        [collectionSize]="cancelevents?.total"
                        [(page)]="cancelevents.current_page"
                        [pageSize]="cancelevents?.per_page"
                        [maxSize]="5" [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        size="sm"></ngb-pagination>
      <p>Page: {{cancelevents?.current_page}}</p>
    </div>
</div>

