import {Component, OnInit, signal} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../services/account.service';
import {PwResetResponse} from 'src/app/customer/models/pw-reset-response.interface';
import {AlertService} from '@ratespecial/core';
import {CommonModule} from '@angular/common';
import {Customer} from 'src/app/customer/models/customer.model';
import {UserAbilityDirective} from 'src/app/shared/auth/user-ability.directive';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    UserAbilityDirective,
  ],
})
export class PasswordResetModalComponent implements OnInit {

  /**
   * Customer Data needed for request
   */
  public customer: Customer;

  /**
   * Form validation errors
   */
  public errors: any;

  public reset_url = '';

  // Add reference to Object.keys function to template
  objectKeys = Object.keys;

  // If the customer is cancelled and not able to login.
  customerExpired = signal<boolean>(false);

  constructor(
    private accountService: AccountService,
    private alertService: AlertService,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    // This field is only present if the customer is cancelled
    const finalDayOfAccessDt = this.customer.finalDayOfAccessDt;

    if (finalDayOfAccessDt) {
      this.customerExpired.set(finalDayOfAccessDt < DateTime.now());
    }
  }

  send() {
    this.accountService.sendPasswordResetEmail(this.customer.getCustomerLite())
      .subscribe({
        next: (response: PwResetResponse) => {
          this.reset_url = response.url;
          this.alertService.success('A Password Reset Notification Email has been sent to ' + this.customer.email);
        },
        error: () => {
          // Global Handler handles this
        },
      });
  }

  close() {
    this.activeModal.close();
  }
}
