<div class="card rs-card rs-card-blue">
  <div class="card-body min-paginated-card-height">

      <div class="clearfix">
          <p class="text-end">{{mailEvents?.length}} Events</p>
      </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped">
      <thead class="">
      <tr>
        <th>Event Date</th>
        <th><span ngbTooltip="Email Service Provider">ESP</span></th>
        <th>Event Type</th>
        <th>Subject</th>
        <th>Tag</th>
        <th class="text-center">Info</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let m of mailEvents | slice:pagination.start:pagination.end">
        <td>{{m.event_date | dateTimeFormat:'withtime' }}</td>

        <ng-container [ngSwitch]="m.esp">
        <td *ngSwitchCase="'mailgun'"><span ngbTooltip="Mailgun">MG</span></td>
        <td *ngSwitchCase="'active_campaign'"><span ngbTooltip="Active Campaign">AC</span></td>
        <td *ngSwitchDefault>{{m.esp}}</td>
        </ng-container>

        <td>{{m.event_type}}</td>
        <td>{{m.subject}}</td>
        <td>{{m.tag}}</td>
        <td class="text-center">
          <span
            (click)="showPdfTemplate(m.tag)"
            *ngIf="allowedTags(m.tag)"
            [ngbTooltip]="info[m.event_type]"
            container="body"
            placement="left"
            style="cursor: pointer;">

            <i class="fa-solid fa-circle-info font-blue"></i>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="text-center mt-4" *ngIf="mailEvents?.length == 0">There are no mail events.</div>
  </div>

    <div class="card-footer">
        <div class="d-flex justify-content-between">
            <ngb-pagination *ngIf="mailEvents"
                            (pageChange)="onPageChange($event)"
                            [collectionSize]="mailEvents?.length"
                            [(page)]="pagination.current_page"
                            [maxSize]="5"
                            [rotate]="true"
                            [ellipses]="false"
                            [boundaryLinks]="true"
                            size="sm">
            </ngb-pagination>
          <p>Page: {{pagination?.current_page}}</p>
        </div>
    </div>
</div>

