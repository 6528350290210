import {Component, OnInit} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from '../../../shared/error/error.service';

@Component({
  selector: 'app-screenshot-modal',
  templateUrl: './screenshot-modal.component.html',
  styleUrls: ['./screenshot-modal.component.css']
})
export class ScreenshotModalComponent implements OnInit {

  public guid;
  public trans_num;
  public screenshot;

  constructor(
    private customerService: CustomerService,
    private activeModal: NgbActiveModal,
    public errorService: ErrorService
  ) {
  }

  ngOnInit() {
    // Get Screenshot record
    this.screenshot = this.customerService.getScreenshotDetail(this.guid, this.trans_num);
  }

  close() {
    this.activeModal.close();
  }
}
