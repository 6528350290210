<div class="container">
	<h3>Paid Trials</h3>

	<div class="card card-body rs-card rs-card-blue">

		<form #searchForm="ngForm" class="customer-search-form">
			<div class="row">
				<div class="col-md-5">
					<!-- fname -->
					<div class="mb-2 row">
						<label class="control-label control-label-left col-md-4" for="fname">First Name</label>
						<div class="col-md-8">
							<input type="text" name="fname" id="fname" class="form-control form-control-sm" [(ngModel)]="searchModel.fname" >
						</div>
					</div>

					<!-- lname -->
					<div class="mb-2 row">
						<label class="control-label control-label-left col-md-4" for="lname">Last Name</label>
						<div class="controls col-md-8">
							<input type="text" id="lname" name="lname" class="form-control form-control-sm" [(ngModel)]="searchModel.lname">
							<span id="errId2" class="error"></span>
						</div>
					</div>

          <!-- email -->
          <div class="mb-2 row">
            <label class="control-label control-label-left col-md-4" for="email">Email</label>
            <div class="controls col-md-8">
              <input type="text" id="email" name="email" class="form-control form-control-sm" [(ngModel)]="searchModel.email">
            </div>
          </div>
				</div>
        <div class="col-md-5">
          <!-- first six -->
          <div class="mb-2 row">
            <label class="control-label control-label-left col-md-2" for="card_bin">First 6</label>
            <div class="controls col-md-8">
              <input type="text" id="card_bin" name="card_bin" class="form-control form-control-sm" [(ngModel)]="searchModel.card_bin">
            </div>
          </div>
          <div class="mb-2 row">
            <label class="control-label control-label-left col-md-2" for="last_four">Last 4</label>
            <div class="controls col-md-8">
              <input type="text" id="last_four" name="last_four" class="form-control form-control-sm" [(ngModel)]="searchModel.last_four">
            </div>
          </div>
        </div>

			</div>

			<div class="row">
				<div class="col-md-12">
					<div class="search-buttons float-end">
						<button type="submit" (click)="submit()" class="btn btn-sm btn-outline-primary me-2">Search</button>
						<button type="button" class="btn btn-sm btn-outline-secondary" (click)="reset()">Reset</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>


<div class="container">

	<h5 class="margin-top-35">Search Results:</h5>

	<div class="card rs-card rs-card-blue">
		<div class="card-body">

			<div class="row">
				<div class="col-sm-12">
					<table class="table table-sm  table-search-results" style="table-layout:fixed">
						<thead class="">
						<tr>
              <th>GUID</th>
              <th>First Name</th>
							<th>Surname</th>
							<th>Email</th>
							<th>Fist 6</th>
							<th>Last 4</th>
              <th>Charged</th>
							<th>Refunded</th>
              <th>Status</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let rec of trials">
              <td *ngIf="rec.status">
                <a [routerLink]="['/customer/detail', rec.guid]">{{ rec.guid | uppercase}}</a>
              </td>
              <td *ngIf="!rec.status">
                {{ rec.guid | uppercase}}
              </td>
              <td>{{ rec.fname}}</td>
							<td>{{ rec.lname}}</td>
							<td>{{ rec.email}}</td>
							<td>{{ rec.card_bin}}</td>
							<td>{{ rec.last_four}}</td>
              <td>{{ rec.trial_dt }}</td>
							<td>{{ rec.refund_dt}}</td>
              <td>{{ rec.status}}</td>
						</tr>
						</tbody>
					</table>

				</div>
			</div>
		</div>

	</div>

</div>
