import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class PricepointService {

    private resourceUrl = 'api/pricepoint';

    /**
     * Notification to any subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onUpdatePricepoint = new EventEmitter<any>();

    constructor(private http: HttpClient) {
    }

    /**
     * Fetch all pricepoints allowed for current user
     * @returns {Observable<Object>}
     */
    get() {
        return this.http.get(this.resourceUrl);
    }

    update(pricepoint) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return this.http.put(this.resourceUrl, pricepoint, {headers}).pipe(
            tap(response => {
                this.notify(true);
            }));
    }

    /**
     * Notify any subscribers
     * @param event
     */
    notify(pricepoint) {
        this.onUpdatePricepoint.emit(pricepoint);
    }
}
