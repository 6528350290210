<div class="container">

  <div class="d-flex justify-content-between">

    <h2 class="p-2">
      <span *ngIf="prospect.deleted_at; else notDeleted">
        <del>{{prospect?.fname}} {{ prospect?.lname}}</del>
      </span>
      <ng-template #notDeleted>
        {{prospect?.fname}} {{ prospect?.lname}}
      </ng-template>
    </h2>

    <div class="p-10">
      <div class="d-flex flex-row">
        <div class="p-2"><i (click)="editProspect()" class="fa-solid fa-pencil fa-lg clickable" aria-hidden="true" ngbTooltip="Edit Prospect Details"></i></div>
        <div class="p-2"><i (click)="addNote()" class="fa-regular fa-note-sticky fa-lg clickable" aria-hidden="true" ngbTooltip="Add Note"></i></div>

        <div class="p-2" [ngSwitch]="prospect?.is_contacted">
          <i *ngSwitchCase="1" class="fa-solid fa-phone fa-lg clickable" aria-hidden="true" ngbTooltip="Contacted {{prospect?.dt_contacted | dateTimeFormat:'withtime' }}"></i>
          <i *ngSwitchDefault (click)="markAsContacted()" class="fa-solid fa-phone fa-lg clickable" aria-hidden="true" ngbTooltip="Mark as Contacted"></i>
        </div>

        <div class="p-2"><i (click)="emailOffer()" class="fa-regular fa-envelope fa-lg clickable" aria-hidden="true" ngbTooltip="Email an Offer"></i></div>
        <div class="p-2"><i (click)="dnc()" class="fa-solid fa-ban fa-lg clickable" aria-hidden="true" ngbTooltip="Mark as Do Not Contact"></i></div>
        <div class="p-2"><i (click)="delete()" class="fa-solid fa-trash fa-lg clickable" aria-hidden="true" ngbTooltip="Delete this Prospect"></i></div>

      </div>
    </div>
  </div>


  <div class="card rs-card rs-card-blue">
    <div class="card-body">
      <!--<h4 class="card-title">prospect</h4>-->
      <!--<h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>-->

      <div class="row">
        <div class="col-sm-6">
          <dl class="row">
            <dt class="col-5">First Name</dt>
            <dd class="col-7">{{prospect?.fname}}</dd>

            <dt class="col-5">Last Name</dt>
            <dd class="col-7">{{prospect?.lname}}</dd>

            <dt class="col-5">Email</dt>
            <dd class="col-7">{{prospect?.email}}</dd>

            <dt class="col-5">Phone</dt>
            <dd class="col-7">{{prospect?.phone | ukPhone}}</dd>

            <dt class="col-5">Address</dt>
            <dd class="col-7">{{prospect?.straddr}}</dd>

            <dt class="col-5">Building Name</dt>
            <dd class="col-7">{{prospect?.building_name}}</dd>

            <dt class="col-5">City</dt>
            <dd class="col-7">{{prospect?.city}}</dd>

            <dt class="col-5">Postcode</dt>
            <dd class="col-7">{{prospect?.zip}}</dd>

            <!--<dt class="col-5">Pricepoint</dt>-->
            <!--<dd class="col-7">{{prospect?.recurring_price}}</dd>-->

            <!--<dt class="col-5">Trial End</dt>-->
            <!--<dd class="col-7">{{prospect?.trial_end_date}}</dd>-->
          </dl>
        </div>

        <div class="col-sm-6">
          <dl class="row">
            <dt class="col-5">DOB</dt>
            <dd class="col-7">{{prospect?.dob | date:'d MMM yyyy'}}</dd>

            <dt class="col-5">Third Party Marketing</dt>
            <dd class="col-7" >{{prospect?.is_third_party_marketing ? 'Yes' : 'No'}}</dd>

            <!--<dt class="col-5 text-truncate">Last IP Address</dt>-->
            <!--<dd class="col-7">{{prospect?.last_ip}}</dd>-->

            <dt class="col-5">Status</dt>
            <dd class="col-7">{{prospect?.status}}</dd>

            <dt class="col-5">Has Been Contacted</dt>
            <dd class="col-7">{{prospect?.is_contacted ? 'Yes' : 'No'}}</dd>

            <dt class="col-5">Date Contacted</dt>
            <dd class="col-7">{{prospect?.dt_contacted | dateTimeFormat:'withtime' }}</dd>

            <!--<dt class="col-5">CID</dt>-->
            <!--<dd class="col-7">{{prospect?.cid ? prospect?.cid : '-'}}</dd>-->

            <dt class="col-5">AFID</dt>
            <dd class="col-7">{{prospect?.afid ? prospect?.afid : '-'}}</dd>

            <!--<dt class="col-5">SID</dt>-->
            <!--<dd class="col-7">{{prospect?.sid ? prospect?.sid : '-'}}</dd>-->

            <!--<dt class="col-5">Processor</dt>-->
            <!--<dd class="col-7">{{prospect?.processor_name ? prospect?.processor_name : '-'}}</dd>-->

            <!--<dt class="col-5">Vertical</dt>-->
            <!--<dd class="col-7">{{prospect?.vertical ? prospect?.vertical : '-'}}</dd>-->
          </dl>
        </div>
      </div>
    </div>
  </div>



  <div  class="padding-top-35">
    <app-customer-notes></app-customer-notes>
  </div>



</div>


