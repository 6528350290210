import {Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../models/customer.model';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent implements OnInit {

  @Input() customer: Customer;

  constructor() { }

  ngOnInit() {
  }

  isDeferredAuth(): boolean {
    return this.customer?.deferred_auth === 1
      && this.customer?.deferred_auth_complete === 0
      && this.customer?.status !== 'cancelled';
  }

  /**
   * If we should show the indicator that changes by customer.status
   */
  shouldShowStatus(): boolean {
    return ['cancelled', 'returnreverse', 'chargeback', 'prospect', 'manual', 'thin_file'].indexOf(this.customer.status) > -1;
  }
}
