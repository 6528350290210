import {IAuthenticationStepTwoResponse} from './IAuthenticationStepTwoResponse';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {PaayOptionsInterface} from './paay-options.interface';
import {ThreedsResults} from "./threeds-results";


declare var ThreeDS: any;

/**
 * Wrapper class for 3dsintegrator v2.1.
 */

@Injectable({
  providedIn: 'root'
})
export class PaayThreedsService {

  /**
   * Actual ThreeDS instance, as provided by 3dsintegrator.com
   */
  public tds: typeof ThreeDS;

  public promiseResolve: (value: (PromiseLike<ThreedsResults> | ThreedsResults)) => void;
  public promiseReject: (reason?: any) => void;
  private defer: Promise<ThreedsResults> | null = null;

  private iframeCallback: () => {};
  private threedsResults: ThreedsResults = new ThreedsResults();

  /**
   * Turn 3ds on or off completely
   */
  private enabled = true;

  private threeDsOptions: PaayOptionsInterface = {
    verbose: true,
    autoSubmit: false,
    resolve: this.resolvedAuth.bind(this),
    reject: this.rejectedAuth.bind(this),
    iframeId: '3ds-iframe',
    showIframe: true,   // I believe this is being deprecated for showChallenge
    showChallenge: true,
    prompt: this.showIframeCallback.bind(this),
    rebill: 0,
  };

  constructor() {
    if (!environment.production) {
      this.threeDsOptions.endpoint = 'https://api-sandbox.3dsintegrator.com/v2';
    }
  }


  /**
   * Determine if an error should report into sentry
   */
  private static reportError(error: string): void {
    const acceptableErrors = [
      'unrecognized credit card no',
      'Invalid credit card information',
      'error contacting DS, ensure that the card type is supported',
      'card not enrolled',
      'invalid  credit card',
      'Card Not Enrolled',
      'could not tokenize PAN',
      'Driver not configured for card type',
      'Invalid credit card information',
    ];

    for (let i = 0; i < acceptableErrors.length; i++) {
      if (error.indexOf(acceptableErrors[i]) !== -1) {
        return;
      }
    }

    // This will have it end up in Sentry.  i'm not longer convinced this is true.  we might
    // need to call sentryservice explicitly here.
    console.log(error);
  }

  /**
   * This must be called before any other function calls.
   *
   * @param forceVersion1 Don't try and use 3DS v2
   */
  activate(forceVersion1: boolean = false): void {
    const options = {...this.threeDsOptions};
    options.protocolVersion = forceVersion1 ? '1.0.2' : null;

    if (this.enabled) {
      this.tds = new ThreeDS('mainForm', '4122cd4066d8c6c0f1d092743381edba', null, options);
    }
  }

  isRebillEnabled(): boolean {
    return this.tds.options.rebill > 0;
  }

  /**
   * If we have enough responses to be considered good for a product order
   */
  areResultsComplete(): boolean {
    // We only care if the inital charge is 3DS, as this is what is required by payment processors.  The rebill is a nice to have,
    // but should not stop us from processing the order.
    return this.threedsResults.isFirstResultSuccessful();

    /*if (!this.isRebillEnabled()) {
      return this.threedsResults.isFirstResultSuccessful();
    } else {
      return this.threedsResults.isFirstResultSuccessful() && this.threedsResults.isRebillResultSuccessful();
    }*/
  }

  /**
   * This is for creating a unique 3DS transaction ID.
   */
  generateUniqueId(): string {
    if (this.enabled) {
      return this.tds.generateUniqueId();
    }

    // Not very unique, but it doesn't matter for our use case.
    return '';
  }

  /**
   * Set the callback that should be run when the challenge modal needs to be displayed
   * @param func
   */
  setIframeCallbackHandler(func) {
    this.iframeCallback = func;
  }

  /**
   * Intermediate callback between paay.co library and whatever is using this service.  This is
   * called by paay.co when the customer needs to complete the challenge at the card issuer's site.
   */
  showIframeCallback(): void {
    // If this.setIframeCallbackHandler() was called, execute that callback function.
    if (this.iframeCallback) {
      this.iframeCallback();
    }
  }


  /**
   * Called by the ThreeDS lib's global resolve() AFTER the 3ds process is complete.
   * @param data
   */
  resolvedAuth(data: IAuthenticationStepTwoResponse): void {
    if (this.threedsResults.first3dsResult == null) {
      this.threedsResults.first3dsResult = data;
      this.threedsResults.first3dsResult.paayTransactionId = this.tds.threeDsTransactionId;

      if (!this.threedsResults.isFirstResultSuccessful()) {
        this.promiseReject('Initial 3DS transcation resolved as failure');
        return;
      }
    } else {
      this.threedsResults.rebill3dsResult = data;
      this.threedsResults.rebill3dsResult.paayTransactionId = this.tds.threeDsTransactionId;
    }

    // Wait to resolve our verify() promise until both the rebill 3DS completes.
    if (!this.isRebillEnabled() || (this.isRebillEnabled() && this.threedsResults.hasRebillResult())) {
      this.promiseResolve(this.threedsResults);
    }
  }

  /**
   * Called by the ThreeDS lib's global reject().  This could be after it gives up trying OR
   * when the long polling for completion times out and tries again.  In that case, we want to
   * keep waiting as well.
   * @param v This seems to be a JSON string that is NOT decoded. One example is
   * {
   *    "error":"No result found for transaction as yet. Please subscribe again",
   *    "transactionId":"f694f625-5325-48eb-a45a-5a6857d90e72",
   *    "correlationId":"76c034ae-bac0-4acb-b3ed-6be3f7892adf"
   * }
   */
  rejectedAuth(v: string): void {
    if (v.indexOf('No result found for transaction as yet') === -1) {
      PaayThreedsService.reportError(v);

      if (this.defer) { this.promiseReject(v); }
    }
  }

  /**
   * Performs 3DS verification.  The promise is resolved when the entire process is complete, including
   * any challenge modal that might have been required.
   * @param amount
   */
  verify(amount: string): Promise<ThreedsResults> {
    this.defer = new Promise((resolve, reject) => {
      // We don't run any async operations now, but
      this.promiseResolve = resolve;
      this.promiseReject = reject;
    });

    // If 3ds is disabled, just return empty data.
    if (!this.enabled) {
      this.promiseResolve(this.threedsResults);
      return this.defer;
    }

    // Reset the library's internal flag, so it knows we are starting over.  When using their rebill feature, this must be reset to false
    // otherwise our resolvedAuth() will be confused.
    this.tds.isRebill = false;

    // This will be performed by the library's verify() call.  If it fails in there, it's more difficult
    // to tell what went wrong.  Do it now so we know we'll pass.
    const validationResult = this.tds.validateTransactionDetails();
    if (!validationResult) {
      const errors = this.tds.errors;

      PaayThreedsService.reportError(errors);

      if (this.defer) {
        this.promiseReject(errors);
      }
    }

    // Let's refresh our token.  We do this because it only lasts 5 minutes, and the customer
    // might be taking a long time on this checkout page.  Also, I believe you only get one try per
    // token, and if the user has an issue and corrects it, it might reject the token.  I am 50% sure
    // this is the behavior.

    // There is no communication back from getToken() if the call fails.  Let's set a timeout to handle
    // this case.
    const tokenTimeout = setTimeout(() => {
      if (this.defer) {
        const error = '3ds getToken timeout';
        PaayThreedsService.reportError(error);
        if (this.defer) { this.promiseReject(error); }
      }
    }, 5000);

    // Refresh the 3ds JWT
    this.tds.token = null;
    this.tds.getToken(() => {
      clearTimeout(tokenTimeout);

      // Once we have a new token, submit our request
      const postData = {amount: parseFloat(amount)};
      this.tds.verify(null, null, postData);
    });

    // When the promise is done, destroy the deferred object
    this.defer
      .finally(() => {
          this.defer = null;
      });

    return this.defer;
  }
}
