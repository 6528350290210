import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NoteService} from '../services/note.service';

@Component({
  selector: 'app-note-detail-modal',
  templateUrl: 'note-detail-modal.component.html',
  styleUrls: ['note-detail-modal.component.css'],
})
export class NoteDetailModalComponent implements OnInit {

  public noteId: any;
  public note: any;

  constructor(
    private noteService: NoteService,
    public activeModal: NgbActiveModal,
  ) {
  }


  ngOnInit() {
    this.noteService.get(this.noteId).subscribe(data => {
      this.note = data;
    });
  }

  close() {
    this.activeModal.close();
  }
}
