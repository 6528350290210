import {Component, OnInit} from '@angular/core';
import {OfferClickService} from '../../services/offer-click.service';
import {ActivatedRoute} from '@angular/router';
import {LengthAwarePaginator} from '../../../shared/models/LengthAwarePaginator';

@Component({
  selector: 'app-customer-offer-clicks',
  templateUrl: 'customer-offer-clicks.component.html',
  styleUrls: ['customer-offer-clicks.component.css'],
})
export class CustomerOfferClicksComponent implements OnInit {

  private guid = '';
  public offerclicks: LengthAwarePaginator;

  constructor(
    private offerclickService: OfferClickService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {

    const guid = this.route.snapshot.params['guid'];
    // this.fetchOfferClicks(guid);

    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.guid = params['guid'];
        this.fetchOfferClicks(1);
      },
    );
  }

  fetchOfferClicks(page) {

    this.offerclickService.getByGuid(this.guid, page).subscribe(lengthAwarePaginator => {
      this.offerclicks = lengthAwarePaginator;
    });
  }

  onPageChange(page) {
    this.fetchOfferClicks(page);
  }
}
