import {Component, Input} from '@angular/core';
import {CustomerThreedsInterface} from 'src/app/customer/models/customer-threeds.interface';
import {ThreedsInfoModalComponent} from 'src/app/customer/threeds-info-modal/threeds-info-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-threeds-info',
  templateUrl: './threeds-info.component.html',
})
export class ThreedsInfoComponent {

  @Input() threeds: CustomerThreedsInterface;

  constructor(private modalService: NgbModal) {
  }

  openInfoModal(): void {
    const m = this.modalService.open(ThreedsInfoModalComponent);

    m.componentInstance.threeds = this.threeds;
  }
}
