import {Component, Input} from '@angular/core';
import {TransactionModel} from '../../../../shared/models/transaction.model';
import {UserService} from '../../../../shared/auth/services/user.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-order-number',
  templateUrl: './order-number.component.html',
  styleUrls: ['./order-number.component.scss']
})
export class OrderNumberComponent {

  @Input() transaction: TransactionModel;
  canLinkToSticky$: Observable<boolean>;

  constructor(private userService: UserService) {
    this.canLinkToSticky$ = this.userService.hasAbility('link-to-stickyio');
  }
}
