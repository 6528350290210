
<div class="card rs-card rs-card-blue"
     *ngIf="smsAlerts?.total > 0"
     [ngClass]="{'min-paginated-card-height': smsAlerts?.total >= 10}">
  <div class="card-body">


    <div class="clearfix">
      <h5 class="float-left">{{label}}</h5>
      <p class="text-end">{{smsAlerts?.total}} SMS Alerts</p>
    </div>

    <table class="table table-sm rs-table table-striped table-smsAlerts">
      <thead class="">
      <tr>
        <th><i class="fa-solid fa-triangle-exclamation"></i></th>
        <th>ID</th>
          <th>SMS ID</th>
        <th>Created</th>
        <th>Resolved</th>
        <th>Resolved By</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let alert of smsAlerts?.data">
        <td>
          <span [ngSwitch]="alert.resolved">
            <i *ngSwitchCase="0" class="fa-solid fa-triangle-exclamation font-red"></i>
            <i *ngSwitchCase="1" class="fa-solid fa-circle font-green"></i>
          </span>
        </td>
        <td>{{alert.id}}</td>
        <td>{{alert.sms_id}}</td>
        <td>{{alert.dt_created | dateTimeFormat:'withtime' }}</td>
        <td >{{alert.dt_resolved | dateTimeFormat:'withtime' }}</td>
        <td >{{alert.resolved_user_id}}</td>
        <!-- Actions -->
        <td class="text-center">
          <div class="d-inline-block clickable" *ngIf="!alert.resolved" (click)="resolveAlert(alert.id)" ngbTooltip="Resolve the Alert">
            <i class="fa-solid fa-rotate-left fa-lg"></i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="smsAlerts"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="smsAlerts?.total"
                    [(page)]="smsAlerts.current_page"
                    [maxSize]="5" [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm">
    </ngb-pagination>
    <p>Page: {{smsAlerts?.current_page}}</p>
  </div>
</div>
