<div class="status-bar"
     [ngClass]="{
            'bg-freecredit': customer?.afid == 420227 && customer?.status != 'cancelled',
            'bg-manual-verify': customer?.status == 'manual',
            'bg-thin-file': customer?.status == 'thin_file',
            'bg-return-reversal': customer?.status == 'returnreverse',
            'bg-chargeback': customer?.status == 'chargeback',
            'bg-prospect': customer?.status == 'prospect',
            'bg-cancelled': customer?.status == 'cancelled',
            'bg-escalated': customer?.escalated}">

  <div class="status-indicator" *ngIf="shouldShowStatus()"
       [ngClass]="{
            'status-indicator-manual-verify': customer?.status == 'manual',
            'status-indicator-thin-file': customer?.status == 'thin_file',
            'status-indicator-return-reversal': customer?.status == 'returnreverse',
            'status-indicator-chargeback': customer?.status == 'chargeback',
            'status-indicator-prospect': customer?.status == 'prospect',
            'status-indicator-cancelled': customer?.status == 'cancelled'}">

            <span [ngSwitch]="customer?.status" class="text-center">

                <span *ngSwitchCase="'cancelled'">
                    <i class="fa-solid fa-ban fa-lg font-cancelled status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Cancelled</span>
                </span>

                <span *ngSwitchCase="'returnreverse'">
                    <i class="fa-solid fa-retweet fa-lg font-return-reversal status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Return Reversal</span>
                </span>

                <span *ngSwitchCase="'chargeback'">
                    <i class="fa-regular fa-credit-card fa-lg font-chargeback status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Chargeback</span>
                </span>

                <span *ngSwitchCase="'prospect'">
                    <i class="fa-solid fa-user fa-lg font-prospect status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Prospect</span>
                </span>

                <span *ngSwitchCase="'manual'">
                    <i class="fa-solid fa-magnifying-glass fa-lg font-manual-verify status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Manual Verify</span>
                </span>

                <span *ngSwitchCase="'thin_file'">
                    <i class="fa-solid fa-magnifying-glass fa-lg font-thin-file status-indicator-icon" aria-hidden="true"></i>
                    <span class="vertical-text">Thin File</span>
                </span>
            </span>

  </div>

  <div class="status-indicator status-indicator-extended-trial" *ngIf="customer?.extended_trial && customer?.status != 'cancelled'">
      <i class="fa-solid fa-up-right-from-square fa-lg font-extended-trial status-indicator-icon" aria-hidden="true"></i>
      <span class="vertical-text">Extended Trial</span>
  </div>

  <div class="status-indicator status-indicator-freecredit" *ngIf="customer?.afid == 420227 && customer?.status != 'cancelled'">
      <i class="fa-solid fa-dollar-sign fa-lg font-freecredit status-indicator-icon" aria-hidden="true"></i>
      <span class="vertical-text">Freecredit</span>
  </div>

  <div class="status-indicator status-indicator-deferred-auth" *ngIf="isDeferredAuth()">
      <span class="vertical-text">Deferred auth incomplete</span>
  </div>

  <div class="status-indicator status-indicator-escalated" *ngIf="customer?.escalated">
      <i class="fa-solid fa-triangle-exclamation fa-lg font-escalated status-indicator-icon" aria-hidden="true"></i>
      <span class="vertical-text">Escalated</span>
  </div>

</div>
