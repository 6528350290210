import {CustomerLiteInterface} from './customer-lite.interface';

export class CustomerLite implements CustomerLiteInterface {

    public abodenumber = '';
    public building_name = '';
    public city = '';
    public country = '';
    public dt = '';
    public email = '';
    public fname = '';
    public guid = '';
    public lname = '';
    public phone = '';
    public proc_id = 0;
    public product_id = '';
    public status = '';
    public straddr = '';
    public street = '';
    public zip = '';
    public trial_end_date = '';

    public product_name = '';
    public recurring_interval = '';
    public recurring_price = '';

    public deferred_auth = 0;
    public deferred_auth_complete = 0;

    constructor() {}

    fromJSON(obj: CustomerLiteInterface) {
        const myObjectKeys = Object.keys(this);
        myObjectKeys.forEach((key) => {
            this[key] = obj[key];
        });
    }
}
