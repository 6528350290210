import {Component} from '@angular/core';
import {InquiryTypeService} from '../shared/services/InquiryTypeService';
import {InquiryTypeSet} from '../shared/models/inquiry-type-set.model';
import {PageTitleService} from '../shared/page/page-title.service';
import {CommonModule} from '@angular/common';
import {SmsAlertsComponent} from 'src/app/dashboard/sms/sms-alerts.component';
import {AlertsComponent} from 'src/app/dashboard/alerts/alerts.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SmsAlertsComponent,
    AlertsComponent,
  ],
})
export class DashboardComponent {

  public inquiryTypes: InquiryTypeSet = null;

  constructor(
    private inquiryTypeService: InquiryTypeService,
    private pageTitleService: PageTitleService,
  ) {
    this.pageTitleService.setTitle('Dashboard');
    this.inquiryTypeService.get().subscribe(inquiryTypeSet => {
      this.inquiryTypes = inquiryTypeSet;
    });
  }

}
