<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Dine Card Subscription</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">

  <div class="container-fluid">
    <div class="row">
      <div class="col-6 label">Status</div>
      <div class="col-6" [ngbTooltip]="statusTip" placement="bottom">
        {{ subscription.status.replace('_', ' ') | capitalize:true }} <i class="fa-regular fa-circle-question"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-6 label">Date Added</div>
      <div class="col-6">{{ subscription.created_at | dateTimeFormat:'withtime'  }}</div>
    </div>
    <div class="row">
      <div class="col-6 label">Paid Type</div>
      <div class="col-6">{{ subscription.paid_type }}</div>
    </div>
    <div class="row">
      <div class="col-6 label">Charge Attempts</div>
      <div class="col-6">{{ subscription.charge_attempts }}</div>
    </div>
    <div class="row">
      <div class="col-6 label">Subscription End</div>
      <div class="col-6">{{ subscription.subscription_end_date | dateTimeFormat }}</div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <table class="table table-sm rs-table table-striped">
          <thead>
          <tr>
            <th>Date</th>
            <th>Body</th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let o of subscription.event_logs">
            <td>{{o.created_at | dateTimeFormat:'withtime' }}</td>
            <td>{{o.body}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-template #statusTip>
    <div style="text-align: left">
      <strong>Pending Active:</strong> Card waiting to be ordered<br>
      <strong>Active:</strong> Card ordered<br>
      <strong>Abandoned:</strong> Cancelled before card was ordered<br>
      <strong>Pending Cancel:</strong> Card waiting cancelled at provider<br>
      <strong>Cancelled:</strong> Card inactive
    </div>
  </ng-template>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">Close</button>
</div>
