<div class="modal-header">
	<h5 class="modal-title" id="exampleModalLabel">Call Recordings</h5>
	<button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">

	<!-- Errors
	<div *ngIf="errors">
		<div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
			{{errors[key]}}
		</div>
	</div>-->

	<table class="margin-top-15 table table-sm rs-table table-striped">
		<thead class="">
		<tr>
			<th>Agent</th>
			<th>Date</th>
			<th class="text-center">info</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let c of recordings?.data">
			<td>{{c.agent}}</td>
			<td>{{c.dt | dateTimeFormat:'withtime'}}</td>
			<td class="text-center">
				<!--<i class="fas fa-assistive-listening-systems clickable" (click)="listen(c)"></i>-->
				<a href="/api/five9/stream/{{c.id}}" [download]="c.filename">
					<i class="fa-solid fa-download clickable" aria-hidden="true" ngbTooltip="Download Audio File" container="body" ></i></a>
			</td>
		</tr>
		</tbody>
	</table>
</div>

<div class="modal-footer d-flex justify-content-between">
	<ngb-pagination *ngIf="recordings"
					(pageChange)="onPageChange($event)"
					[collectionSize]="recordings.total"
					[pageSize]="recordings?.per_page"
					[(page)]="recordings.current_page"
					[maxSize]="5"
					[rotate]="true"
					[ellipses]="false"
					[boundaryLinks]="true" size="sm"></ngb-pagination>
  <p>Page: {{recordings?.current_page}}</p>
</div>
