<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Dark Web Info</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <table class="table table-sm rs-table table-striped">
    <thead>
    <tr>
      <th>Email</th>
      <th>Added</th>
      <th>Verified</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let o of darkWebInfo.emails">
      <td>{{o.criterion}}</td>
      <td>{{o.created_at | dateTimeFormat:'withtime' }}</td>
      <td>{{o.is_verified ? 'Yes' : 'No'}}</td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">Close</button>
</div>
