import {IAuthenticationStepTwoResponse} from "./IAuthenticationStepTwoResponse";

/**
 * Returned by PaayThreedsService::verify() promise
 */
export class ThreedsResults {
  first3dsResult: IAuthenticationStepTwoResponse | null = null;
  rebill3dsResult: IAuthenticationStepTwoResponse | null = null;

  hasRebillResult(): boolean {
    return this.rebill3dsResult != null;
  }

  isFirstResultSuccessful(): boolean {
    if (this.first3dsResult == null) {
      return false;
    }

    return !(this.first3dsResult.eci == '00' || this.first3dsResult.eci == '07');
  }

  isRebillResultSuccessful(): boolean {
    if (this.rebill3dsResult == null) {
      return false;
    }

    return !(this.rebill3dsResult.eci == '00' || this.rebill3dsResult.eci == '07');
  }
}
