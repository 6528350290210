import {Component} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {CustomerService} from '../services/customer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerEditModalComponent} from '../customer-edit-modal/customer-edit-modal.component';
import {ChangeAfidModalComponent} from '../change-afid-modal/change-afid-modal.component';
import {AddNoteModalComponent} from '../../notes/add-note-modal/add-note-modal.component';
import {Note} from '../../notes/models/note.model';
import {Customer} from '../models/customer.model';
import {UserService} from '../../shared/auth/services/user.service';
import {DownsellModalComponent} from '../pricepoint/downsell-modal/downsell-modal.component';
import {PricepointService} from '../pricepoint/services/pricepoint.service';
import {PasswordResetModalComponent} from '../account/password-reset-modal/password-reset-modal.component';
import {CancelAccountModalComponent} from '../account/cancel-account-modal/cancel-account-modal.component';
import {AccountService} from '../account/services/account.service';
import {ExtendTrialModalComponent} from '../extend-trial-modal/extend-trial-modal.component';
import {Observable} from 'rxjs';
import {RefundService} from '../transactions/refund.service';
import {SendSmsModalComponent} from '../account/send-sms-modal/send-sms-modal.component';
import {ConfirmationModalComponent} from '../../shared/modal/confirmation-modal/confirmation-modal.component';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../shared/error/error.service';
import {CallRecordingsComponent} from './call-recordings/call-recordings.component';
import {ReactivateAccountModalComponent} from '../account/reactivate-account-modal/reactivate-account-modal.component';
import {PageTitleService} from '../../shared/page/page-title.service';
import {AddressHistoryComponent} from '../address-history/address-history.component';
import {DeferredAuthModalComponent} from '../../card-services/components/deferred-auth-modal/deferred-auth-modal.component';
import {AlertService} from "@ratespecial/core";
import {SendEmailModalComponent} from '../account/send-email-modal/send-email-modal.component';
import {OneTimePasswordModalComponent} from 'src/app/customer/one-time-password-modal/one-time-password-modal.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent {

    public guid: string;
    public customer: Customer;
    public user: any = {};

    /**
     * State flag to prevent navigation away from detail page without adding a note
     */
    public hasAddedOrBypassedNote: boolean = false;
    public addNoteBlockedUrl = '';

  // Add reference to Object.keys function to template
    public objectKeys = Object.keys;

    constructor(
        private customerService: CustomerService,
        route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private alertService: AlertService,
        public userService: UserService,
        private pricepointService: PricepointService,
        private acountService: AccountService,
        private refundService: RefundService,
        private errorService: ErrorService,
        private pageTitleService: PageTitleService
    ) {
      this.userService.get()
        .pipe(takeUntilDestroyed())
        .subscribe(user => {
            this.user = user;
        });

      // Watch for GUID changes
        route.params.pipe(takeUntilDestroyed()).subscribe(
            params => {
                this.guid = params['guid'];
                this.addNoteBlockedUrl = '';
                this.hasAddedOrBypassedNote = false;

                this.refreshCustomer(true);
            }
        );

        // Subscribe to updates that effect displayed customer data
        this.initSubscriptions();
    }

    /**
     * Watch for events so we can update display
     */
    private initSubscriptions() {

      this.customerService
        .customerUpdateEvent
        .pipe(takeUntilDestroyed())
        .subscribe(() => this.refreshCustomer(true));

      this.pricepointService
        .onUpdatePricepoint
        .pipe(takeUntilDestroyed())
        .subscribe(() => this.refreshCustomer(true));

      this.acountService
        .onAccountUpdateEvent
        .pipe(takeUntilDestroyed())
        .subscribe(() => this.refreshCustomer(true));

      this.refundService
        .onRefundTransaction
        .pipe(takeUntilDestroyed())
        .subscribe(() => this.refreshCustomer(true));

      // Subscribe to route changes to remember where user wanted to go after addNoteModal fires
      this.router.events.pipe(takeUntilDestroyed()).subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.addNoteBlockedUrl = val.url;
        }
      });

      /* Another method to trigger the add note modal when attempting to navigate away from the page.  But
          this method still allows the route to change behind the grayed out modal backdrop.
      */
      // this.location.subscribe((back: PopStateEvent) => {
      //     console.log({back: back});
      //     //this.addNote();
      // });
    }


    /**
     * The "AddNoteGuard" prevents navigation away from detail view calling this function to pop the AddNoteModal
     * In order to do this, we have to record where the user was trying to go, so we can go there after the modal
     * is closed.
     */
    public canDeactivate(): Observable<boolean> | boolean {

        this.hasAddedOrBypassedNote = true;

        return this.hasAddedOrBypassedNote;
    }


    /**
     * Show Edit Customer Modal
     */
    public editCustomer() {
        const modalRef = this.modalService.open(CustomerEditModalComponent);
        // const modalRef = this.modalService.open(CustomerEditModalComponent);
        if (modalRef) {
            modalRef.componentInstance.loadFormModel(this.customer);
        }
    }

  /**
   * Show Change AFID Modal
   */
  public changeAFID() {
    const modalRef = this.modalService.open(ChangeAfidModalComponent);
    if (modalRef) {
      modalRef.componentInstance.loadCustomer(this.customer);
    }
  }

    /**
     * Show Add Note Modal
     */
    public addNote() {
        const modalRef = this.modalService.open(AddNoteModalComponent);
        // const modalRef = this.modalService.open(AddNoteModalComponent);

        if (modalRef) {
            modalRef.componentInstance.note = new Note(
                this.customer.guid,
                this.customer.status,
                this.customer.fname,
                this.customer.lname,
            );
            modalRef.componentInstance.note.customer_type = 'customer';
            modalRef.componentInstance.user = this.user;

            // On close of add note modal, allow navigation away from page
            modalRef.result.then(() => {
                this.hasAddedOrBypassedNote = true;
            });
        }
    }

    /**
     * Show Downsell (Pricepoint Update) Modal
     */
    public downsell() {
        const modalRef = this.modalService.open(DownsellModalComponent);
        // const modalRef = this.modalService.open(DownsellModalComponent);

        if (modalRef) {
            modalRef.componentInstance.form.guid = this.customer.guid;
            modalRef.componentInstance.recurring_price = this.customer.recurring_price;
        }
    }

    /**
     *
     */
    public extendTrial() {
        const modalRef = this.modalService.open(ExtendTrialModalComponent);
        // const modalRef = this.modalService.open(ExtendTrialModalComponent);

        if (modalRef) {
            modalRef.componentInstance.customer = this.customer.getCustomerLite();
            modalRef.componentInstance.customer.trial_end_date = this.customer.trial_end_date;
        }
    }

    /**
     * Show Reset Password Modal
     */
    public resetPassword() {
      const modalRef = this.modalService.open(PasswordResetModalComponent);

      modalRef.componentInstance.customer = this.customer;
    }

    /**
     * Show Cancel Account Modal
     */
    public cancelAccount() {
        const modalRef = this.modalService.open(CancelAccountModalComponent);
        // const modalRef = this.modalService.open(CancelAccountModalComponent);

        if (modalRef) {
            modalRef.componentInstance.customer = this.customer.getCustomerLite();
        }
    }

    /**
     * Show Reactivate Account Modal
     */
    public reactivateAccount() {
        const modalRef = this.modalService.open(ReactivateAccountModalComponent);

        if (modalRef) {
            modalRef.componentInstance.customer = this.customer.getCustomerLite();
        }
    }

    /**
     * Show Send SMS Modal
     */
    public sendSms() {
        const modalRef = this.modalService.open(SendSmsModalComponent);

        if (modalRef) {
            modalRef.componentInstance.customer = this.customer.getCustomerLite();
        }
    }

    public sendEmail() {
      const modalRef = this.modalService.open(SendEmailModalComponent);

      if (modalRef) {
        modalRef.componentInstance.customer = this.customer.getCustomerLite();
      }
    }

    /**
     * Open Call Recording Modal
     */
    public openCallRecordingModal() {
        const modalRef = this.modalService.open(CallRecordingsComponent);

        if (modalRef) {
            modalRef.componentInstance.customer = this.customer.getCustomerLite();
        }
    }

    /**
     * Show Delete Customer Confirmation Modal
     */
    public delete() {
        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.title = 'Delete Customer';
        modalRef.componentInstance.message = 'Are you sure you want to clear all PII data for this customer?';

        modalRef.result.then((shouldDelete) => {
            if (shouldDelete) {
                this.deleteCustomer();
            }
        });
    }

  private deleteCustomer() {
    this.customerService
      .delete(this.customer.guid)
      .subscribe({
        next:
          () => {
            this.alertService.success('Customer deleted.');
            this.customer.status = 'deleted';
          },
        error: (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        },
      });
  }

  private refreshCustomer(isForce = false) {
    this.customerService
      .get(this.guid, isForce)
      .subscribe({
        next: (customer: Customer) => {
          this.customer = customer;

          const customerHasName = !!customer.fname || !!customer.lname;

          if (customerHasName) {
            this.pageTitleService.setTitle(customer.fname + ' ' + customer.lname);
          }

          this.hasAddedOrBypassedNote = false;
        }, error: (e: HttpErrorResponse) => {
          if (e.status == 422) {
            this.alertService.error('Invalid GUID');
            this.hasAddedOrBypassedNote = true;
            this.router.navigateByUrl('/');
          }
        },
      });
  }

    isNotCancelled(): boolean {
      // Customer cannot already be in a cancelled or chargeback state
      return ['cancelled', 'chargeback'].indexOf(this.customer.status) === -1;
    }

    openAddressHistory(): void {
      const modalRef = this.modalService.open(AddressHistoryComponent);
      if (modalRef) {
        modalRef.componentInstance.addressHistory = this.customer.address_history;
      }
    }

    addCreditCard() {
      this.router.navigateByUrl('/card-services/finalize-account/' + this.customer.guid);
    }

    openCreditCardModal() {
      const modalRef = this.modalService.open(DeferredAuthModalComponent, { size: 'lg'});

      if (modalRef) {
        modalRef.componentInstance.guid = this.customer.guid;
        modalRef.componentInstance.customer = this.customer.getCustomerLite();
        modalRef.componentInstance.modalInit();

        modalRef
          .result
          .then(
            (isSuccess => {
              if (isSuccess) {
                this.refreshCustomer(true);
              }
            })
          );
      }
    }

    getDeferredAuthLabel(): string {
      let label = 'No';

      if (this.customer.deferred_auth === 1) {
        label = 'Yes, ';

        if (this.customer.deferred_auth_complete === 1) {
          label += 'Complete';
        } else {
          label += 'Incomplete';
        }
      }

      return label;
    }

  refreshReport(): void {
    if (confirm('This will pull a new copy of the customers report.')) {
      this.customerService.refreshReport(this.customer.guid).subscribe({
        next: () => {
          this.alertService.success('Report refreshed');
        },
        error: (e: HttpErrorResponse) => {
          this.alertService.error(e.error.message);
        },
      });
    }
  }

  sendOnetimePassword(): void {
    const modal = this.modalService.open(OneTimePasswordModalComponent);
    modal.componentInstance.customer = this.customer;
  }

}
