import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {map} from "rxjs/operators";


/**
 * Prevent back button to the login or auth processing pages when user is already authenticated
 *
 * To use:
 *    Add guard to your login related routes
 *    {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
 *    {path: 'google', component: GoogleComponent, canActivate: [LoginGuard]}
 */
export const LoginGuard: CanActivateFn = (
    // route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.checkUser().pipe(
        map(userLoggedIn => {
            if (userLoggedIn) {
                router.navigateByUrl('/dashboard');
                return false;
            }
            return true;
        })
    );
};
