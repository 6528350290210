import {Component, OnInit} from '@angular/core';
import {CustomerLite} from '../../models/customer-lite.model';
import {AccountService} from '../services/account.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from '../../../shared/error/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-reactivate-account-modal',
  templateUrl: './reactivate-account-modal.component.html',
  styleUrls: ['./reactivate-account-modal.component.css']
})
export class ReactivateAccountModalComponent implements OnInit {


  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  /**
   * Reason select options
   */
  public reasons: Array<any> = [];

  public reactivateAccountRequest = {
    guid: null,
    note: ''
  };

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;


  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    public errorService: ErrorService
  ) {}


  ngOnInit() {
    this.errorService.clearErrors();
  }

  reactivateAccount() {
    this.errorService.clearErrors();
    this.reactivateAccountRequest.guid = this.customer.guid;

    this.accountService.reactivateAccount(this.reactivateAccountRequest)
      .subscribe(
        (data) => {
          this.alertService.success('Account has been reactivated.');
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      );
  }

  close() {
    this.activeModal.close();
  }
}
