<ng-template #deferredAuthComplete>
  <h1>Account Complete</h1>
  <p class="pt-3">The customer's membership has been finalized. They may update their billing information at any time by navigating to the Member Profile from the main menu.</p>
</ng-template>


<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Add Credit Card</h5>
  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="activeModal.close(isSuccess)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

    <div class="col-sm-10 offset-sm-1">

      <div *ngIf="!isDeferredAuthComplete(); else deferredAuthComplete">

        <dl class="row">
          <dt class="col-sm-3">Customer:</dt>
          <dd class="col-sm-9">{{customer?.fname}} {{customer.lname}}</dd>

          <dt class="col-sm-3">Monthly Price:</dt>
          <dd class="col-sm-9">&pound;{{customer?.recurring_price}}</dd>

          <dt class="col-sm-3" *ngIf="isPriceDiscounted === true">Discount:</dt>
          <dd class="col-sm-9" *ngIf="isPriceDiscounted === true">{{discountPercent}}% off our regular price of &pound;34.95</dd>
        </dl>

        <!-- 3DS Specific Errors -->
        <div class="alert alert-danger" role="alert" *ngFor="let err of errors">
          {{err}}
        </div>

        <!-- Unknown Error -->
        <div class="alert alert-danger" role="alert" *ngIf="hasUnknownError">
          An error occurred while processing the card.
        </div>

        <!-- Checkout Authcard Result Messages -->
        <div *ngIf="deferredAuthCardResponse" [ngSwitch]="deferredAuthCardResponse.result">

          <div class="alert alert-primary" role="alert" *ngSwitchCase="processorResponse.SUCCESS">
            The customer's membership has been finalized. They may update their billing information at any time by navigating to the Member Profile from the main menu.
          </div>

          <div *ngSwitchDefault>
            <div class="alert alert-danger" role="alert" *ngIf="deferredAuthCardResponse.cardWasAttempted">
              Backend error: {{deferredAuthCardResponse.backendError}}
            </div>
          </div>
        </div>
      </div>

      <div class="form" [hidden]="isDeferredAuthComplete() || (isSubmitted && isPostSuccess())">

        <form id="mainForm" (ngSubmit)="save()" [formGroup]="cardForm">

          <!-- 3DS fields -->
          <input type="hidden" name="x_transaction_id" [value]="tdsFields.id" data-threeds="id"/>

          <div class="mb-3">
            <div class="form-group col-md-12">
              <div *ngIf="!isProduction()"><a href="https://docs.3dsintegrator.com/docs/test-cards" target="_blank">TEST CARD</a>:
                4012000033330026
              </div>

              <label for="ccn" class="form-label">Credit Card</label>
              <input type="text" class="form-control" formControlName="ccn" id="ccn" data-threeds="pan" autocomplete="false"
                     [ngClass]="{ 'is-invalid': errors.hasOwnProperty('ccn') || (formControls.cvv.touched && formControls.ccn.errors) }">
              <div class="invalid-feedback" *ngIf="formControls.ccn.touched && formControls.ccn.hasError('required')">Required</div>
              <div class="invalid-feedback" *ngIf="formControls.ccn.touched && formControls.ccn.hasError('pattern')">Invalid Card</div>
              <div class="form-error" *ngIf="errors.hasOwnProperty('ccn')">{{errors?.ccn}}</div>
            </div>
          </div>

          <div class="mb-3">
            <div class="mb-3 col-md-6">
              <label for="cvv" class="form-label">Security Code</label>
              <input type="text" class="form-control" formControlName="cvv" id="cvv"
                     [ngClass]="{ 'is-invalid':errors.hasOwnProperty('ccn') || ((isSubmitted || formControls.cvv.touched) && formControls.cvv.errors) }">
              <div class="invalid-feedback" *ngIf="(isSubmitted || formControls.cvv.touched) && formControls.cvv.hasError('required')">
                Required
              </div>
              <div class="invalid-feedback" *ngIf="(isSubmitted || formControls.cvv.touched) && formControls.cvv.hasError('pattern')">
                Invalid Security Code
              </div>
              <div class="form-error" *ngIf="errors.hasOwnProperty('cvv')">{{errors?.cvv}}</div>
            </div>

            <div class="mb-1 col-md-3">
              <label for="expm" class="form-label">Expiration</label>
              <select id="expm" class="form-control" formControlName="expm" data-threeds="month"
                      [ngClass]="{ 'is-invalid': errors.hasOwnProperty('expm') || ((isSubmitted || formControls.expm.touched) && formControls.expm.errors)}">
                <option [value]="null">Month</option>
                <option *ngFor="let month of months" [value]="month">
                  {{month}}
                </option>
              </select>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('expm')">{{errors?.expm}}</div>
              <div class="invalid-feedback" *ngIf="formControls.expm.hasError('required')">Required</div>
              <div class="invalid-feedback" *ngIf=" formControls.expm.hasError('pattern')">Invalid Month</div>
            </div>

            <div class="col-md-3">
              <!--<label for="expy" class="form-label">&nbsp;</label>-->
              <select class="form-control" formControlName="expy" id="expy" data-threeds="year"
                      [ngClass]="{ 'is-invalid': errors.hasOwnProperty('expy') || ((isSubmitted || formControls.expy.touched) && formControls.expy.errors)}">
                <option [value]="null">Year</option>
                <option *ngFor="let year of years" [value]="year.toString().substr(2)">
                  {{year}}
                </option>
              </select>
              <div class="invalid-feedback"
                   *ngIf="(formControls.expy && formControls.expy.touched) && formControls.expy.hasError('required')">Required
              </div>
              <div class="invalid-feedback"
                   *ngIf="(formControls.expy && formControls.expy.touched) && formControls.expy.hasError('pattern')">Invalid Year
              </div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('expy')">{{errors?.expy}}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="offset-9 col-sm-3">
              <button type="submit" class="btn btn-block btn-primary" [disabled]="(cardForm.dirty && cardForm.valid && isSubmitted && isSuccess) || cardForm.invalid">Process</button>
            </div>
          </div>
        </form>

      </div>
    </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close(isSuccess)">Close</button>
</div>

<div id="modal-bg-3ds" [hidden]="!isShowModal">
  <div class="inside">
    <div class="close" (click)="closeModal()" role="button" tabindex="5">
      &times;
    </div>
    <iframe id="3ds-iframe"></iframe>
  </div>
</div>
