<div class="container" >
  <!--<hr class="separator">-->
  <div class="d-flex justify-content-between">
    <h3 class="p-2">Prospects</h3>

    <!-- Toolbar -->
    <div class="p-10">
      <div class="d-flex flex-row page-actions">
        <div class="p-2">
          <div class="pa-icon cursor-pointer" (click)="addProspect()" ngbTooltip="Add New Prospect">
            <i class="fa-solid fa-user fa-2x font-blue"></i>
            <i class="fa-solid fa-circle-plus fa-lg"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="card card-body rs-card rs-card-blue">
    <div class="row">
      <div class="col-sm-12">

        <form class="row row-cols-lg-auto align-items-center">
          <div class="col-12 me-auto">{{prospectsPage?.total}} Records</div>

          <div class="col-6 form-check form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input"
                     type="radio"
                     name="filterby"
                     id="filterby1"
                     value=""
                     checked
                     (change)="filter('all')"
                     [(ngModel)]="searchModel.is_contacted">
              All
            </label>
          </div>
          <div class="col-6 form-check form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input"
                     type="radio"
                     name="filterby"
                     id="filterby2"
                     value="0"
                     (change)="filter('uncontacted')"
                     [(ngModel)]="searchModel.is_contacted">
              Uncontacted
            </label>
          </div>

          <div class="col-12">
            <div class="input-group">
              <input type="text"
                     class="form-control"
                     id="searchtext"
                     name="searchText"
                     placeholder="Search Text"
                     [(ngModel)]="searchModel.searchText">

              <button type="submit" class="btn btn-primary" (click)="search()"><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
          </div>

          <div class="col-12">
            <button type="button" class="btn btn-secondary" (click)="reset()">Reset</button>
          </div>

        </form>

        <table class="table table-sm  table-search-results">
          <thead class="">
          <tr>
            <th class="text-center">Status</th>
            <th>fname</th>
            <th>lname</th>
            <th>straddr</th>
            <th class="d-md-none d-lg-table-cell">city</th>
            <th>zip</th>
            <th>email</th>
            <th><i class="fa-solid fa-phone" ngbTooltip="Is Contacted?" tooltip-append-to-body="true"></i></th>
            <!--<th>last_four</th>-->
            <th class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngIf="prospectsPage?.data.length == 0">
            <td collspan="9">No results found</td>
          </tr>

          <tr *ngFor="let customer of prospectsPage?.data">

            <td [ngSwitch]="customer.status" class="text-center">
              <i class="fa-solid fa-ban font-cancelled" aria-hidden="true" *ngSwitchCase="'cancelled'"></i>
              <i class="fa-solid fa-retweet font-return-reversal" aria-hidden="true" *ngSwitchCase="'returnreverse'"></i>
              <i class="fa-regular fa-credit-card font-chargeback" aria-hidden="true" *ngSwitchCase="'chargeback'"></i>
              <i class="fa-solid fa-user font-prospect" aria-hidden="true" *ngSwitchCase="'prospect'"></i>
              <i class="fa-solid fa-magnifying-glass font-manual-verify" aria-hidden="true" *ngSwitchCase="'manual'"></i>

              <i class="fa-solid fa-up-right-from-square font-extended-trial" aria-hidden="true" *ngIf="customer.extended_trial"></i>

              <i class="fa-solid fa-dollar-sign font-freecredit" aria-hidden="true" *ngIf="customer.afid == 420227"></i>
              <i class="fa-solid fa-triangle-exclamation font-escalated" aria-hidden="true" *ngIf="customer.escalated"></i>
            </td>
            <td>{{ customer.fname}}</td>
            <td>{{ customer.lname}}</td>
            <td>{{ customer.straddr}}</td>
            <td class="d-md-none d-lg-table-cell">{{ customer.city}}</td>
            <td>{{ customer.zip}}</td>
            <td>{{ customer.email}}</td>
            <td [ngSwitch]="customer.is_contacted">
              <i *ngSwitchCase="true" class="fa-solid fa-check"></i>
              <span *ngSwitchCase="false">&nbsp;</span>
            </td>
            <!--<td>{{ customer.last_four}}</td>-->
            <td class="text-center"><i class="fa-solid fa-eye clickable" [routerLink]="['/prospect/detail', customer.id, customer.guid]"></i>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>

    <div class="card-footer d-flex justify-content-between">
      <ngb-pagination *ngIf="prospectsPage"
                      (pageChange)="onPageChange($event)"
                      [collectionSize]="prospectsPage?.total"
                      [pageSize]="prospectsPage?.per_page"
                      [(page)]="prospectsPage.current_page"
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      size="sm">
      </ngb-pagination>
      <p>Page: {{prospectsPage?.current_page}}</p>
    </div>
  </div>

</div>
