<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">3DS Info</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <table class="table table-striped">
    <tr>
      <th>3DS Success</th>
      <td>{{threeds.success ? 'Yes' : 'No'}}</td>
    </tr>
    <tr>
      <th>Was challenge requested</th>
      <td>{{threeds.challenge_requested ? 'Yes' : 'No'}}</td>
    </tr>
    <tr>
      <th>Was challenge completed</th>
      <td>{{threeds.challenge_completed ? 'Yes' : 'No'}}</td>
    </tr>
    <tr>
      <th>Transaction date</th>
      <td>{{threeds.transaction_date | dateTimeFormat:'withtime' }}</td>
    </tr>
    <tr>
      <th>Evidence</th>
      <td>
        <dl>
          <ng-container *ngFor="let e of objectKeys(threeds.evidence)">
            <dt>{{ e }}</dt>
            <dd>{{ threeds.evidence[e]}}</dd>
          </ng-container>
        </dl>
      </td>
    </tr>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">Close</button>
</div>
