<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Address History</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">

  <p>If the customer's address has been updated either by them in the portal or by us through the CRM, this maintains
    a record of past values.</p>

  <div class="row mt-2">
    <div class="col-12">
      <table class="table table-sm rs-table table-striped">
        <thead>
        <tr>
          <th>Date</th>
          <th>Address</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let a of addressHistory">
          <td>{{a.created_at | dateTimeFormat}}</td>
          <td>
            {{a.abodenumber}} {{a.building_name}}<br *ngIf="a.abodenumber > 0 || a.building_name.length > 0">
            {{a.straddr}} {{a.street}}<br *ngIf="a.straddr.length > 0 || a.street.length > 0">
            {{a.city}}<br>
            {{a.zip}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()">Close</button>
</div>

