<div class="container">
  <div class="row justify-content-center">

    <div class="card rs-card rs-card-blue col-lg-6 col-md-8">
      <div class="card-body">

        @if (currentState === 'prompt') {
          <p>To view the CRM in an iframe, you must approve browser permission to allow cookies.</p>

          <div class="justify-content-center d-flex">
          <button (click)="requestApproval()" class="btn btn-primary">Request Cookie Approval</button>
            </div>
        } @else if (currentState === 'denied') {
          <p>Your browser has declined the permission.  You will need to clear the setting and try again.</p>

          <img ngSrc="/assets/permission-reset.png" alt="Permission Reset" height="479" width="444" class="mt-3">
        } @else {
          <p>Permission granted. Redirecting from whence you came...</p>
        }

      </div>
    </div>
  </div>
</div>



