import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {Note} from '../models/note.model';


@Injectable({providedIn: 'root'})
export class NoteService {

    /**
     * Resource URLS
     * @type {string}
     */
    private resourceUrl = 'api/note';
    private searchUrl = 'api/notes/';

    /**
     * Notification to any subscribers of note save events
     * @type {EventEmitter<boolean>}
     */
    @Output() onNoteSaveEvent = new EventEmitter<any>();


    constructor(private http: HttpClient) {
    }


    /**
     * Retrieve a specific note by its ID
     * @param id
     * @returns {Observable<Object>}
     */
    get(id) {
        return this.http.get(this.resourceUrl + '/' + id);
    }


    /**
     * Create a new Note
     * @param {Note} note
     * @param isResolveNote     boolean denoting if note is to resolve an alert
     * @returns {Observable<Object>}
     */
    create(note: Note, isResolveNote?) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        let resolve = '';
        if (isResolveNote) {
            resolve = '/resolve';
        }

        return this.http.post(this.resourceUrl + resolve, note, {headers});
    }


    /**
     * Retrieve all notes for a specific user by GUID (paginated)
     * @param guid
     * @param page
     * @returns {Observable<Object>}
     */
    getByGuid(guid, page) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get<LengthAwarePaginator>(this.searchUrl + 'guid/' + guid, {params: params});
    }


    /**
     * Notify any subscribers
     * @param event
     */
    notify(event) {
        this.onNoteSaveEvent.emit(event);
    }
}
