import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '../../shared/auth/services/auth.service';
import {UserService} from '../../shared/auth/services/user.service';
import {APP_CONFIG} from '../../app.config';
import {IAppConfig} from '../../app-config.interface';
import {Observable} from "rxjs";
import {User} from "../../shared/models/user.model";
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import {NgbCollapse, NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgbDropdownItem,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbCollapse,
  ],
})
export class NavbarComponent implements OnInit {

  user$: Observable<User>;
  isNavbarCollapsed = true;

  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
    public userService: UserService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.userService.get();
  }

  logout(): void {
    this.authService.logout();
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
