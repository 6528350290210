<div class="card rs-card rs-card-blue">
  <div class="card-body min-paginated-card-height">

    <div class="clearfix">
      <p class="text-end">{{clickLog?.data.length}} Events</p>
    </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped">
      <thead class="">
      <tr>
        <th>Event Date</th>
        <th>Page</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let cl of clickLog?.data | slice:pagination.start:pagination.end">
        <td>{{cl.created_at | dateTimeFormat:'withtime'}}</td>
        <td>{{cl.page}}</td>
        <td>{{cl.action}}</td>
      </tr>
      </tbody>
    </table>
    <div class="text-center mt-4" *ngIf="clickLog?.data.length == 0">There are no click events.</div>
  </div>

  <div class="card-footer">
    <div class="d-flex justify-content-between">
      <ngb-pagination *ngIf="clickLog"
                      (pageChange)="onPageChange($event)"
                      [collectionSize]="clickLog?.data.length"
                      [(page)]="pagination.current_page"
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                      size="sm">
      </ngb-pagination>
      <p>Page: {{pagination?.current_page}}</p>
    </div>
  </div>
</div>

