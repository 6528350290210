import {filter, Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import UpdateUser = SessionActions.UpdateUser;

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient, private store: Store) {
  }

  get(): Observable<User> {
    return this.store
      .select(SessionState.getUser)
      .pipe(
        filter(user => user != null)
      );
  }

  /**
   * Fetch User entity from server
   */
  fetch(): Observable<User> {
    return this.http
      .get<User>('/api/user')
      .pipe(
        share(),
        tap(user => this.store.dispatch(new UpdateUser(user)))
      );
  }

  /**
   * Return true if user has the named role
   */
  hasRole(roleName: string): boolean {
    const user = this.store.selectSnapshot<User>(SessionState.getUser);

    let hasRole = false;

    user.roles.map(role => {
      if (role.name === roleName) {
        hasRole = true;
      }
    });

    return hasRole;
  }


  /**
   * Return true if user has the named role
   */
  hasAbility(abilityName: string): Observable<boolean> {
    return this.get()
      .pipe(
        map(u => {
          let hasAbility = false;

          u.abilities.map(ability => {
            if (ability.name === abilityName || ability.name === '*') {
              hasAbility = true;
              return;
            }
          });

          return hasAbility;
        })
      );
  }
}
