import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LengthAwarePaginator} from '../../../../shared/models/LengthAwarePaginator';
import {AlertSearchOptions} from '../../../models/AlertSearchOptions';


@Injectable({providedIn: 'root'})
export class AlertService {

    private resourceUrl = 'api/alerts';

    constructor(private http: HttpClient) {
    }

    get(guid) {
        return this.http.get(this.resourceUrl + guid);
    }

    getByGuid(guid, page) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get<LengthAwarePaginator>(this.resourceUrl + '/guid/' + guid, {params: params});
    }

    search(options: AlertSearchOptions) {
        let params = new HttpParams();
        // params = params.append('page', page);

        Object.keys(options).map((key) => {
            // params.set will completely replace previous object, so
            params = params.set(key, options[key]);
        });

        return this.http.get(this.resourceUrl, {params: params});
    }

    hide(alert_id) {
        return this.http.get(this.resourceUrl + '/hide/' + alert_id);
    }
}
