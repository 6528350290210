<div class="card rs-card rs-card-blue">
  <div class="card-body" [ngClass]="{'min-paginated-card-height': smsEvents?.length >= 10}">

    <div class="clearfix">
      <p class="text-end">{{smsEvents?.length}} Events</p>
    </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="smsEvents?.length > 0">
      <thead class="">
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Type</th>
        <th>Incoming</th>
          <th>Status</th>
        <!--<th class="text-center">Dev</th>-->
        <!--<th class="text-center">OS</th>-->
        <!--<th class="text-center">Web</th>-->
        <th>Message</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let m of smsEvents | slice:pagination.start:pagination.end">
        <td>{{m.id}}</td>
        <td>{{m.created_at | dateTimeFormat:'withtime' }}</td>
        <td>{{m.type}}</td>
        <td>{{m.incoming === 1 ? 'Yes' : 'No'}}</td>
          <td>{{m.status}}</td>
        <td>{{m.message}}</td>

        <!--<td [ngSwitch]="m.client_device_type" class="text-center">-->
          <!--<i class="fa fa-mobile" *ngSwitchCase="'mobile'"></i>-->
          <!--<i class="fa fa-desktop" *ngSwitchCase="'desktop'"></i>-->
          <!--<i class="fa fa-tablet" *ngSwitchCase="'tablet'"></i>-->
        <!--</td>-->

        <!--<td [ngSwitch]="m.client_os" class="text-center">-->
          <!--<i class="fa fa-android font-android" *ngSwitchCase="'Android'"></i>-->
          <!--<i class="fa fa-apple font-apple" *ngSwitchCase="'iOS'"></i>-->
          <!--<i class="fa fa-windows font-windows" *ngSwitchCase="'Windows'"></i>-->
          <!--<i class="fa fa-apple font-apple" *ngSwitchCase="'OS X'"></i>-->
          <!--<i class="fa fa-linux font-linux" *ngSwitchCase="'Linux'"></i>-->
        <!--</td>-->

        <!--<td class="text-center">-->
          <!--<i class="fa fa-edge" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('Edge')"></i>-->
          <!--<i class="fa fa-chrome font-chrome" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('Chrom')"></i>-->
          <!--<i class="fa fa-firefox font-firefox" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('Firefox') && !m.client_user_agent.includes('Seamonkey')"></i>-->
          <!--<i class="fa fa-firefox font-firefox" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('Mozilla') && !m.client_user_agent.includes('Seamonkey')"></i>-->
          <!--<i class="fa fa-safari font-safari" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('Safari') && !m.client_user_agent.includes('Chrom')"></i>-->
          <!--<i class="fa fa-internet-explorer" aria-hidden="true" ngbTooltip="{{m.client_user_agent}}" *ngIf="m.client_user_agent.includes('MSIE')"></i>-->
        <!--</td>-->

        <!--<td class="text-center">-->
          <!--<span [ngbTooltip]="info[m.type]" placement="left"  container="body">-->
            <!--<i class="fa-solid fa-circle-info font-blue"></i>-->
          <!--</span>-->
        <!--</td>-->

      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="smsEvents"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="smsEvents?.length"
                    [(page)]="pagination.current_page"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm">
    </ngb-pagination>
    <p>Page: {{pagination?.current_page}}</p>
  </div>

</div>
