import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DineSubscriptionInterface} from '../models/dine-subscription.interface';

@Component({
  selector: 'app-dine-card-info-modal',
  templateUrl: './dine-card-info-modal.component.html',
  styleUrls: ['./dine-card-info-modal.component.scss']
})
export class DineCardInfoModalComponent {

  public subscription: DineSubscriptionInterface = null;

  constructor(public activeModal: NgbActiveModal) { }

}
