import {Component} from '@angular/core';
import {HistoryService} from 'src/app/shared/auth/services/history.service';
import {Router} from '@angular/router';
import {StorageAccessApiService} from 'src/app/shared/services/storage-access-api.service';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-cookie-access',
  standalone: true,
  imports: [
    NgOptimizedImage,
  ],
  templateUrl: './cookie-access.component.html',
  styleUrl: './cookie-access.component.scss',
})
export class CookieAccessComponent {

  currentState: PermissionState = 'prompt';

  constructor(private historyService: HistoryService,
              private router: Router) {
    StorageAccessApiService.getPermissionState().then(status => {
      this.currentState = status.state;
    });
  }

  async requestApproval() {
    try {
      await document.requestStorageAccess();
    } catch (err) {
      // Access was not granted and it may be gated behind an interaction

      // You can't ever get a denied state back from the permission state.  See https://github.com/privacycg/storage-access/issues/149
      // It's always prompt.  So we force it here to change the view.
      this.currentState = 'denied';
      return;
    }

    let url = this.historyService.consumeLastVisitedRoute();

    if (!url) {
      url = '/auth/login';
    }

    this.router.navigateByUrl(url);
  }
}
