import {Component, Input} from '@angular/core';
import {Customer} from 'src/app/customer/models/customer.model';
import {TransunionModalComponent} from 'src/app/customer/customer-detail/transunion-modal/transunion-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-agency-guid',
  standalone: true,
  imports: [],
  templateUrl: './agency-guid.component.html',
})
export class AgencyGuidComponent {
  @Input({required: true}) customer: Customer;

  constructor(private modalService: NgbModal) {
  }

  showTransunion() {
    const modalRef = this.modalService.open(TransunionModalComponent);
    modalRef.componentInstance.customer = this.customer;
  }
}
