<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    <span>TransUnion Tools</span>
  </h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-6 text-center">
      <button class="btn btn-outline-primary" (click)="queryAccount()">Query Account Status</button>
    </div>
    <div class="col-6 text-center">
      <button class="btn btn-outline-danger" (click)="closeUser()">Close Account</button>
    </div>
  </div>

  <div class="text-center margin-top-15" *ngIf="accountStatus !== undefined">
    Account Status:
    <strong class="text-{{ accountStatus ? 'success' : 'danger' }}">
      {{ accountStatus ? 'Active' : 'Inactive' }}
    </strong>
  </div>

  <div class="text-center margin-top-15" *ngIf="accountClosed !== undefined">
    Close Account Result:
    <strong class="text-{{ accountClosed ? 'success' : 'danger' }}">
      {{ accountClosed ? 'Account Closed Successfully' : 'Unable to Close Account' }}
    </strong>
  </div>

  <hr>

  <div class="text-muted mt-3 small">
    <p><strong>Query status:</strong> Ping Transunion's API to check the registration status in their system. An account with Active status
      in our system should return True. Anything else should return False.</p>
    <p><strong>Close account:</strong> Permanently closes an account in Transunion's system. They cannot be reactivated afterwards, but
      could re-signup and avoid "duplicate account" errors.</p>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>
