import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomerSearchComponent} from './customer-search/customer-search.component';
import {CustomerDetailComponent} from './customer-detail/customer-detail.component';
import {CustomerTransactionsComponent} from './customer-detail/customer-transactions/customer-transactions.component';
import {CustomerAlertsComponent} from './customer-detail/customer-alerts/customer-alerts.component';
import {CustomerOfferClicksComponent} from './customer-detail/customer-offer-clicks/customer-offer-clicks.component';
import {CustomerLoginsComponent} from './customer-detail/customer-logins/customer-logins.component';
import {CustomerEditModalComponent} from './customer-edit-modal/customer-edit-modal.component';
import {ChangeAfidModalComponent} from './change-afid-modal/change-afid-modal.component';
import {SharedModule} from '../shared/shared.module';
import {AddNoteModalComponent} from '../notes/add-note-modal/add-note-modal.component';
import {OfferClickService} from './services/offer-click.service';
import {CustomerCancelEventsComponent} from './customer-detail/customer-cancel-events/customer-cancel-events.component';
import {DownsellModalComponent} from './pricepoint/downsell-modal/downsell-modal.component';
import {PricepointService} from './pricepoint/services/pricepoint.service';
import {PasswordResetModalComponent} from './account/password-reset-modal/password-reset-modal.component';
import {CancelAccountModalComponent} from './account/cancel-account-modal/cancel-account-modal.component';
import {AccountService} from './account/services/account.service';
import {CancelReasonService} from './account/services/cancel-reason.service';
import {RefundModalComponent} from './transactions/refund-modal/refund-modal.component';
import {ChargebackModalComponent} from './transactions/chargeback-modal/chargeback-modal.component';
import {ScreenshotModalComponent} from './transactions/screenshot-modal/screenshot-modal.component';
import {RefundReasonService} from './account/services/refund-reason.service';
import {RefundService} from './transactions/refund.service';
import {ChargebackService} from './transactions/chargeback.service';
import {NotesModule} from '../notes/notes.module';
import {ExtendTrialModalComponent} from './extend-trial-modal/extend-trial-modal.component';
import {MailEventsComponent} from './customer-detail/mail-events/mail-events.component';
import {MailEventService} from './services/mail-event.service';
import {SendSmsModalComponent} from './account/send-sms-modal/send-sms-modal.component';
import {SendSmsService} from './account/services/send-sms.service';
import {CallRecordingsComponent} from './customer-detail/call-recordings/call-recordings.component';
import {SmsEventsComponent} from './customer-detail/sms-events/sms-events.component';
import {SmsEventService} from './services/sms-event.service';
import {CustomerSmsAlertsComponent} from './customer-detail/sms-alerts/customer-sms-alerts.component';
import {SmsAlertsService} from './services/sms-alerts.service';
import {CannotRefundModalComponent} from './transactions/cannot-refund-modal/cannot-refund-modal.component';
import {ReactivateAccountModalComponent} from './account/reactivate-account-modal/reactivate-account-modal.component';
import {ReportHistoryService} from './services/report-history.service';
import {ReportHistoryComponent} from './customer-detail/report-history/report-history.component';
import {StatusIndicatorComponent} from './customer-detail/status-indicator/status-indicator.component';
import {DineCardEnrollModalComponent} from './dine-card-enroll-modal/dine-card-enroll-modal.component';
import {DineCardService} from './services/dine-card.service';
import {DineCardInfoModalComponent} from './dine-card-info-modal/dine-card-info-modal.component';
import {DineIncentiveComponent} from './customer-detail/dine-incentive/dine-incentive.component';
import {DarkWebInfoComponent} from './customer-detail/dark-web-info/dark-web-info.component';
import {DarkWebInfoModalComponent} from './dark-web-info-modal/dark-web-info-modal.component';
import {OrderNumberComponent} from './customer-detail/customer-transactions/order-number/order-number.component';
import {AddressHistoryComponent} from './address-history/address-history.component';
import {AfidComponent} from './customer-detail/afid/afid.component';
import {SmartOffersIncentiveComponent} from './customer-detail/smart-offers-incentive/smart-offers-incentive.component';
import {SmartOffersInfoModalComponent} from './smart-offers-info-modal/smart-offers-info-modal.component';
import {ThreedsInfoComponent} from './customer-detail/threeds-info/threeds-info.component';
import {ThreedsInfoModalComponent} from './threeds-info-modal/threeds-info-modal.component';
import {SendEmailModalComponent} from './account/send-email-modal/send-email-modal.component';
import {RemoveSpacesPipe} from 'src/app/shared/pipes/removeSpaces';
import {CapitalizePipe} from 'src/app/shared/pipes/capitalize.pipe';
import {UkPhonePipe} from 'src/app/shared/pipes/uk-phone.pipe';
import {OneTimePasswordModalComponent} from './one-time-password-modal/one-time-password-modal.component';
import {MailEventsModalComponent} from './mail-events-pdf-modal/mail-events-modal.component';
import {ReportPostLogComponent} from "./customer-detail/report-post-log/report-post-log.component";
import {ClickLogComponent} from './customer-detail/click-log/click-log.component';
import {DateTimeFormatPipe} from 'src/app/shared/pipes/date-time-format.pipe';
import {TransunionModalComponent} from "./customer-detail/transunion-modal/transunion-modal.component";
import {AgencyGuidComponent} from 'src/app/customer/customer-detail/agency-guid/agency-guid.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NotesModule,
    RemoveSpacesPipe,
    CapitalizePipe,
    UkPhonePipe,
    DateTimeFormatPipe,
    AgencyGuidComponent,
    CustomerSearchComponent,
    PasswordResetModalComponent,
  ],
  declarations: [
    CustomerDetailComponent,
    CustomerTransactionsComponent,
    CustomerAlertsComponent,
    CustomerOfferClicksComponent,
    CustomerLoginsComponent,
    CustomerEditModalComponent,
    ChangeAfidModalComponent,
    AddNoteModalComponent,
    CustomerCancelEventsComponent,
    DownsellModalComponent,
    CancelAccountModalComponent,
    SendSmsModalComponent,
    RefundModalComponent,
    CannotRefundModalComponent,
    ChargebackModalComponent,
    ScreenshotModalComponent,
    ExtendTrialModalComponent,
    MailEventsComponent,
    SmsEventsComponent,
    CustomerSmsAlertsComponent,
    MailEventsComponent,
    CallRecordingsComponent,
    ReactivateAccountModalComponent,
    ReportHistoryComponent,
    StatusIndicatorComponent,
    DineCardEnrollModalComponent,
    DineCardInfoModalComponent,
    DineIncentiveComponent,
    DarkWebInfoComponent,
    DarkWebInfoModalComponent,
    OrderNumberComponent,
    AddressHistoryComponent,
    AfidComponent,
    SmartOffersIncentiveComponent,
    SmartOffersInfoModalComponent,
    ThreedsInfoComponent,
    ThreedsInfoModalComponent,
    TransunionModalComponent,
    SendEmailModalComponent,
    OneTimePasswordModalComponent,
    MailEventsModalComponent,
    ReportPostLogComponent,
    ClickLogComponent,
  ],
  providers: [
    OfferClickService,
    PricepointService,
    AccountService,
    CancelReasonService,
    RefundReasonService,
    RefundService,
    ChargebackService,
    MailEventService,
    SmsEventService,
    SendSmsService,
    SmsAlertsService,
    ReportHistoryService,
    DineCardService,
  ],
})
export class CustomerModule {
}
