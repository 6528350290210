import {Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {LengthAwarePaginator} from "../../../shared/models/LengthAwarePaginator";
import {ReportPostLogInterface} from "../../models/report-post-log.interface";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-click-log',
  templateUrl: './click-log.component.html',
  styleUrls: ['./click-log.component.scss']
})
export class ClickLogComponent implements OnInit {

  private resourceUrl = 'api/logs/clicks';

  public clickLog:  LengthAwarePaginator<ReportPostLogInterface>;

  public pagination: any = {
    start: 0,
    end: 10,
    current_page: 1
  };

  @Input() uid: number;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit() {
    // Watch for GUID changes
    this.route.params.subscribe(
      params => {
        this.fetchClicks(1);
      }
    );
  }

  onPageChange(page) {
    this.fetchClicks(page);
  }

  private fetchClicks(page: number): void {
    this.get(this.uid, page).subscribe(lengthAwarePaginator => {
      this.clickLog = lengthAwarePaginator;
    });
  }

  get(uid: number, page: number) {
    let params = new HttpParams();
    params = params.append('page', page);
    return this.http.get<LengthAwarePaginator<ReportPostLogInterface>>(this.resourceUrl + '/' + uid, {params: params});
  }
}
