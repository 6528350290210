import {User} from 'src/app/shared/models/user.model';

export namespace SessionActions {

  export class UpdateUser {
    static readonly type = '[Session] Update User';
    constructor(public user: User) {}
  }

  export class UpdateIsAuthenticated {
    static readonly type = '[Session] Update isAuthenticated';
    constructor(public isAuthenticated: boolean) {}
  }

  export class ClearState {
    static readonly type = 'ClearState';

    constructor() {
    }
  }
}
