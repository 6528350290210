import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

/**
 * Remembers the last visited route.  Typically set on logout, or route guards
 * and used on login or after guard is satisfied to take user to where they were
 * originally trying to go.
 */
@Injectable({providedIn: 'root'})
export class HistoryService {

  constructor(private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService) {
  }

  clearLastVisitedRoute() {
    this.$localStorage.clear('lastVisitedRoute');
    this.$sessionStorage.clear('lastVisitedRoute');
  }

  getLastVisitedRoute() {
    return this.$localStorage.retrieve('lastVisitedRoute') || this.$sessionStorage.retrieve('lastVisitedRoute');
  }

  consumeLastVisitedRoute() {
    const url = this.getLastVisitedRoute();
    this.clearLastVisitedRoute();
    return url;
  }

  setLastVisitedRoute(url: string) {
    this.$localStorage.store('lastVisitedRoute', url);
    this.$sessionStorage.store('lastVisitedRoute', url);
  }

}
