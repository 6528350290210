import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageAccessApiService {
  static isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      // If an error occurs (e.g., due to cross-origin restrictions), assume it's inside an iframe
      return true;
    }
  }

  static async hasCookieAccess(): Promise<PermissionState> {
    // Check if Storage Access API is supported
    if (!document.requestStorageAccess) {
      // Storage Access API is not supported so best we can do is
      // hope it's an older browser that doesn't block 3P cookies
      return 'granted';
    }

    // Check if access has already been granted
    if (await document.hasStorageAccess()) {
      return 'granted';
    }

    // Check the storage-access permission
    // Wrap this in a try/catch for browsers that support the
    // Storage Access API but not this permission check
    // (e.g. Safari and older versions of Firefox)
    try {
      const permission = await StorageAccessApiService.getPermissionState();

      if (permission.state === "granted") {
        // Can just call requestStorageAccess() without a
        // user interaction and it will resolve automatically.
        try {
          await document.requestStorageAccess();
          return 'granted';
        } catch (error) {
          // This shouldn't really fail if access is granted
          return 'denied';
        }
      } else if (permission.state === "prompt") {
        // Need to call requestStorageAccess() after a user interaction
        // Can't do anything further here, so handle this in the click handler
        return 'prompt';
      } else if (permission.state === "denied") {
        // Currently not used. See:
        // https://github.com/privacycg/storage-access/issues/149
        console.log("Cookie access denied");
        return 'denied';
      }
    } catch (error) {
      // storage-access permission not supported. Assume false.
      console.error("storage-access permission not supported. Assume no access.");
      return 'denied';
    }

    // By default return false, though should really be caught by one of above.
    return 'denied';
  }

  static async getPermissionState(): Promise<PermissionStatus> {
    return navigator.permissions.query({
      // @ts-expect-error Typescript doesn't have storage-access as a valid PermissionName yet
      name: "storage-access",
    })
  }
}
