<div class="container">
	<div class="row justify-content-center">

		<div class="card rs-card rs-card-blue col-lg-6 col-md-8">
			<div class="card-body">

				<form action="" method="post">
					<div class="row justify-content-center">
						<div class="col-sm-12">
							<a class="mx-auto d-block" href="{{ googleAuthUrl }}">
                <img class="mx-auto d-block" src="/assets/Red-signin_Long_base_20dp.png"/>
              </a>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
