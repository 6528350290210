<div *ngIf="threeds">
  <span (click)="openInfoModal()" class="clickable">
    {{ threeds.success ? 'Success' : 'Decline' }} |
    {{ threeds.challenge_completed ? 'Challenged' : '-' }}
  </span>
</div>

<div *ngIf="!threeds">
  No data
</div>
