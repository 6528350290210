import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {NoteService} from '../services/note.service';
import {Note} from '../models/note.model';
import {Alert} from '../../customer/models/alert.model';
import {User} from '../../shared/models/user.model';
import {HistoryService} from '../../shared/auth/services/history.service';
import {Router} from '@angular/router';
import {AlertService} from "@ratespecial/core";
import {ErrorService} from "../../shared/error/error.service";


@Component({
  selector: 'app-add-note-modal',
  templateUrl: 'add-note-modal.component.html',
  styleUrls: ['add-note-modal.component.css']
})
export class AddNoteModalComponent implements OnInit {

  // Add reference to Object.keys function to template
  public objectKeys = Object.keys;

  /**
   * The note object related to alert
   */
  public note: Note;

  /**
   * User entity (set by caller through modelRef)
   */
  public user: User;

  /**
   * Alert object which we wish to resolve
   */
  public alert: Alert;

  /**
   * Form validation errors
   */
  public errors: any;


  constructor(
    private noteService: NoteService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    public historyService: HistoryService,
    private router: Router,
    private errorService: ErrorService,
  ) {
  }


  ngOnInit() {
    // TODO: Subscribe to logout events to close self
  }


  save() {
    let isResolveNote = false;

    // When resolving alerts, we need these extra vars
    if (this.alert) {
      this.note.resolve_note_id = this.alert.note_id;
      this.note.alert_id = this.alert.id;
      isResolveNote = true;
    }

    this.noteService
      .create(this.note, isResolveNote)
      .subscribe(
        (data) => {
          this.alertService.success('Customer Note Added');
          this.noteService.notify(true);
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      );
  }


  close() {
    this.activeModal.close();

    // If a route is in history, go there
    const route = this.historyService.consumeLastVisitedRoute();
    if (route) {
      this.router.navigateByUrl(route);
    }
  }
}
