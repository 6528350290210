import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerLite} from '../../models/customer-lite.model';
import {RefundReasonService} from '../../account/services/refund-reason.service';
import {CustomerService} from '../../services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TransactionInterface} from '../../models/transaction.interface';
import {RefundService} from '../refund.service';
import {ErrorService} from '../../../shared/error/error.service';
import {RefundRequest} from '../../models/refund-request.interface';
import {Reason} from '../../models/reason.model';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.css']
})
export class RefundModalComponent implements OnInit {

  /**
   * The Transaction that is the subject of the refund
   */
  public transaction: TransactionInterface;

  /**
   * Customer Data needed for request
   */
  public customer: CustomerLite;

  public reasons: Array<Reason> = [];
  public refundAmounts: number[] = [];

  /**
   * Form Model
   */
  public refundRequest: RefundRequest = {
    guid: null, // set by parent modal reference
    trans_num: null,
    reason: null,
    note: null,
    trans_amount: null,
    refund_amount: null
  };

  /**
   * Add reference to Object.keys function to template
   */
  public objectKeys = Object.keys;


  constructor(private customerService: CustomerService,
              private reasonService: RefundReasonService,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private refundService: RefundService,
              public errorService: ErrorService
  ) {
  }


  ngOnInit() {
    // Refund for whom?  Get customer entity and initialize refund amounts
    this.customerService.get(this.refundRequest.guid).subscribe(data => {
      this.customer = data.getCustomerLite();
      this.initAvailableRefundAmount();
    });

    // Get Reasons for refund select options
    this.reasonService.get().subscribe(data => {
      this.reasons = data;
    });
  }


  /**
   * Initialize Refund Amount Options
   * Some processors allow partial refunds, some don't.
   */
  private initAvailableRefundAmount(): void {
    if (this.customerService.canPartialRefund(this.customer.proc_id)) {
      // Show partial refund options in increments of 5
      for (let amount = 5; amount < this.transaction.available_refund; amount += 5) {
        this.refundAmounts.push(amount);
      }
    }

    this.refundAmounts.push(this.transaction.available_refund);

    // Initialize Form Model Values
    this.refundRequest.trans_amount = Number(this.transaction.amount);
    this.refundRequest.refund_amount = Number(this.transaction.available_refund);
  }

  /**
   * Make refund request to backend
   */
  refund(): void {
    this.errorService.clearErrors();

    this.refundRequest.trans_num = this.transaction.trans_num;

    this.refundService.refund(this.refundRequest)
      .subscribe(
        (data) => {
          this.alertService.success('Refund initiated');
          this.close();
        },
        (err: HttpErrorResponse) => {
          this.errorService.handleValidationErrors(err);
        }
      );
  }

  close(): void {
    this.activeModal.close();
  }
}
