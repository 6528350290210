import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {PricepointService} from '../services/pricepoint.service';
import {CustomerService} from '../../services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertService} from "@ratespecial/core";

@Component({
    selector: 'app-downsell-modal',
    templateUrl: './downsell-modal.component.html',
    styleUrls: ['./downsell-modal.component.css']
})
export class DownsellModalComponent implements OnInit {

    /**
     * Pricepoint Data Options
     */
    public pricepoints: any;

    /**
     * User selected pricepoint selected
     */
    public selectedPricepoint: any;

    /**
     * The current price customer is paying
     */
    public recurring_price: any;

    /**
     * Form Model
     * @type {{bsid: any; guid: any}}
     */
    public form = {
        bsid: null,
        guid: null,
        recurring_price: null,
    };

    /**
     * Form validation errors
     */
    public errors: any;

    /**
     * Add reference to Object.keys function to template
     */
    public objectKeys = Object.keys;

    constructor(
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private alertService: AlertService,
        public activeModal: NgbActiveModal,
        private pricepointService: PricepointService
    ) {}

    /**
     * Initialie by fetching available pricepoints
     */
    ngOnInit() {
        this.pricepointService.get()
            .subscribe(data => {
                this.pricepoints = data;
                this.form.bsid = this.getBsidForCurrentPrice();
            });
    }

    /**
     * Returns the BSID for current price customer is paying
     */
    getBsidForCurrentPrice() {
        for (let pp of this.pricepoints) {
            if (pp.recurring_price === this.recurring_price) {
                 return pp.bsid;
            }
        }
    }

    /**
     * On price selection change by user, update hidden value for price
     * @param pricepoint
     */
    onSelectionChange(pricepoint) {
        this.selectedPricepoint = pricepoint;
        this.form.recurring_price = pricepoint.recurring_price;
    }

    /**
     * Save to database
     */
    save() {
        this.pricepointService
            .update(this.form)
            .subscribe(
                (data) => {
                    this.alertService.success('Customer Pricepoint Updated');
                    this.close();
                },
                (err: HttpErrorResponse) => {
                    this.handleValidationErrors(err);
                }
            );
    }

    /**
     * Close the modal
     */
    close() {
        this.activeModal.close();
    }


    /**
     * Handle 422 Form Validation Errors
     * @param err
     */
    // TODO: Switch to the universal ErrorService & app-error component
    handleValidationErrors(err) {
        if (err.status == 422) {
            err.error.errors.map(error => {
                this.alertService.addApiError(error);
            });
        }
    }
}
