<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-white">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/"><img src="assets/logo_main.png" alt="UKC CRM"/></a>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNavbar()">

      <span class="navbar-toggler-icon"></span>
    </button>


    <ul class="collapse navbar-collapse" id="navbarCollapse" [ngbCollapse]="isNavbarCollapsed">

      <ul class="navbar-nav me-auto">

        <li class="nav-item" *ngIf="userService.hasAbility('view-dashboard') | async">
          <a  class="nav-link" routerLink="/dashboard" (click)="isNavbarCollapsed = true">Dashboard</a>
        </li>

        <li class="nav-item" *ngIf="userService.hasAbility('search-customers') | async">
          <a  class="nav-link" routerLink="/customer/search" (click)="isNavbarCollapsed = true">Customers</a>
        </li>

        <li class="nav-item" *ngIf="userService.hasAbility('view-prospects') | async">
          <a  class="nav-link" routerLink="/prospect/list" (click)="isNavbarCollapsed = true">Prospects</a>
        </li>

        <li class="nav-item" *ngIf="userService.hasAbility('listen-call-recordings') | async">
          <a  class="nav-link" routerLink="/call-recordings" (click)="isNavbarCollapsed = true">Call Recordings</a>
        </li>

        <li class="nav-item" *ngIf="userService.hasAbility('view-paid-trials') | async">
          <a  class="nav-link" routerLink="/paid-trial" (click)="isNavbarCollapsed = true">Paid Trial Status</a>
        </li>

        <!-- ONLY DISPLAY FOR MOBILE -->
        <div class="dropdown-divider d-lg-none d-md-flex"></div>
        <li class="nav-item d-lg-none d-md-flex">
          <a class="nav-link disabled"><strong class="font-blue">Admin</strong></a>
        </li>
        <div class="dropdown-divider d-lg-none d-md-flex"></div>


        <li class="nav-item d-lg-none d-md-flex" (click)="isNavbarCollapsed = true" ngbDropdownItem *ngIf="userService.hasAbility('update-abilities') | async">
          <a class="nav-link" routerLink="/admin/acl"><i class="fa-regular fa-id-badge" aria-hidden="true"></i> <span class="drop-menu-item-text">ACL</span></a>
        </li>
        <li class="nav-item d-lg-none d-md-flex" ngbDropdownItem>
          <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/log"><i class="fa-solid fa-list-ol" aria-hidden="true"></i> <span class="drop-menu-item-text">Action Log</span></a>
        </li>
        <li class="nav-item d-lg-none d-md-flex" ngbDropdownItem *ngIf="userService.hasAbility('view-users') | async">
          <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/users"><i class="fa-solid fa-users" aria-hidden="true"></i> <span
            class="drop-menu-item-text">Users</span></a>
        </li>
        <li class="nav-item d-lg-none d-md-flex" ngbDropdownItem *ngIf="userService.hasAbility('whitelist') | async">
          <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/whitelist"><i class="fa-solid fa-key" aria-hidden="true"></i> <span class="drop-menu-item-text">IP Whitelist</span></a>
        </li>
        <li class="nav-item d-lg-none d-md-flex" ngbDropdownItem *ngIf="userService.hasAbility('holiday-hours') | async">
          <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/holiday-hours"><i class="fa-regular fa-calendar" aria-hidden="true"></i> <span
            class="drop-menu-item-text">Holiday Hours</span></a>
        </li>
        <li class="nav-item mb-4 d-lg-none d-md-flex" ngbDropdownItem>
          <a class="nav-link" (click)="logout(); isNavbarCollapsed = true"><i class="fa-solid fa-right-from-bracket" aria-hidden="true"></i> <span class="drop-menu-item-text">Logout</span></a>
        </li>
      </ul>


      <ul class="navbar-nav d-md-none d-sm-none d-none d-lg-flex">

        <li class="nav-item">
          <a  class="nav-link" routerLink="/whatsnew"><i class="fa-regular fa-circle-question"></i></a>
        </li>

        <li class="nav-item dropdown" ngbDropdown placement="bottom-left">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
            <i class="fa-solid fa-circle-user fa-lg" aria-hidden="true"></i> {{ (user$ | async)?.name }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
            <li class="nav-item" (click)="isNavbarCollapsed = true" ngbDropdownItem *ngIf="userService.hasAbility('update-abilities') | async">
              <a class="nav-link" routerLink="/admin/acl"><i class="fa-regular fa-id-badge" aria-hidden="true"></i> <span class="drop-menu-item-text">ACL</span></a>
            </li>
            <li class="nav-item" ngbDropdownItem>
              <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/log"><i class="fa-solid fa-list-ol" aria-hidden="true"></i> <span class="drop-menu-item-text">Action Log</span></a>
            </li>
            <li class="nav-item" ngbDropdownItem *ngIf="userService.hasAbility('view-users') | async">
              <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/users"><i class="fa-solid fa-users" aria-hidden="true"></i> <span class="drop-menu-item-text">Users</span></a>
            </li>
            <li class="nav-item" ngbDropdownItem *ngIf="userService.hasAbility('whitelist') | async">
              <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/whitelist"><i class="fa-solid fa-key" aria-hidden="true"></i> <span class="drop-menu-item-text">IP Whitelist</span></a>
            </li>
            <li class="nav-item" ngbDropdownItem *ngIf="userService.hasAbility('holiday-hours') | async">
              <a class="nav-link" (click)="isNavbarCollapsed = true" routerLink="/admin/holiday-hours"><i class="fa-regular fa-calendar" aria-hidden="true"></i> <span class="drop-menu-item-text">Holiday Hours</span></a>
            </li>
            <li class="nav-item" ngbDropdownItem>
              <a class="nav-link" (click)="logout(); isNavbarCollapsed = true"><i class="fa-solid fa-right-from-bracket" aria-hidden="true"></i> <span class="drop-menu-item-text">Logout</span></a>
            </li>

          </ul>
        </li>
      </ul>

    <div class="build-version">{{config.version}}</div>


      <!--<form class="form-inline mt-2 mt-md-0">-->
        <!--<input class="form-control me-sm-2" type="text" placeholder="Search">-->
        <!--<button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>-->
      <!--</form>-->
    </ul>
  </div>
</nav>
