import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {HistoryService} from './history.service';
import {StorageAccessApiService} from 'src/app/shared/services/storage-access-api.service';

/**
 * Prevent going to an authenticated route when not logged in
 */
export const StorageAccessApiGuard: CanActivateFn = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router = inject(Router);
    const historyService = inject(HistoryService);

    if (!StorageAccessApiService.isInIframe()) {
      return true;
    }

    const hasAccess: PermissionState = await StorageAccessApiService.hasCookieAccess();

    if (hasAccess === 'granted') {
      return true;
    }

    historyService.setLastVisitedRoute(state.url);
    router.navigateByUrl('/auth/cookie-access');
    return false;
};
