/**
 * Inquiry Types
 *
 * Fetch distinct inquiry types for open and closed alerts
 */
import {Observable, of as observableOf} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InquiryTypeSet} from '../models/inquiry-type-set.model';

@Injectable({providedIn: 'root'})
export class InquiryTypeService {

    private resourceUrl = '/api/alerts/inquiry-types';
    private expireMinutes = 5;
    private timestamp: Date;
    public types: any;

    constructor(private http: HttpClient) {
        this.timestamp = new Date();
    }


    /**
     * Fetch data from server
     * @returns {Observable<T>}
     */
    fetch() {
        return this.http
            .get<InquiryTypeSet>(this.resourceUrl).pipe(
            map(
                resp => resp as InquiryTypeSet
            ),
            tap(
                resp => {
                    this.timestamp = new Date();
                    this.types = resp;
                }
            ), );
    }

    /**
     * Return cached data if exists and not expired
     * @param force
     * @returns {any}
     */
    get(force?: boolean): Observable<InquiryTypeSet> {

        if (force === true || this.isExpired()) {
            this.types = undefined;
        }

        // if we already have retrieved the user data from the server, return it
        if (this.types) {
            return observableOf(this.types).pipe(map(
                resp => resp as InquiryTypeSet
            ));
        }

        return this.fetch();
    }


    /**
     * Check if cached data is older than X minutes
     * @returns {boolean}
     */
    isExpired () {
        let isExpired = false;
        const now = new Date();
        const differenceInMinutes = Math.floor((now.getTime() - this.timestamp.getTime()) / (1000 * 60 * 60 * 60));

        if (differenceInMinutes > this.expireMinutes) {
            isExpired = true;
        }

        return isExpired;
    }
}
