import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProspectService} from '../services/prospect.service';
import {AlertService} from "@ratespecial/core";

@Component({
  selector: 'app-mark-contacted-modal',
  templateUrl: './mark-contacted-modal.component.html',
  styleUrls: ['./mark-contacted-modal.component.css']
})
export class MarkContactedModalComponent implements OnInit {

  private prospect: any;

  // private prospectData = {
  //   id: 0,
  //   guid: '',
  //   is_contacted: 0
  // }

  constructor(
      public activeModal: NgbActiveModal,
      private prospectService: ProspectService,
      private alertService: AlertService
  ) { }

  ngOnInit() {

  }

  save() {

    this.prospectService
        .markContacted(this.prospect.id)
        .subscribe(
            (data) => {
              this.alertService.success('Prospect marked contacted');

              this.close();
            },
            (err: HttpErrorResponse) => {
              // no validate global handler does the rest
            }
        );
  }

  close() {
    this.activeModal.close();
  }
}
