<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Update Pricepoint</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <!-- Errors -->
  <div *ngIf="errors">
    <div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">
      {{errors[key]}}
    </div>
  </div>

  <!-- PP Form -->
  <form #ppForm="ngForm">
    <input type="hidden" [(ngModel)]="form.guid" name="guid" />

    <div class="form-check disabled" *ngFor="let pp of pricepoints; let idx = index">
      <label class="form-check-label">
        <input class="form-check-input"
               type="radio"
               name="bsid"
               [value]="pp.bsid"
               (change)="onSelectionChange(pp)"
               [(ngModel)]="form.bsid"> ${{pp.recurring_price}}
      </label>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Cancel</button>
</div>





