<div class="container">

  <div class="card rs-card rs-card-blue">
    <div class="card-body">

      <!-- Desktop View -->
      <table class="margin-top-15 table table-sm rs-table table-striped">
        <thead class="">
        <tr>
          <th>GUID</th>
          <th>Time</th>
          <th>Type</th>
          <th>Info</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let c of cancelEventsPage?.data">
          <td>{{c.guid}}</td>
          <td>{{c.updated_at | dateTimeFormat:'withtime'}}</td>
          <td>{{c.type}}</td>
          <td>{{c.other}}</td>
          <td class="text-center">
            <a [routerLink]="['/customer/detail/' + c.guid]"><i class="fa-solid fa-eye fa-lg" ngbTooltip="View Customer Details"></i></a>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="pagination-footer margin-top-25">
        <div class="d-flex justify-content-between">
          <ngb-pagination *ngIf="cancelEventsPage"
                          (pageChange)="onPageChange($event)"
                          [collectionSize]="cancelEventsPage.total"
                          [pageSize]="cancelEventsPage?.per_page"
                          [(page)]="cancelEventsPage.current_page"
                          [maxSize]="5"
                          [rotate]="true"
                          [ellipses]="false"
                          [boundaryLinks]="true" size="sm"></ngb-pagination>
          <p>Page: {{cancelEventsPage?.current_page}}</p>
        </div>
      </div>

    </div>
  </div>

</div>
