<div *ngIf="customer && !customer?.guid">
  <div class="container" *ngIf="customer">
    <div class="alert alert-warning" role="alert">
      This does not appear to be a valid GUID
    </div>
  </div>
</div>

<div *ngIf="customer?.guid">

  <app-status-indicator [customer]="customer"></app-status-indicator>

  <div class="container" *ngIf="customer">

    <img *ngIf="customer.is_pii_cleared == true" id="pii-cleared-stamp" src="assets/deleted-sm.png" alt="Deleted" />

    <div class="d-flex justify-content-between align-items-center">

      <h2 class="p-2">{{customer?.fname}} {{customer?.lname}}</h2>

      <!-- Tool Bar -->
      <div class="p-10" *ngIf="userService.hasAbility('view-customers') | async">
        <div class="d-flex flex-row">
          <div class="p-2 clickable" (click)="dineCardservice.openDineCardOfferModal(this.customer)" *ngIf="(userService.hasAbility('dine-card-offer') | async) && dineSubscription === null" ngbTooltip="Dine Card Offer">
            <i class="fa-solid fa-utensils fa-lg" aria-hidden="true"></i>
          </div>
          <div class="p-2 clickable" (click)="sendOnetimePassword()" ngbTooltip="Send One Time Password">
            <i class="fa-solid fa-unlock-keyhole fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="editCustomer()" *ngIf="userService.hasAbility('edit-customer-pii') | async" ngbTooltip="Edit Customer Details">
            <i class="fa-solid fa-pencil fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="addNote()" *ngIf="userService.hasAbility('add-note') | async" ngbTooltip="Add Note">
            <i class="fa-regular fa-note-sticky fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="sendSms()" *ngIf="userService.hasAbility('send-sms') | async" ngbTooltip="Send SMS">
            <i class="fa-regular fa-paper-plane fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="downsell()" *ngIf="userService.hasAbility('update-pricepoint') | async" ngbTooltip="Downsell">
            <i class="fa-solid fa-arrow-turn-down fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="extendTrial()" *ngIf="(userService.hasAbility('extend-trial') | async) && customer.in_trial && !customer.extended_trial_date" ngbTooltip="Extend Trial">
            <i class="fa-solid fa-repeat fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="resetPassword()" *ngIf="userService.hasAbility('reset-password') | async" ngbTooltip="Reset Password">
            <i class="fa-solid fa-key fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="reactivateAccount()" *ngIf="(this.userService.hasAbility('reactivate-account') | async) && customer.status === 'cancelled'" ngbTooltip="Reactivate Account">
            <i class="fa-solid fa-recycle fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="cancelAccount()" *ngIf="(this.userService.hasAbility('cancel-account') | async) && isNotCancelled()" ngbTooltip="Cancel Account">
            <i class="fa-solid fa-ban fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="sendEmail()" *ngIf="userService.hasAbility('send-email') | async" ngbTooltip="Send E-mail">
            <i class="fa-regular fa-lg fa-envelope" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="delete()" *ngIf="(userService.hasAbility('delete-customer') | async) && ['cancelled','chargeback'].indexOf(customer?.status) !== -1" ngbTooltip="Delete PII Information">
            <i class="fa-solid fa-trash fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2 clickable" (click)="openCreditCardModal()" *ngIf="customer.deferred_auth && !customer.deferred_auth_complete" ngbTooltip="Add Credit Card"> <!-- Restrict to Deferred Auth -->
            <i class="fa-regular fa-credit-card fa-lg" aria-hidden="true"></i>
          </div>

          <div class="p-2" ngbDropdown>
            <div class="clickable" ngbDropdownToggle>
              <i class="fa-solid fa-lg" aria-hidden="true" ngbTooltip="Menu"></i>
            </div>
            <div ngbDropdownMenu>
              <button (click)="refreshReport()" *ngIf="(userService.hasAbility('refresh-report') | async) && customer.status == 'active'" ngbDropdownItem>Refresh Report</button>
              <button (click)="openCallRecordingModal()" *ngIf="userService.hasAbility('listen-call-recordings') | async" ngbDropdownItem>Call Recordings</button>
              <button (click)="changeAFID()" *ngIf="userService.hasAbility('change-afid') | async" ngbDropdownItem>Change AFID</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Customer Info -->
    <div class="card rs-card rs-card-blue">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <dl class="row">
              <dt class="col-5">First Name</dt>
              <dd class="col-7">{{customer?.fname}}</dd>

              <dt class="col-5">Last Name</dt>
              <dd class="col-7">{{customer?.lname}}</dd>

              <dt class="col-5">Email</dt>
              <dd class="col-7">{{customer?.email}}</dd>

              <dt class="col-5">Phone</dt>
              <dd class="col-7">{{customer?.phone | ukPhone}}</dd>

              <dt class="col-5">DOB</dt>
              <dd class="col-7">{{customer?.dob | dateTimeFormat}}</dd>

              <!-- Address -->
              <dt class="col-5">Address</dt>
              <dd class="col-7">
                {{customer?.abodenumber}} {{customer?.building_name}}<br *ngIf="customer.abodenumber > 0 || customer.building_name.length > 0">
                {{customer?.straddr}} {{customer?.street}}<br *ngIf="customer.straddr.length > 0 || customer.street.length > 0">
                {{customer?.city}}<br>
                {{customer?.zip}}

                <button class="btn btn-link" (click)="openAddressHistory()" ngbTooltip="View Address History">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </dd>

              <dt class="col-5">Pricepoint</dt>
              <dd class="col-7">{{customer?.recurring_price | currency : 'GBP'}}</dd>

              <dt class="col-5">Trial End</dt>
              <dd class="col-7">{{customer?.trial_end_date | dateTimeFormat}}</dd>

              <dt class="col-5">Third Party Marketing</dt>
              <dd class="col-7">{{customer?.addmarket === 1 ? 'Yes' : 'No'}}</dd>

              <dt class="col-5">IP Address</dt>
              <dd class="col-7">{{customer?.ip_addr ? customer?.ip_addr : '-'}}</dd>

              <dt class="col-5">Agency GUID</dt>
              <dd class="col-7 text-left"><app-agency-guid [customer]="customer"></app-agency-guid>
              </dd>

              <dt class="col-5">Incentives</dt>
              <dd class="col-7" id="incentives-container">
                <app-dine-incentive [guid]="guid"></app-dine-incentive>
                <app-smart-offers-incentive [uid]="customer?.uid"></app-smart-offers-incentive>
              </dd>

              <dt class="col-5">Deferred Auth  <i class="fa-regular fa-circle-question" ngbTooltip="Deferred auth means the customer's card was not processed during signup." container="body"></i></dt>
              <dd class="col-7">{{ getDeferredAuthLabel() }}</dd>

              <dt class="col-5">3DS <i class="fa-regular fa-circle-question" ngbTooltip="Details on the initial transaction's 3D Secure resolution" container="body"></i></dt>
              <dd class="col-7"><app-threeds-info [threeds]="customer?.threeds"></app-threeds-info></dd>
            </dl>
          </div>

          <div class="col-sm-6">
            <dl class="row">
              <dt class="col-5">Number of Payments</dt>
              <dd class="col-7">{{customer?.num_payments}}</dd>

              <dt class="col-5">Signup Date</dt>
              <dd class="col-7">
                {{customer?.start_dt | dateTimeFormat:'withtime'}}
              </dd>
              <!--<dd class="col-7"></dd>-->

              <dt class="col-5">Last Login</dt>
              <dd class="col-7">{{(customer?.last_login) ? (customer?.last_login | dateTimeFormat:'withtime') : 'N/A' }}</dd>

              <!--<dt class="col-5 text-truncate">Last IP Address</dt>-->
              <!--<dd class="col-7">{{customer?.last_ip}}</dd>-->

              <dt class="col-5">Status</dt>
              <dd class="col-7">{{customer?.status | capitalize}}</dd>

              <!--<dt class="col-5">Security Questions</dt>-->
              <!--<dd class="col-7">{{customer?.security}}</dd>-->

              <dt class="col-5">CID</dt>
              <dd class="col-7">{{customer?.cid ? customer?.cid : '-'}}</dd>

              <dt class="col-5">AFID</dt>
              <dd class="col-7"><app-afid [afid]="customer?.afid">-</app-afid></dd>

              <dt class="col-5">SID</dt>
              <dd class="col-7">{{customer?.sid ? customer?.sid : '-'}}</dd>

              <dt class="col-5">Processor</dt>
              <dd class="col-7">{{customer?.processor_name ? customer?.processor_name : '-'}}</dd>

              <dt class="col-5">Sticky.io Gateway</dt>
              <dd class="col-7">{{customer?.limelight_gateway_name ? customer?.limelight_gateway_name : '-'}}</dd>

              <dt class="col-5">In Trial</dt>
              <dd class="col-7">{{customer?.in_trial ? 'Yes' : 'No'}}</dd>

              <dt class="col-5">Manual Verify <i class="fa-regular fa-circle-question" ngbTooltip="Denotes if customer was a manual verify customer.  If 'Yes' then it remains 'Yes' even after manual verification completed." container="body"></i></dt>
              <dd class="col-7">{{customer?.is_manual_verify ? 'Yes' : 'No'}}</dd>

              <dt class="col-5">Thin File <i class="fa-regular fa-circle-question" ngbTooltip="Denotes if customer was a thin file customer.  If 'Yes' then it remains 'Yes' even after their file is thicker." container="body"></i></dt>
              <dd class="col-7">{{customer?.is_thin_file ? 'Yes' : 'No'}}</dd>

              <dt class="col-5">Broken Enroll <i class="fa-regular fa-circle-question" ngbTooltip="Denotes if customer started as broken enrollment.  If 'Yes' then it remains 'Yes' even after they finish KBA." container="body"></i></dt>
              <dd class="col-7">{{customer?.is_broken_enroll ? 'Yes' : 'No'}}</dd>

              <dt class="col-5">Vertical</dt>
              <dd class="col-7">{{customer?.vertical ? customer?.vertical : '-'}}</dd>

              <dt class="col-5">Product Name</dt>
              <dd class="col-7">{{ customer?.product_name ? customer?.product_name : '-'}}</dd>

              <dt class="col-5">Dark Web</dt>
              <dd class="col-7"><app-dark-web-info [uid]="customer.uid"></app-dark-web-info></dd>

              <dt class="col-5">Third Party Data</dt>
              <dd class="col-7">
                <div *ngFor="let tpd of objectKeys(customer?.third_party_data)">{{tpd}}: {{customer?.third_party_data[tpd]}}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="userService.hasAbility('customer-details-extended') | async">

    <app-customer-sms-alerts></app-customer-sms-alerts>

    <!-- Alerts -->
    <app-customer-alerts></app-customer-alerts>

    <!-- Transactions -->
    <app-customer-transactions [transactions]="customer?.transactions"></app-customer-transactions>

    <!-- Notes -->
    <app-customer-notes></app-customer-notes>


    <div  class="padding-top-35">
      <div class="row">
        <div class="col-md-5">
          <h4>Recent Logins</h4>
          <app-customer-logins></app-customer-logins>
        </div>

        <div class="col-md-7">
          <h4>Mail Events <a href="https://ratespecial.atlassian.net/wiki/spaces/UP/pages/2280194049/Mail+Event+Types" target="_blank" id="mail-events-help-link"><i class="fa-regular fa-circle-question" ngbTooltip="Visit help article" container="body"></i></a></h4>
          <app-mail-events></app-mail-events>
        </div>
      </div>
    </div>
    <div  class="padding-top-35">
      <div class="row">
        <div class="col-md-12">
          <h4>SMS Events</h4>
          <app-sms-events></app-sms-events>
        </div>

      </div>
    </div>

    <div  class="padding-top-35">
      <div class="row">
        <div class="col-md-6">
          <h4>Cancellation Activity</h4>
          <app-customer-cancel-events></app-customer-cancel-events>
        </div>

        <div class="col-md-6">
          <h4>Offer Clicks</h4>
          <app-customer-offer-clicks></app-customer-offer-clicks>
        </div>
      </div>
    </div>

    <div class="padding-top-35">
      <div class="row">
        <div class="col-md-6">
          <h4>Report Fetch History</h4>
          <app-report-history></app-report-history>
        </div>

        <div class="col-md-6">
          <h4>Click Log</h4>
          <app-click-log [uid]="customer.uid"></app-click-log>
        </div>
      </div>
    </div>

    <div class="padding-top-35">
        <div class="row">
          <div class="col-md-12">
            <h4>Report Post Log</h4>
            <app-report-post-log [uid]="customer.uid"></app-report-post-log>
          </div>
        </div>
    </div>

    </ng-container>

  </div>


</div> <!-- END IF (customer) -->
