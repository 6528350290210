<div class="card rs-card rs-card-blue">
  <div class="card-body" [ngClass]="{'min-paginated-card-height': reportHistory?.length >= 10}">

    <div class="clearfix">
      <p class="text-end">{{reportHistory?.length}} Events</p>
    </div>

    <!-- Desktop View -->
    <table class="margin-top-15 table table-sm rs-table table-striped" *ngIf="reportHistory?.length > 0">
      <thead class="">
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Success</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let m of reportHistory | slice:pagination.start:pagination.end">
        <td>{{m.id}}</td>
        <td>{{m.created_at | dateTimeFormat:'withtime' }}</td>
        <td>
          <i
            class="fa-solid fa-circle"
            [ngClass]="{'fetch-success': m.success, 'fetch-fail': !m.success}"
            ngbTooltip="{{m.error_msg}}">
          </i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-flex justify-content-between">
    <ngb-pagination *ngIf="reportHistory"
                    (pageChange)="onPageChange($event)"
                    [collectionSize]="reportHistory?.length"
                    [(page)]="pagination.current_page"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    size="sm">
    </ngb-pagination>
    <p>Page: {{pagination?.current_page}}</p>
  </div>

</div>
