import {Component, Input, OnInit} from '@angular/core';
import {RefundModalComponent} from '../../transactions/refund-modal/refund-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChargebackModalComponent} from '../../transactions/chargeback-modal/chargeback-modal.component';
import {ScreenshotModalComponent} from '../../transactions/screenshot-modal/screenshot-modal.component';
import {ActivatedRoute} from '@angular/router';
import {TransactionModel} from '../../../shared/models/transaction.model';

@Component({
  selector: 'app-customer-transactions',
  templateUrl: 'customer-transactions.component.html',
  styleUrls: ['customer-transactions.component.scss'],
})
export class CustomerTransactionsComponent implements OnInit {

  /**
   * Current GUID of user from detail view
   */
  private guid: string;

  @Input() transactions: Array<TransactionModel>;

  public pagination: any = {
    start: 0,
    end: 10,
    current_page: 1,
  };

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Watch for GUID changes.  This also runs on first load
    this.route.params.subscribe(
      params => {
        this.guid = params['guid'];
        this.onPageChange(1);
      },
    );
  }

  onPageChange(page) {
    this.pagination.current_page = page;
    this.pagination.start = page * 10 - 10;
    this.pagination.end = page * 10;

    // REMOVE ME (tests RF CB RR columns)
    // this.transactions.push({
    //   amount: 0,
    //   canChargeback: false,
    //   canRefund: false,
    //   canReturnReversal: false,
    //   dt: "2017-11-06 00:00:00",
    //   hasChargeback: true,
    //   hasRefund: true,
    //   hasReturnReversal: true,
    //   trans_num: "",
    //   transaction_description: "",
    //   transaction_name: "Cancellation",
    //   type: "CN"
    // });
  }

  /**
   * Open Refund Modal
   */
  openRefundModal(trans): void {
    const modalRef = this.modalService.open(RefundModalComponent);
    modalRef.componentInstance.transaction = trans;
    modalRef.componentInstance.refundRequest.guid = this.guid;
  }

  openChargebackModal(trans) {
    const modalRef = this.modalService.open(ChargebackModalComponent);
    modalRef.componentInstance.transaction = trans;
    modalRef.componentInstance.chargebackRequest.guid = this.guid;
  }

  openScreenshotModal(trans) {
    const modalRef = this.modalService.open(ScreenshotModalComponent, {size: 'lg'});
    modalRef.componentInstance.trans_num = trans.trans_num;
    modalRef.componentInstance.guid = this.guid;
  }

  /**
   * Determine what to display in the Processor Name column for a specific transaction record
   * @param transaction
   */
  getTransactionProcessor(transaction): string {
    if (transaction.is_stickyio) {
      return transaction.stickyio_gateway_name;
    }

    return transaction.processor_name;
  }
}
