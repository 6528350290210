import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddressHistory} from 'src/app/shared/models/address-history';

@Component({
  selector: 'app-address-history',
  templateUrl: './address-history.component.html',
  styleUrls: ['./address-history.component.scss']
})
export class AddressHistoryComponent {

  public addressHistory: Array<AddressHistory>;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

}
