/**
 * Customer Service
 *
 * The Customer Object is a mix of elements from multiple datastores/tables
 */
import {Observable, of as observableOf} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Customer} from '../models/customer.model';
import {AccountSettings} from "../../shared/models/account-settings.interface";

@Injectable({providedIn: 'root'})
export class CustomerService {

  private resourceUrl = '/api/customer';

  /**
   * Customer cache stores last 10 customers
   */
  public customers: Array<Customer>;

  /**
   * Notification to any subscribers of note save events
   */
  @Output() customerUpdateEvent = new EventEmitter<any>();


  constructor(private http: HttpClient) {
    this.customers = [];
  }


  /**
   * Retrieve Customer from cache or fetch from server if not found
   * @param guid
   * @param force     Force fetch from server
   * @returns {any}
   */
  get(guid: string, force?: boolean): Observable<Customer> {

    // Check if customer with that guid is in our cache and return it
    let i = 0;
    for (const cust of this.customers) {
      if (guid === cust.guid) {
        // If force is true, remove customer from cache, else return customer
        if (force === true) {
          // TODO: Add last fetch timestamp and expire cached items after X time
          // Remove cached customer
          this.customers.splice(i, 1);

        } else {
          return observableOf(cust).pipe(map(
            resp => resp
            // resp => {
            //     let c = new Customer();
            //     c.fromJSON(resp as CustomerInterface);
            //     return c;
            // }
          ));
        }
      }
      i++;
    }

    return this.fetch(guid);
  }


  /**
   * Fetch customer entity from the server
   */
  private fetch(guid: string): Observable<Customer> {
    return this.http
      .get<Customer>(`${this.resourceUrl}/${guid}`)
      .pipe(
        map(resp => new Customer(resp)),
        tap(
          customer => {
            // Cache customer, but limit cached to 10
            if (this.customers && Object.keys(this.customers).length > 10) {
              this.customers.shift();
            }
            this.customers.push(customer);
          },
        ),
      );
  }


  /**
   * Update Customer Record
   */
  update(guid: string, params) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.put(this.resourceUrl + '/' + guid, params, {headers});
  }


  search(searchCriteria) {
    return this.http.post(this.resourceUrl + '/search', searchCriteria);
  }


  extendTrial(guid) {

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get(this.resourceUrl + '/' + guid + '/extend-trial', {headers}).pipe(tap(
      data => {
        this.notify(data);
      }
    ));
  }

  getScreenshotDetail(guid: string, trans_num: number) {
    for (const cust of this.customers) {
      if (cust.guid === guid && cust.screenshots) {
        for (const ss of cust.screenshots) {
          if (ss.trans_num == trans_num) {
            return ss;
          }
        }
      }
    }

    return false;
  }

  delete(guid: string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.delete(this.resourceUrl + '/' + guid, {headers});
  }


  notify(event) {
    this.customerUpdateEvent.emit(event);
  }


  canPartialRefund(processorId): boolean {
    return !(processorId == 9 ||
      processorId == 10 ||
      processorId == 12 ||
      processorId == 13);
  }

  convertAgencyGuidToOurGuid(agencyGuid: string): Observable<string> {
    return this.http
      .get('/api/customer/agency-guid-to-guid', {params: {guid: agencyGuid}})
      .pipe(
        map(r => r['ourGuid'])
      );
  }

  refreshReport(guid: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post('/api/customer/' + guid + '/refresh-report', {headers});
  }

  getUserSettings(uid: number): Observable<AccountSettings> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json');

    return this.http.get<AccountSettings>('/api/customer/' + uid + '/user-settings', {headers});
  }

  sendOneTimePassword(guid: string, provider: string = 'TW'): Observable<any> {
    return this.http.post(`/api/customer/${guid}/send-one-time-password`, {provider});
  }

  queryAccountStatus(guid: string): Observable<boolean> {
    return this.http
      .get(`/api/customer/${guid}/account-status`)
      .pipe(
        map(r => r['result'])
      );
  }

  closeUser(guid: string): Observable<boolean> {
    return this.http
      .post(`/api/customer/${guid}/close-user`, {})
      .pipe(
        map(r => r['status'])
      );
  }

  getPartnerList(): Observable<object> {
    return this.http
      .get(`/api/customer/partner-list`)
      .pipe();
  }

}
