import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SendEmailRequest} from '../../models/send-email-request.interface';


@Injectable({providedIn: 'root'})
export class SendEmailService {

    constructor(private http: HttpClient) {
    }

    send(sendEmailRequest: SendEmailRequest) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');

        return this.http.post(`/api/customer/${sendEmailRequest.guid}/resend-email`, sendEmailRequest, {headers});
    }
}
