import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NoteService} from '../../notes/services/note.service';
import {LengthAwarePaginator} from '../../shared/models/LengthAwarePaginator';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NoteDetailModalComponent} from '../../notes/note-detail-modal/note-detail-modal.component';
import {AccountService} from '../../customer/account/services/account.service';
import {PricepointService} from '../../customer/pricepoint/services/pricepoint.service';

@Component({
  selector: 'app-customer-notes',
  templateUrl: 'customer-notes.component.html',
  styleUrls: ['customer-notes.component.css'],
})
export class CustomerNotesComponent implements OnInit {

  public guid = '';

  /**
   * Notes pagination data set
   */
  public notesPage: LengthAwarePaginator;


  constructor(
    private noteService: NoteService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private accountService: AccountService,
    private pricepointService: PricepointService,
  ) {
    this.pricepointService
      .onUpdatePricepoint
      .subscribe(
        event => this.refresh(event),
      );

    // subscribe to note save events
    this.noteService
      .onNoteSaveEvent
      .subscribe(
        event => this.refresh(event),
      );
  }

  ngOnInit() {

    this.guid = this.route.snapshot.params['guid'];

    this.accountService
      .onAccountUpdateEvent
      .subscribe(
        event => this.getNotes(1),
      );

    // this.getNotes(1);

    // Watch for GUID changes so we can update alert data
    this.route.params.subscribe(
      params => {
        this.guid = params['guid'];
        this.refresh(false);
      },
    );
  }

  onPageChange(page) {
    this.getNotes(page);
  }

  getNotes(page) {
    this.noteService.getByGuid(this.guid, page).subscribe((lengthAwarePaginator) => {
      this.notesPage = lengthAwarePaginator;
    });
  }

  /**
   * open note modal that fetches note detail
   * @param noteId
   */
  viewNote(noteId) {
    const modalRef = this.modalService.open(NoteDetailModalComponent);
    modalRef.componentInstance.noteId = noteId;
  }


  /**
   * Event handler for refetching Notes from server
   */
  refresh(event) {
    this.getNotes(1);
  }
}
