import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {GoogleComponent} from './auth/social/google/google.component';
import {LoginComponent} from './auth/login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CustomerModule} from './customer/customer.module';
import {ProspectModule} from './prospect/prospect.module';
import {CancellationListComponent} from './cancellations/cancellation-list/cancellation-list.component';
import {GuidSearchModalComponent} from './hotkey/guid-search-modal/guid-search-modal.component';
import {CustomerSearchModalComponent} from './hotkey/customer-search-modal/customer-search-modal.component';
import {WhatsnewComponent} from './whatsnew/whatsnew.component';
import {CallRecordingsModule} from './call-recordings/call-recordings.module';
import {HotkeyModule, IHotkeyOptions} from 'angular2-hotkeys';
import {DateTimeFormatPipe} from './shared/pipes/date-time-format.pipe';
import {NgxsModule} from '@ngxs/store';
import {SessionState} from './shared/state/session/session.state';
import {environment} from '../environments/environment';
import {AlertsComponent as RsAlertsComponent, LoadIndicatorComponent} from '@ratespecial/core';
import {provideRouter} from '@angular/router';
import {routes} from 'src/app/app-routes';
import {appErrorFactory, AppErrorHandler} from 'src/app/shared/error/app-error-handler.service';
import {PaidTrialComponent} from 'src/app/paid-trial/paid-trial/paid-trial.component';

const iHotkeyOptions: IHotkeyOptions = {

  disableCheatSheet: false,
  // cheatSheetHotkey: 'shift+h',
  // cheatSheetCloseEsc: true,
  // cheatSheetCloseEscDescription: 'Close Help Menu',
  cheatSheetDescription: 'Show/Hide this Help Menu',
};

@NgModule({
  declarations: [
    AppComponent,
    GoogleComponent,
    LoginComponent,
    CancellationListComponent,
    GuidSearchModalComponent,
    CustomerSearchModalComponent,
    WhatsnewComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    SharedModule,
    CustomerModule,
    ProspectModule,
    FormsModule,
    CallRecordingsModule,
    HotkeyModule.forRoot(iHotkeyOptions),
    DateTimeFormatPipe,
    NgxsModule.forRoot([
      SessionState,
    ], {
      developmentMode: !environment.production,
    }),
    LoadIndicatorComponent,
    RsAlertsComponent,
    PageNotFoundComponent,
    PaidTrialComponent,
  ],
  providers: [
    provideRouter(routes),
    // Use our custom AppErrorHandler to process Angular errors
    {provide: ErrorHandler, useFactory: appErrorFactory},
    // Alias AppErrorHandler to the ErrorHandler token so we can inject AppErrorHandler and configure it
    {provide: AppErrorHandler, useExisting: ErrorHandler},
  ],
})
export class AppModule {
}
