import {tap} from 'rxjs/operators';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class RefundService {

  private resourceUrl = 'api/transaction/refund';

  /**
   * Notification to any subscribers of note save events
   * @type {EventEmitter<boolean>}
   */
  @Output() onRefundTransaction = new EventEmitter<any>();


  constructor(private http: HttpClient) {
  }


  refund(refundRequest) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.resourceUrl, refundRequest, {headers}).pipe(tap(
      (data) => {
        this.notify(data);
      }));
  }


  /**
   * Notify any subscribers
   * @param event
   */
  notify(event) {
    this.onRefundTransaction.emit(event);
  }
}
