import {Injectable} from '@angular/core';
import {AlertService} from "@ratespecial/core";
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class HttpErrorService {

    constructor(private alertService: AlertService) {
    }

    /**
     * Handle any http error that comes back from Server side
     */
    handleError(err: HttpErrorResponse) {

        // console.log(err);

      // If there is no error body, this could be the browser cannot connect to the backend.
      if (err?.error == null) {
        this.alertService.error(err.message);
      } else if (err?.error?.errors) {
            // Assume we have an array
            this.handleErrorArray(err);

        } else if (typeof err.error === 'object') {
            this.handleErrorObject(err.error);

        } else {
            // Assuming error is just a string
            this.alertService.error(err.error);
        }
    }


    /**
     * Handle an error that is an object
     */
    handleErrorObject(error) {
        if (this.isApiError(error)) {
            this.alertService.addApiError(error);
        } else {
            // This will likely be a Laravel error which uses the mssage field.
            this.alertService.error(error.message);
        }
    }

    /**
     * Handle array of errors
     * @param err
     */
    handleErrorArray(err) {
        err.error.errors.map(error => {
            // Are the errors an array of API Error Objects or an array of strings
            if (typeof error === 'string') {
                this.alertService.error(error);
            } else {
                this.alertService.addApiError(error);
            }
        });
    }

    /**
     * Returns true if error is our internal Api Error Object
     */
    private isApiError(error): boolean {
        return error?.code && error?.detail;
    }
}
