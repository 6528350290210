<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Find Customer by GUID</h5>
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<form class="guid-modal-form" (ngSubmit)="submit()">
  <div class="modal-body">

    <app-error></app-error>

    <div class="form-group row">
      <label for="guid" class="col-sm-4 col-form-label">GUID</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="guid"
               name="guid"
               required
               [(ngModel)]="guid"
               #guidInput
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['guid']}">
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-outline-primary">Search</button>
    <button type="button" class="btn btn-outline-secondary" (click)="reset()">Reset</button>
  </div>
</form>
