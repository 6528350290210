import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {filter, take} from 'rxjs';

/**
 * Hides host element if UserService.hasAbility() returns false
 *
 * <div appUserAbility="can-reset-password"></div>
 */

@Directive({
  selector: '[appUserAbility]',
  standalone: true,
})
export class UserAbilityDirective implements OnInit {

  @Input('appUserAbility') ability: string;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.userService
      .hasAbility(this.ability)
      .pipe(
        filter(hasAbility => !hasAbility),
        take(1),
      )
      .subscribe(() => {
        this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
      });
  }
}
