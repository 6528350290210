<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Cancel Account</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <!-- Errors -->
  <!--<div *ngIf="errors">-->
    <!--<div class="alert alert-danger" role="alert" *ngFor="let key of objectKeys(errors)">-->
      <!--{{errors[key]}}-->
    <!--</div>-->
  <!--</div>-->

  <app-error></app-error>

  <p>Are you sure you want to cancel the account for <strong>{{customer?.fname}} {{customer?.lname}}</strong>?</p>

  <form>

    <input type="hidden" name="guid" [(ngModel)]="cancelAccountRequest.guid">

    <!-- Reason -->
    <div class="form-group form-row">
      <label for="reason" class="col-sm-4 col-form-label">Reason</label>
      <div class="col-sm-8">
        <select
            name="reason"
            id="reason"
            required
            class="form-control"
            [(ngModel)]="cancelAccountRequest.reason"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['reason']}"
        >
          <optgroup *ngFor="let groupname of objectKeys(reasons)" [label]="groupname">
            <option *ngFor="let r of reasons[groupname]" [ngValue]="r">{{r.body}}</option>
          </optgroup>
        </select>
      </div>
    </div>

    <!-- Notes -->
    <div class="form-group form-row">
      <label for="body" class="col-sm-4 col-form-label">Note</label>
      <div class="col-sm-8">
        <textarea
            class="form-control"
            id="body"
            name="body"
            rows="3"
            [(ngModel)]="cancelAccountRequest.note"
            [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['note']}"></textarea>
      </div>
    </div>

  </form>

  <!--<pre>{{customer|json}}</pre>-->
  <!--<pre>{{reasons|json}}</pre>-->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="cancelAccount()" [disabled]="ajaxWorking">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">No</button>
</div>




