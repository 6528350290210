import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SmsEvent} from '../models/sms-event.model';

@Injectable({providedIn: 'root'})
export class SmsEventService {

  constructor(private http: HttpClient) {
  }

  get(guid: string) {
    return this.http.get<Array<SmsEvent>>(`api/logs/${guid}/sms-events`);
  }
}
