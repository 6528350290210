import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomerService} from 'src/app/customer/services/customer.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Customer} from 'src/app/customer/models/customer.model';
import {AlertService} from '@ratespecial/core';

@Component({
  selector: 'app-one-time-password-modal',
  templateUrl: './one-time-password-modal.component.html',
  styleUrls: ['./one-time-password-modal.component.scss'],
})
export class OneTimePasswordModalComponent {
  // Passed in from modal creator
  customer: Customer = null;
  provider = 'TW';

  constructor(public activeModal: NgbActiveModal,
              private customerService: CustomerService,
              private alertService: AlertService) {
  }

  send(): void {
    this.customerService
      .sendOneTimePassword(this.customer.guid, this.provider)
      .subscribe({
        next: () => {
          this.alertService.success('One time password sent');
          this.activeModal.close();
        },
        error: (e: HttpErrorResponse) => {
          this.alertService.error(e.error.message);
        },
      });
  }
}
