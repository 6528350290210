<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Edit Customer</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

  <app-error></app-error>

  <form>

    <!-- First Name -->
    <div class="mb-2 row">
      <label for="fname" class="col-sm-4 col-form-label">First Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="fname"
               name="fname"
               required
               [(ngModel)]="customerModel.fname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['fname']}">
      </div>
    </div>

    <!-- Last Name -->
    <div class="mb-2 row">
      <label for="lname" class="col-sm-4 col-form-label">Last Name</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="lname"
               name="lname"
               required
               [(ngModel)]="customerModel.lname"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['lname']}">
      </div>
    </div>

    <!-- Email -->
    <div class="mb-2 row" *ngIf="userService.hasAbility('change-email') | async">
      <label for="email" class="col-sm-4 col-form-label">Email</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="email"
               name="email"
               required
               [(ngModel)]="customerModel.email"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['email']}">
      </div>
    </div>

    <!-- Phone -->
    <div class="mb-2 row">
      <label for="phone" class="col-sm-4 col-form-label">Phone</label>
      <div class="col-sm-8">
        <input type="text"
               class="form-control"
               id="phone"
               name="phone"
               required
               valueType='full'
               [ngModel]="customerModel.phone | ukPhone | removeSpaces"
               (ngModelChange)="customerModel.phone=$event"
               [ngClass]="{'is-invalid' : errorService.errors && errorService.errors['phone']}">
      </div>
    </div>

    <!-- DateofBirth -->
    <div class="mb-2 row">
      <label for="dob" class="col-sm-4 col-form-label">Date of Birth</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd" id="dob" name="dob" [minDate]="minDate"
                 [(ngModel)]="dob" ngbDatepicker #d1="ngbDatepicker">

            <button class="btn btn-outline-secondary" type="button" (click)="d1.toggle()">
              <i class="fa-regular fa-calendar" aria-hidden="true"></i>
            </button>
        </div>

      </div>
    </div>

    <!-- Current Address -->
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Current Address</label>
      <div class="col-sm-8">
        <address class="text-dark">
        {{customerModel.abodenumber}} {{customerModel.building_name}}<br *ngIf="customerModel.abodenumber || customerModel.building_name">
        {{customerModel.straddr}} {{customerModel.street}}<br *ngIf="customerModel.straddr || customerModel.street">
        {{customerModel.city}}<br *ngIf="customerModel.city">
        {{customerModel.zip}}
        </address>
      </div>
    </div>

    <!-- PCA Address -->
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Update Address</label>
      <div class="col-sm-8">
        <input
          id="pcainput"
          name="searchTerm"
          type="text"
          class="form-control"
          placeholder="Start typing a postcode, street or address"
          autocorrect="off"
          autocomplete="none">
      </div>
    </div>


    <div class="form-group row mb-2">
      <div class="col-sm-8 offset-sm-4">
        <textarea name="formattedAddress" id="formattedAddress" wrap="soft" class="form-control" rows="6" readonly></textarea>
      </div>
    </div>

	<div class="form-group row">
		<div class="col-sm-4"></div>
		<div class="col-sm-6">
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="forwardAddress" name="forwardAddress" [(ngModel)]="customerModel.forwardAddress">
				<label class="form-check-label" for="forwardAddress">
					Forward address updates
				</label>
			</div>
		</div>
	</div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>





