import {inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {HistoryService} from './history.service';

/**
 * Prevent going to an authenticated route when not logged in
 */
export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const historyService = inject(HistoryService);

    return authService.checkUser().pipe(
        map(userLoggedIn => {
            if (!userLoggedIn) {
                historyService.setLastVisitedRoute(state.url);
                router.navigateByUrl('/auth/login');
                return false;
            }
            return true;
        })
    );
};
