import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ReportProviderFetchLogInterface} from '../models/report-provider-fetch-log.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportHistoryService {

  private resourceUrl = 'api/logs/report-history';

  constructor(private http: HttpClient) {
  }

  get(guid: string) {
    // Now returns entries from report_provider_fetch_log intead of report_history, as that's more useful
    // to the CRM
    return this.http.get<Array<ReportProviderFetchLogInterface>>(this.resourceUrl + '/' + guid);
  }
}
